import { Stem } from "@server/entities/project";
import { UpdateStemDto } from "@server/modules/project/stem/dto/update-stem.dto";
import { Howl } from "howler";
import { atom } from "recoil";

export type StemHowl = RequiredBy<
  Partial<Stem>,
  "stemId" | "name" | "color" | "index" | "duration" | "volume"
> & {
  howl: Howl;
  currentFile?: File;
  previousFile?: File;
  buffer: AudioBuffer;
  solo?: boolean;
  active?: boolean;
  uploading?: boolean;
  loading?: boolean;
  error?: boolean;
};

export type StemState = {
  loading: { done: number; total: number; percent: number };
  exporting: { done: number; total: number; percent: number };
  uploading: { id?: string; done: number; total: number; percent: number };
  stems: StemHowl[];
  updatesQueue: UpdateStemDto[];
  replace: string | null;
};

export const loadingState = atom<StemState["loading"]>({
  key: "loadingStems",
  default: { done: 0, total: 0, percent: 0 },
});

export const exportingState = atom<StemState["exporting"]>({
  key: "exporting",
  default: { done: 0, total: 0, percent: 0 },
});

export const uploadingState = atom<StemState["uploading"]>({
  key: "uploading",
  default: { done: 0, total: 0, percent: 0 },
});

export const replaceState = atom<string | null>({
  key: "replace",
  default: null,
});

export const stemsState = atom<StemHowl[]>({
  key: "stems",
  default: [],
  dangerouslyAllowMutability: true,
});

export const updatesQueueState = atom<UpdateStemDto[]>({
  key: "updatesQueue",
  default: [],
  dangerouslyAllowMutability: true,
});
