import colors from "tailwindcss/colors";

/**
 * @type {import('tailwindcss').TailwindConfig}
 */
const config = {
  content: [
    "./index.html",
    "./src/**/*.{js,jsx,ts,tsx}",
    "../../packages/ui/src/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    screens: {
      sm: "480px",
      md: "768px",
      lg: "976px",
      xl: "1440px",
    },
    container: {
      center: true,
    },
    fontFamily: {
      sans: ["San Francisco", "sans-serif"],
      neue: ["San Francisco"],
      sf: ["San Francisco"],
      serif: ["San Francisco", "serif"],
    },
    extend: {
      fontSize: {
        xs: "0.9rem",
        sm: "1rem",
      },
      colors: {
        blue: {
          1100: "#1e242f",
        },
        dark: {
          100: "#f2f2ff",
          200: "#cad1ea",
          300: "#7787c4",
          400: "#4e5c8d",
          500: "#232f59",
          600: "#1a213d",
          700: "#13182E",
          800: "#070c1f",
          900: "#060a1c",
          1000: "#040711",
        },
        indigo: {
          400: "#5b58f3",
          500: "#4945f8",
          600: "#312DF8",
          700: "#2926D4",
          800: "#211FAB",
          900: "#191782",
          1000: "#191782",
        },
        purple: {
          200: "#dcd9ee",
          300: "#a181ff",
          400: "#9167fa",
          500: "#8354fb",
          600: "#6b47d6",
          700: "#4e39ad",
          800: "#3f2c85",
          900: "#2b1e5c",
        },
        red: {
          100: "rgb(255,255,255)",
          200: "rgb(249,211,210)",
          300: "rgb(243,167,165)",
          400: "rgb(238,123,119)",
          500: "rgb(232,79,74)",
          600: "rgb(225,68,62)",
          700: "rgb(176,47,43)",
          800: "rgb(140,46,42)",
          900: "rgb(131,43,40)",
          1000: "rgb(98,40,39)",
        },
        slate: {
          100: "#B5C2F4",
          200: "#9BA6D1",
          300: "#7D85A8",
          400: "#5E657F",
          700: "#3c3e4d",
          800: "#2e323d",
          900: "#1d1f25",
          1000: "#191a1f",
          1100: "#131317",
        },
        primary: {
          ...colors.purple,
          200: "#dcd9ee",
          300: "#a181ff",
          400: "#9167fa",
          500: "#8354fb",
          600: "#6b47d6",
          700: "#4e39ad",
          800: "#3f2c85",
          900: "#2b1e5c",
        },
      },
      animation: {
        opacity: "kf_opacity 0.6s ease-out forwards",
        up: "kf_up 0.4s ease-out forwards",
        "bg-blur": "kf_blur 0.8s ease-out forwards",
        modal: "kf_modal 0.5s ease-out",
        shake: "kf_shake 0.5s cubic-bezier(.36,.07,.19,.97) both",
      },
      keyframes: {
        kf_modal: {
          "0%": {
            transform: "translateY(30px)",
          },
          "100%": {
            filter: "blur(0px)",
            transform: "translateY(0px)",
          },
        },
        kf_blur: {
          "0%": { backdropFilter: "blur(0px)" },
          "100%": { backdropFilter: "blur(10px)" },
        },
        kf_opacity: {
          "0%": { opacity: 0 },
          "100%": { opacity: 1 },
        },
        kf_up: {
          "0%": { opacity: 0, transform: "translateY(16px)" },
          "100%": { opacity: 1, transform: "translateY(0)" },
        },
        kf_shake: {
          "10%, 90%": { transform: "translate3d(-1px, 0, 0)" },
          "20%, 80%": { transform: "translate3d(2px, 0, 0)" },
          "30%, 50%, 70%": { transform: "translate3d(-4px, 0, 0)" },
          "40%, 60%": { transform: "translate3d(4px, 0, 0)" },
        },
      },
    },
  },
  plugins: [
    function ({ addVariant }) {
      addVariant("children", "& *");
    },
    function ({ addVariant }) {
      addVariant("span", "& > span");
    },
  ],
  corePlugins: {
    preflight: false,
  },
  important: true,
};

export default config;
