import React from "react";

import { Button, ButtonProps } from "./Button";

export interface ActionButtonsProps extends Omit<ButtonProps, "form"> {
  loading?: boolean;
  saveText?: string;
  cancelText?: string;
  saveIcon?: React.ReactElement;
  cancelIcon?: React.ReactElement;
  saveDisabled?: any;
  onSave: () => void;
  onCancel: () => void;
  children?: any | any[];
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  loading,
  saveText = "Save",
  cancelText = "Cancel",
  saveIcon,
  cancelIcon,
  saveDisabled,
  onSave,
  onCancel,
  children,
  ...props
}) => {
  return (
    <div className="flex flex-wrap justify-end gap-2 mt-6">
      <Button
        disabled={saveDisabled}
        color="dark"
        variant="outline"
        leftIcon={cancelIcon}
        onClick={onCancel}
        {...props}
        data-testid="cancel-button"
        data-quick-assist-id="cancel-button"
      >
        {cancelText}
      </Button>

      {children}

      <Button
        disabled={saveDisabled}
        onClick={onSave}
        leftIcon={saveIcon}
        loading={loading}
        {...props}
      >
        {saveText}
      </Button>
    </div>
  );
};

export { ActionButtons };
