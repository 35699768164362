import { atom } from "recoil";

import { PlayerAudio } from "./PlayerContext";

export type PlayerState = {
  audio: PlayerAudio | null;
  loading: boolean;
  visible: boolean;
  playing: boolean;
  volume: number;
  currTime: number;
  duration: number;
};

export const audioState = atom<PlayerAudio | null>({
  key: "audioState",
  default: null,
});

export const loadingState = atom<boolean>({
  key: "playerLoading",
  default: true,
});

export const visibleState = atom<boolean>({
  key: "playerVisible",
  default: false,
});

export const playingState = atom<boolean>({
  key: "playerPlaying",
  default: false,
});

export const volumeState = atom<number>({
  key: "playerVolume",
  default: 1,
});

export const currTimeState = atom<number>({
  key: "playerCurrTime",
  default: 0,
});

export const durationState = atom<number>({
  key: "playerDuration",
  default: 0,
});
