import { User } from "@server/entities";
import { atom } from "recoil";

export type Collaborators<T extends { [key: string]: any }> = Array<
  { user: User } & T
>;

export type CollabState = {
  me: User | undefined;
  joined: Collaborators<any>;
  mice: Collaborators<{ x: number; y: number }>;
};

export const meState = atom<CollabState["me"]>({
  key: "me",
  default: undefined,
});

export const joinedState = atom<CollabState["joined"]>({
  key: "joined",
  default: [],
});

export const miceState = atom<CollabState["mice"]>({
  key: "mice",
  default: [],
});
