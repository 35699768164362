import React, { MutableRefObject, useState } from "react";

import { Needle } from "./Needle";

interface TrackHeaderProps {
  pinchingRef: MutableRefObject<boolean>;
}

const TrackHeader: React.FC<TrackHeaderProps> = ({ pinchingRef }) => {
  const [scrubbing, setScrubbing] = useState(false);

  return (
    <thead className="sticky top-0 z-50 bg-dark-1000">
      <tr>
        <td className="sticky left-0 z-[100] bg-dark-1000" />
        <td
          data-testid="sv-track-header"
          data-quick-assist-id="sv-track-header"
          id="track-header-col-2"
          className="sticky top-0 z-50 h-[10px] pb-4"
          onMouseDown={() => setScrubbing(true)}
        >
          <Needle
            pinchingRef={pinchingRef}
            scrubbing={scrubbing}
            setScrubbing={setScrubbing}
          />
        </td>
      </tr>
    </thead>
  );
};

export { TrackHeader };
