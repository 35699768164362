import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {
  Loader,
  MultiSelect,
  MultiSelectProps,
  useMantineTheme,
} from "@mantine/core";
import React from "react";

import { getInputDefaults } from "./Input";
import { ISelectItem, SelectableItem } from "./Search";

export interface MultiSearchProps<T> extends MultiSelectProps {
  loading?: boolean;
  renderOption?: { (option: ISelectItem<T>): React.ReactNode };
}

function MultiSearch<T>(props: MultiSearchProps<T>) {
  const theme = useMantineTheme();
  const {
    loading,
    renderOption = (o) => (
      <div className="p-2">{o.label ? o.label : String(o)}</div>
    ),
    rightSection,
    ..._props
  } = props;

  const defaults: Omit<MultiSelectProps, "data"> = getInputDefaults(_props);

  return (
    <MultiSelect
      searchable
      rightSection={
        loading ? (
          <Loader size="xs" />
        ) : (
          rightSection || (
            <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" />
          )
        )
      }
      styles={{
        defaultValue: {
          background: theme.colors.slate[7],
          color: "white",
        },
      }}
      itemComponent={SelectableItem(renderOption)}
      transition="fade"
      transitionDuration={100}
      transitionTimingFunction="ease"
      withinPortal
      {...defaults}
      {..._props}
    />
  );
}

export { MultiSearch };
