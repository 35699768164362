import { Dropzone } from "@common/components";
import { Modal } from "@common/components";
import { ModalProps } from "@mantine/core";
import React from "react";

import { useStemViewer } from "../../../contexts/StemViewerContext";

type UploadStemProps = ModalProps;

const UploadStem: React.FC<UploadStemProps> = (props) => {
  const { stem } = useStemViewer().controllers;
  const { uploading } = useStemViewer().stem(["uploading"]);

  const handleUploadFiles = async (files: File[]) => {
    props.onClose();
    await stem.upload(files);
  };

  return (
    <Modal {...props}>
      <h2 className="mb-4">Upload Stem</h2>

      <Dropzone
        loading={uploading.done !== uploading.total}
        acceptedFileTypes={["audio/*"]}
        onUpload={handleUploadFiles}
      />
    </Modal>
  );
};

export { UploadStem };
