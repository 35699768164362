import {
  ActionIcon,
  Card,
  Divider,
  Input,
  Modal,
  Tooltip,
} from "@common/components";
import UserItem from "@common/components/UserItem";
import { notification } from "@common/utils/notification";
import useAsyncForm from "@common/utils/use-async-form";
import { CheckIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { ModalProps, ScrollArea } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { FetchResponse } from "@requests/helpers";
import {
  inviteCollab,
  inviteExternalCollab,
} from "@requests/project/collaborator";
import { useFriends } from "@requests/social/friendship";
import { useUsers } from "@requests/user/user";
import { User } from "@server/entities";
import { InviteCollabDto } from "@server/modules/project/collaborator/dto";
import React, { useCallback, useState } from "react";
import { Copy } from "tabler-icons-react";

import { useProject } from "../ProjectContext";

import { GetShareableLink } from "./GetShareableLink";
import { SelectRole } from "./SelectRole";

interface InviteUsersProps extends ModalProps {}

const InviteUsers: React.FC<InviteUsersProps> = ({ ...props }) => {
  const { project } = useProject();
  const [query, setQuery] = useState("");
  const { data: friends } = useFriends({ query });
  const { data: users } = useUsers({ query });
  const [sent, setSent] = useState(false);
  const [copied, setCopied] = useState(false);
  const clipboard = useClipboard({ timeout: 500 });

  const form = useAsyncForm<InviteCollabDto>({
    initialValues: {
      projectId: project?.id,
      role: "editor",
    } as InviteCollabDto,
  });

  const handleCopyPermalink = () => {
    clipboard.copy("https://www.synqup.com.au/p/" + project?.permalink);
    setCopied(true);
  };

  const handleInvite = async (user: User) => {
    return form.sendForm((values) => {
      return inviteCollab({
        ...values,
        inviteeId: user.id,
      });
    });
  };

  const handleExternalInvite = async () => {
    if (sent) return;

    const { error } = await form.sendForm(
      (values) => {
        return inviteExternalCollab({
          ...values,
          email: query,
        });
      },
      {
        resetInitial: true,
      }
    );

    if (!error) setSent(true);
  };

  return (
    <Modal size="sm" {...props}>
      <div className="grid grid-cols-1 gap-6">
        <h2>Share project</h2>

        <div className="flex items-center gap-4">
          <Input
            data-testid="invite-permalink"
            data-quick-assist-id="invite-permalink"
            className="flex-1"
            label="Project URL"
            value={`https://www.synqup.com.au/p/${project?.permalink}`}
          />

          <Tooltip
            label={copied ? "Link copied ✓" : "Copy permalink to clipboard"}
            positionDependencies={[copied]}
          >
            <ActionIcon
              data-testid="copy-permalink"
              data-quick-assist-id="copy-permalink"
              color="green"
              variant="light"
              className="mt-6"
              onClick={handleCopyPermalink}
            >
              <Copy className="w-5 h-5" />
            </ActionIcon>
          </Tooltip>
        </div>

        <Divider className="w-full my-0" />

        <SelectRole
          data-testid="invite-role"
          data-quick-assist-id="invite-role"
          description="Invitee role"
          className="w-auto"
          {...form.getInputProps("role")}
        />

        <Input
          data-testid="invite-search-user"
          data-quick-assist-id="invite-search-user"
          description="Search users"
          value={query}
          onChange={({ target }) => {
            setQuery(target.value);
            if (sent) setSent(false);
          }}
        />

        <Card>
          <ScrollArea
            style={{ height: 150 }}
            offsetScrollbars
            scrollbarSize={8}
          >
            {friends && friends.data.length > 0
              ? friends.data.map(
                  (friendship) =>
                    friendship.destUser && (
                      <InviteRow
                        key={friendship.id}
                        user={friendship.destUser}
                        onInvite={handleInvite}
                      />
                    )
                )
              : users && users.length > 0
              ? users.map((user) => (
                  <InviteRow
                    key={user.id}
                    user={user}
                    onInvite={handleInvite}
                  />
                ))
              : query && (
                  <div>
                    <p className="text-center text-dark-400">No users found</p>

                    <Divider />

                    <div className="flex items-center gap-4">
                      <p className="m-0">
                        Invite{" "}
                        <span className="px-2 mx-1 py-1 text-indigo-400 bg-opacity-40 bg-indigo-700 rounded">
                          {query}
                        </span>{" "}
                        to Synqup
                      </p>
                      <ActionIcon
                        p={6}
                        color="synqup"
                        loading={form.loading}
                        className="ml-auto"
                        onClick={handleExternalInvite}
                      >
                        {sent ? (
                          <CheckIcon className="w-5 h-5" />
                        ) : (
                          <PaperAirplaneIcon className="w-5 h-5" />
                        )}
                      </ActionIcon>
                    </div>
                  </div>
                )}
          </ScrollArea>
        </Card>
      </div>

      <Divider labelPosition="center" label="OR" className="my-6" />

      <GetShareableLink />
    </Modal>
  );
};

interface InviteRowProps {
  user: User;
  onInvite: (user: User) => Promise<FetchResponse<null>>;
}

const InviteRow = ({ user, onInvite }: InviteRowProps) => {
  const { project } = useProject();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const isCollaborator = useCallback(
    (user: User) => {
      return project?.collaborators.some((collab) => collab.userId === user.id);
    },
    [project?.collaborators]
  );

  const handleInvite = async () => {
    if (sent) return;
    setLoading(true);

    const { error } = await onInvite(user);

    setLoading(false);
    if (!error) setSent(true);
    else notification.error(error.message);
  };

  return (
    <UserItem user={user} showActive>
      <ActionIcon
        data-testid="invite-user-button"
        p={6}
        color="synqup"
        loading={loading}
        className="ml-auto"
        onClick={handleInvite}
        disabled={sent || isCollaborator(user)}
      >
        {sent || isCollaborator(user) ? (
          <CheckIcon className="w-5 h-5" />
        ) : (
          <PaperAirplaneIcon className="w-5 h-5" />
        )}
      </ActionIcon>
    </UserItem>
  );
};

export { InviteUsers };
