import { Mixdown } from "@server/entities";
import { QueryRenderProgressDto } from "@server/modules/project/mixdown/dto/query-render-progress.dto";
import { RenderMixdownDto } from "@server/modules/project/mixdown/dto/render-mixdown.dto";
import { UpdateMixdownDto } from "@server/modules/project/mixdown/dto/update-mixdown.dto";

import { useDelete, useGet, usePatch, usePost, useStickySWR } from "../helpers";

export const updateMixdown = (id: string, data: UpdateMixdownDto) =>
  usePatch(`/project/mixdown/${id}`, data);

export const renderMixdown = (data: RenderMixdownDto) =>
  usePost<QueryRenderProgressDto>("/project/mixdown/render", data);

export const registerListen = (id: string) =>
  usePost(`/project/mixdown/listens/${id}`);

export const getRenderProgress = (data: QueryRenderProgressDto) =>
  useGet(`/project/mixdown/render`, data);

export const getMixdownListens = (id: string) =>
  useGet<number>(`/project/mixdown/listens/${id}`);

export const deleteMixdown = (id: string) =>
  useDelete(`/project/mixdown/${id}`);

export const useTopMixdowns = () =>
  useStickySWR<(Mixdown & { listenCount: number })[]>("/project/mixdown/top");
