import {
  genericSelector,
  RecoilStates,
  useSelector,
} from "../helpers/selectors";

import {
  loadingState,
  markupsState,
  MarkupState,
  savingState,
  selectedState,
  viewLoadingState,
  viewState,
} from "./markup.atom";

export const states: RecoilStates<MarkupState> = {
  loading: loadingState,
  markups: markupsState,
  view: viewState,
  viewLoading: viewLoadingState,
  selected: selectedState,
  saving: savingState,
};

export const markupState = genericSelector("markupState", states);

export function useMarkupSelector<K extends keyof MarkupState>(
  keys: K[] = []
): Pick<MarkupState, K> {
  return useSelector(keys, states);
}
