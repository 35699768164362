import { useDeepEffect } from "@common/utils/use-deep-effect";
import { useAsyncWorker } from "@common/utils/use-worker";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { WithContext } from "../../../contexts";
import { useSockets } from "../../../contexts/SocketContext";
import { useProject } from "../../projects/view/ProjectContext";
import { Mp3EncoderArgs } from "../helpers/workers/mp3-encoder";
import { ProcessStemsArgs, StemBuffer } from "../helpers/workers/process-stems";
import { WavEncoderArgs } from "../helpers/workers/wav-encoder";
import { CollabState } from "../recoil/collab/collab.atom";
import { CollabController } from "../recoil/collab/collab.controller";
import { useCollabSelector } from "../recoil/collab/collab.selector";
import { useCollabSocket } from "../recoil/collab/collab.socket";
import { ExportsController } from "../recoil/exports/exports.controller";
import { MarkupState } from "../recoil/markup/markup.atom";
import { MarkupController } from "../recoil/markup/markup.controller";
import { useMarkupSelector } from "../recoil/markup/markup.selector";
import { useMarkupSocket } from "../recoil/markup/markup.socket";
import { StemController, StemState, useStemSelector } from "../recoil/stem";
import { useStemSocket } from "../recoil/stem/stem.socket";
import { socketState } from "../recoil/stemviewer.selector";
import { TrackController, TrackState, useTrackSelector } from "../recoil/track";

type StateSelector<T> = <K extends keyof T>(keys?: K[]) => Pick<T, K>;

export interface StemViewerContextType {
  stem: StateSelector<StemState>;
  track: StateSelector<TrackState>;
  markup: StateSelector<MarkupState>;
  collab: StateSelector<CollabState>;
  controllers: StemViewerControllers;
}

export interface StemViewerControllers {
  stem: StemController;
  track: TrackController;
  markup: MarkupController;
  collab: CollabController;
  exports: ExportsController;
}

const initialState = {} as StemViewerContextType;

const StemViewerContext = createContext<StemViewerContextType>(initialState);
StemViewerContext.displayName = "StemViewerContext";

export const useStemViewer = () => useContext(StemViewerContext);

const createProcessStemsWorker = () =>
  new Worker(new URL("../helpers/workers/process-stems", import.meta.url), {
    type: "module",
  });

const createWavEncoderWorker = () =>
  new Worker(new URL("../helpers/workers/wav-encoder", import.meta.url), {
    type: "module",
  });

const createMp3EncoderWorker = () =>
  new Worker(new URL("../helpers/workers/mp3-encoder", import.meta.url), {
    type: "module",
  });

export const withStemViewerContext: WithContext = (Component) => (props) => {
  const navigate = useNavigate();
  const { project } = useProject();
  const { svSocket } = useSockets();
  const setSocket = useSetRecoilState(socketState);

  // Audio export worker
  const processStemsFn = useAsyncWorker<ProcessStemsArgs, StemBuffer[]>(
    createProcessStemsWorker
  );
  const wavEncoderFn = useAsyncWorker<WavEncoderArgs, Blob[]>(
    createWavEncoderWorker
  );
  const mp3EncoderFn = useAsyncWorker<Mp3EncoderArgs, Blob[]>(
    createMp3EncoderWorker
  );

  // Initialize controllers
  const [stem] = useState(() => new StemController());
  const [track] = useState(() => new TrackController());
  const [markup] = useState(() => new MarkupController(navigate));
  const [collab] = useState(() => new CollabController());
  const [exports] = useState(
    // @ts-ignore
    () => new ExportsController(processStemsFn, wavEncoderFn, mp3EncoderFn)
  );

  // Initialize socket handlers
  const Sockets = () => {
    useCollabSocket();
    useStemSocket();
    useMarkupSocket();
    return <></>;
  };

  // Context value
  const value: StemViewerContextType = {
    stem: useStemSelector,
    track: useTrackSelector,
    markup: useMarkupSelector,
    collab: useCollabSelector,
    controllers: {
      stem,
      track,
      markup,
      collab,
      exports,
    },
  };

  // Set socket
  useEffect(() => {
    setSocket(svSocket);
  }, [svSocket]);

  const stopAudio = () => {
    track.stop();
    Howler.stop();
    Howler.unload();
  };

  // Stop track when leaving
  useEffect(() => {
    return stopAudio;
  }, []);

  // Stop track when project.id changes
  useDeepEffect(() => {
    return stopAudio;
  }, [project?.id]);

  return useMemo(() => {
    return (
      <StemViewerContext.Provider value={value}>
        <Sockets />
        <Component {...props} />
      </StemViewerContext.Provider>
    );
  }, []);
};
