import { useDebouncedValue, useMouse, useWindowEvent } from "@mantine/hooks";
import { motion } from "framer-motion";
import { clamp } from "lodash";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";
import { BsCaretDownFill } from "react-icons/bs";

import { useStemViewer } from "../../../contexts/StemViewerContext";
import { stemPanelWidth } from "../../../helpers/constants";

interface NeedleProps {
  pinchingRef: MutableRefObject<boolean>;
  scrubbing: boolean;
  setScrubbing: Dispatch<SetStateAction<boolean>>;
}

const Needle: React.FC<NeedleProps> = ({
  pinchingRef,
  scrubbing,
  setScrubbing,
}) => {
  const { currTime, scale } = useStemViewer().track(["currTime", "scale"]);
  const { track } = useStemViewer().controllers;
  const { x } = useMouse();
  const [debouncedX] = useDebouncedValue(x, 15);

  // Scrubbing functionality
  useWindowEvent("mouseup", () => setScrubbing(false));
  useEffect(() => {
    if (!scrubbing) return;
    const trackBody = document.getElementById("track_view");
    if (!trackBody) return;

    const pixels = x - stemPanelWidth + trackBody.scrollLeft;
    const time = track.pixelsToTime(Math.max(0, pixels));

    track.seek(time);
  }, [scrubbing, debouncedX]);

  return useMemo(() => {
    let scrollLeft = 0;
    let scrollWidth = 0;

    if (scrubbing) {
      const el = document.getElementById("track_view");
      scrollLeft = el?.scrollLeft || 0;
      scrollWidth = el?.scrollWidth || 0;
    }

    const left = scrubbing
      ? clamp(0, -10 + x - stemPanelWidth + scrollLeft, scrollWidth)
      : -10 + track.timeToPixels(currTime);

    return (
      <motion.div
        data-quick-assist-id="sv-needle"
        className="absolute px-1 -mx-1 cursor-ew-resize flex flex-col h-[calc(100vh-160px)] items-center top-[-5px]"
        style={{
          left,
        }}
        layout
        transition={{
          duration: scrubbing || pinchingRef.current ? 0 : 0.2,
          ease: "linear",
        }}
      >
        <BsCaretDownFill size={20} className="fill-blue-500" />
        <div className="w-[1px] -translate-y-2 h-full bg-blue-500" />
      </motion.div>
    );
  }, [!scrubbing || x, currTime, scale]);
};

export { Needle };
