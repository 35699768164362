import React from "react";

import { UseAsyncFormReturnType } from "../utils/use-async-form";

import { ActionButtons, ActionButtonsProps } from "./ActionButtons";

interface FormActionButtonsProps
  extends RequiredBy<Partial<ActionButtonsProps>, "onSave"> {
  form: UseAsyncFormReturnType<any>;
  prompt?: boolean;
}

const FormActionButtons: React.FC<FormActionButtonsProps> = ({
  form,
  prompt,
  ...props
}) => {
  // if (prompt) usePrompt(form.isDirty());

  return (
    <ActionButtons
      size="sm"
      loading={form.loading}
      saveDisabled={!form.isDirty()}
      onCancel={form.reset}
      {...props}
    />
  );
};

export { FormActionButtons };
