import { Button } from "@common/components";
import { __prod__ } from "@common/utils/constants";
import { FEATURE_FLAGS, FeatureFlags } from "@common/utils/feature-flags";
import { notification } from "@common/utils/notification";
import { Switch } from "@mantine/core";
import { useDropboxAuthCheck } from "@requests/integrations/dropbox";
import { updateCollab } from "@requests/project/collaborator";
import React, { useEffect, useState } from "react";
import { BsDropbox } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useUser } from "src/contexts/UserContext";
import { useProject } from "src/modules/projects/view/ProjectContext";
import { Unlink } from "tabler-icons-react";

export const DropboxSync = () => {
  const { data } = useDropboxAuthCheck();
  const { project } = useProject();
  const { user } = useUser();

  const [synced, setSynced] = useState(false);
  const [loading, setLoading] = useState(false);

  const dropboxAuthLink = `${
    __prod__ ? "" : "http://localhost:5001"
  }/api/dropbox/auth?redirect=${window.location.href}`;
  const dropboxUnlinkLink = `${
    __prod__ ? "" : "http://localhost:5001"
  }/api/dropbox/auth/revoke?redirect=${window.location.href}`;

  const collab = project?.collaborators.find(
    (collab) => collab.user.id === user?.id
  );

  useEffect(() => {
    if (collab) {
      setSynced(collab.dropboxSyncActive);
    }
  }, [collab]);

  const handleSync = async () => {
    if (!collab) return;
    setLoading(true);
    setSynced(!synced);

    const { error } = await updateCollab(collab?.id, {
      dropboxSyncActive: !synced,
    });

    if (!error && !synced) {
      notification.success(
        "Files from this project will now be synced with Dropbox!"
      );
    }

    setLoading(false);
  };

  if (!FEATURE_FLAGS[FeatureFlags.DropboxSync]) {
    return null;
  }

  if (!data) {
    return (
      <Link to={dropboxAuthLink}>
        <Button color="blue" leftIcon={<BsDropbox />}>
          Sync with Dropbox
        </Button>
      </Link>
    );
  }

  return (
    <div>
      <div className="flex items-center gap-2 text-dark-400 mb-2">
        <BsDropbox />
        <span>Dropbox sync is active</span>

        <Link to={dropboxUnlinkLink}>
          <Button
            size="xs"
            color="red"
            variant="subtle"
            leftIcon={<Unlink className="w-3 h-3" />}
          >
            Unlink
          </Button>
        </Link>
      </div>

      <Switch
        disabled={!collab || loading}
        label="Enable sync"
        checked={synced}
        onChange={handleSync}
      />
    </div>
  );
};
