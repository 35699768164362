import { DotsMenu } from "@common/components";
import UserItem from "@common/components/UserItem";
import { PaperAirplaneIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ExitIcon } from "@modulz/radix-icons";
import { Face } from "@mui/icons-material";
import { Collaborator } from "@server/entities/project";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../../../contexts/UserContext";
import { isAdmin } from "../../helpers/role";
import { useProject } from "../ProjectContext";

import { SelectRole } from "./SelectRole";

interface CollaboratorRowProps {
  collab: Collaborator;
  onChange: (collab: Collaborator, role: Collaborator["role"]) => void;
  onRemove: (collab: Collaborator) => void;
  onLeave: (collab: Collaborator) => void;
}

const CollaboratorRow: React.FC<CollaboratorRowProps> = ({
  collab,
  onChange,
  onRemove,
  onLeave,
}) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { view } = useProject();

  return (
    <tr data-testid="collaborator-row">
      <td>
        <UserItem user={collab.user} />
      </td>

      <td className="w-40">
        <SelectRole
          data-testid="collaborator-role"
          withinPortal
          className="ml-auto"
          size="sm"
          disabled={!isAdmin(view)}
          value={collab.role}
          onChange={(role) => onChange(collab, role)}
        />
      </td>

      <td className="w-16">
        <DotsMenu data-testid="collaborator-menu">
          <DotsMenu.Dropdown>
            <DotsMenu.Item icon={<PaperAirplaneIcon className="w-5 h-5" />}>
              Send message
            </DotsMenu.Item>

            <DotsMenu.Item
              icon={<Face fontSize="small" />}
              onClick={() => navigate(`/u/${collab.user.username}`)}
            >
              View profile
            </DotsMenu.Item>

            {view === "admin" && collab.userId !== user?.id && (
              <DotsMenu.Item
                data-testid="remove-user"
                color="red"
                disabled={isAdmin(collab.role)}
                onClick={() => onRemove(collab)}
                icon={<TrashIcon className="w-5 h-5" />}
              >
                Remove user
              </DotsMenu.Item>
            )}

            {collab.userId === user?.id && (
              <DotsMenu.Item
                data-testid="leave-project"
                color="red"
                onClick={() => onLeave(collab)}
                icon={<ExitIcon className="w-5 h-5" />}
              >
                Leave project
              </DotsMenu.Item>
            )}
          </DotsMenu.Dropdown>
        </DotsMenu>
      </td>
    </tr>
  );
};

export { CollaboratorRow };
