import { LoadingOverlay } from "@mantine/core";
import { getMe } from "@requests/auth/auth";
import { useMyMarkups } from "@requests/project/markup";
import { useStarredProjects } from "@requests/project/project";
import { PaginatedListDto } from "@server/dto/paginated-list.dto";
import { User } from "@server/entities";
import { Markup, Project } from "@server/entities/project";
import React, { createContext, useContext } from "react";
import { KeyedMutator, SWRResponse } from "swr";

import { WithContext } from "./with-contexts";

export interface UserContextType {
  loading: boolean;
  user: User | undefined;
  markups: SWRResponse<PaginatedListDto<Markup>>;
  starred: Project[];
  mutateStarred: KeyedMutator<any>;
  mutate: KeyedMutator<any>;
}

const initialState = {} as UserContextType;

const UserContext = createContext<UserContextType>(initialState);
UserContext.displayName = "UserContext";

export const useUser = () => useContext(UserContext);

export const withUserContext: WithContext = (Component) => (props) => {
  const { data: user, mutate, isValidating: loading } = getMe();
  const markups = useMyMarkups({ assignedToMe: true });
  const { data: projects, mutate: mutateStarred } = useStarredProjects();

  return (
    <UserContext.Provider
      value={{
        loading,
        user,
        mutate,
        markups,
        starred: projects || [],
        mutateStarred,
      }}
    >
      <LoadingOverlay
        visible={loading}
        transitionDuration={1000}
        loaderProps={{ size: "xs", variant: "oval" }}
        overlayColor="#000"
        overlayOpacity={0.5}
        className="backdrop-blur"
      />
      <Component {...props} />
    </UserContext.Provider>
  );
};
