import { CameraIcon } from "@heroicons/react/24/solid";
import { ModalProps } from "@mantine/core";
import { uploadFile } from "@requests/file";
import React, { useState } from "react";
import Cropper from "react-easy-crop";

import { notification } from "../utils/notification";

import { Button } from "./Button";
import { Divider } from "./Divider";
import { Input } from "./Input";
import { Modal } from "./Modal";
import Placeholder from "./Placeholder";

export type UploadImageProps = Partial<ModalProps> & {
  children?: React.ReactNode;
  className?: string;
  aspect?: number;
  onUpload?: (url: string) => void;
  targetWidth?: number;
  targetHeight?: number;
  projectId?: string;
  dataTestId?: string;
};

export const UploadImageModal = (props: UploadImageProps) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File>();
  const [url, setUrl] = useState<string>();
  const [validUrl, setValidUrl] = useState(true);

  const {
    opened: _open,
    onClose,
    children,
    className,
    aspect,
    targetWidth = 256,
    targetHeight = 256,
    onUpload,
    projectId,
    dataTestId,
    ...modalProps
  } = props;

  const handleDrop = (file: File) => {
    // let url = URL.createObjectURL(file);
    // let img = new Image();
    //
    // img.onload = function () {
    //   if (targetWidth && targetHeight) {
    //     if (img.width < targetWidth || img.height < targetHeight) {
    //       return notification.error(
    //         `Image must be at least ${targetWidth}x${targetHeight}px`
    //       );
    //     }
    //   }

    setFile(file);

    //   URL.revokeObjectURL(img.src);
    // };
    //
    // img.src = url;
  };

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const cropImage = async (file: File, croppedArea: CroppedAreaType) => {
    const image = await createImage(URL.createObjectURL(file));

    const baseCvs = document.createElement("canvas");
    const baseCtx = baseCvs.getContext("2d");

    const imageCvs = document.createElement("canvas");
    const imageCtx = imageCvs.getContext("2d");

    const { x, y, width } = croppedArea;

    const scale = targetWidth / width;

    // Stretch and copy image onto base canvas
    baseCvs.width = image.width * scale;
    baseCvs.height = image.height * scale;
    baseCtx?.drawImage(image, 0, 0, image.width * scale, image.height * scale);
    let data = baseCtx?.getImageData(
      0,
      0,
      image.width * scale,
      image.height * scale
    );

    if (!data) return;

    // Put image data into secondary canvas
    imageCvs.width = targetWidth;
    imageCvs.height = targetHeight;
    imageCtx?.putImageData(data, -x * scale, -y * scale);

    return imageCvs.toBlob(
      (blob) => uploadImage(blob, file.name),
      "image/jpeg",
      0.8
    );
  };

  const uploadImage = async (
    blob: Blob | null,
    name = `${new Date().toString()}`
  ) => {
    if (!blob) return;
    const file = new File([blob], `${name}.jpg`);

    const { data, error } = await uploadFile({
      file,
      filetype: "image",
      projectId,
    });

    if (error) return notification.error(error.message);

    onUpload && onUpload(data.url);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setFile(undefined);
    onClose && onClose();
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
    setValidUrl(true);
  };

  const handleSaveUrl = () => {
    if (!url) return setValidUrl(false);
    // Check URL starts with http(s)://
    if (!url.match(/(http(s)?:\/\/)/)) return setValidUrl(false);

    onUpload && onUpload(url);
    setUrl("");
    handleClose();
  };

  return (
    <React.Fragment>
      <Modal
        opened={open || !!_open}
        onClose={handleClose}
        size="sm"
        {...modalProps}
      >
        <h2 className="mb-5">Upload image</h2>

        {file ? (
          <ImageEditor
            file={file}
            aspect={targetWidth / targetHeight}
            onSubmit={(croppedArea) => file && cropImage(file, croppedArea)}
          />
        ) : (
          <Placeholder
            icon={<CameraIcon className="w-10 h-10" />}
            content="Click to upload"
            dropzone
            onDrop={handleDrop}
          />
        )}

        {!file && (
          <>
            <Divider label="OR" labelPosition="center" />

            <div className="flex items-start gap-4">
              <Input
                label="Insert from URL"
                value={url}
                onChange={handleUrlChange}
                error={validUrl ? undefined : "Invalid URL"}
                className="flex-1"
              />
              <Button className="mt-9" onClick={handleSaveUrl}>
                Save
              </Button>
            </div>
          </>
        )}
      </Modal>

      {children && (
        <div
          onClick={() => setOpen(true)}
          className={`relative cursor-pointer ${className}`}
          data-testid={dataTestId}
        >
          <div className="absolute w-full h-full z-50 bg-dark-900 transition bg-opacity-0 opacity-0 hover:bg-opacity-50 hover:opacity-100">
            <CameraIcon className="w-full h-full scale-[0.4]" />
          </div>
          {children}
        </div>
      )}
    </React.Fragment>
  );
};

export type ImageEditorProps = {
  file: File;
  aspect: number;
  onSubmit: (croppedArea: CroppedAreaType) => void;
};

export type CroppedAreaType = {
  x: number;
  y: number;
  width: number;
  height: number;
};

const ImageEditor = ({ file, aspect = 1, onSubmit }: ImageEditorProps) => {
  const [image] = useState(URL.createObjectURL(file));
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedArea, setCroppedArea] = useState<CroppedAreaType>({
    x: 0,
    y: 0,
    width: 128,
    height: 128,
  });

  const handleDone = () => {
    onSubmit(croppedArea);
  };

  const handleCropComplete = (_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  return (
    <div>
      <Cropper
        classes={{
          containerClassName:
            "relative initial h-[30rem] p-10 overflow-hidden bg-dark-900 rounded-xl",
          mediaClassName: "rounded-xl",
        }}
        image={image}
        crop={crop}
        zoom={zoom}
        aspect={aspect}
        onCropChange={setCrop}
        onCropComplete={handleCropComplete}
        onZoomChange={setZoom}
      />

      <Button
        data-testid="upload-image-save"
        className="float-right mt-6"
        onClick={handleDone}
      >
        Done
      </Button>
    </div>
  );
};
