import { Card, useMantineTheme } from "@mantine/core";
import { useDebouncedValue, useElementSize } from "@mantine/hooks";
import { PauseRounded, PlayArrowRounded } from "@mui/icons-material";
import { File as FileEntity } from "@server/entities";
import { ActionIcon } from "@synqup/ui/src/components/ActionIcon";
import Skeleton from "@synqup/ui/src/components/Skeleton";
import {
  pixelsToTime,
  timeToPixels,
} from "@synqup/ui/src/utils/time-pixel-converter";
import { useDeepEffect } from "@synqup/ui/src/utils/use-deep-effect";
import { useWorker } from "@synqup/ui/src/utils/use-worker";
import { useDrag } from "@use-gesture/react";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";

import { getFilename } from "../../contexts/MediaLibrary/components/FileLibrary";
import { usePlayer, usePlayerState } from "../../contexts/Player";
import { MixdownTime } from "../../modules/projects/view/home/components/MixdownTime";
import { stemHeight } from "../../modules/stemviewer/helpers/constants";
import { getBuffer } from "../../modules/stemviewer/recoil/helpers/stem";

interface PlayableAudioWaveformProps {
  file: FileEntity;
  isPlaying?: boolean;
}

const createWorker = () =>
  new Worker(
    new URL(
      "../../modules/stemviewer/helpers/workers/get-audio-array-buffer",
      import.meta.url
    ),
    { type: "module" }
  );

export const PlayableAudioWaveform: React.FC<PlayableAudioWaveformProps> = ({
  file,
  isPlaying,
}) => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const [buffer, setBuffer] = useState<AudioBuffer | null>(null);
  const [arrayBuffer, setArrayBuffer] = useState<number[]>([]);
  const { ref, width } = useElementSize();
  const [debouncedWidth] = useDebouncedValue(width - 16, 200);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const { seek, playPause, load } = usePlayer();
  const {
    playing: _playing,
    currTime: _currTime,
    duration,
  } = usePlayerState(["playing", "currTime", "duration"]);

  const currTime = isPlaying ? _currTime : 0;
  const playing = isPlaying ? _playing : false;

  const scale = useMemo(
    () =>
      buffer && debouncedWidth !== 16 ? 4 / (debouncedWidth - 16) : undefined,
    [buffer, debouncedWidth]
  );

  const [{ result }] = useWorker<{ array: number[] }>(createWorker, {
    buffer: buffer?.getChannelData(0),
    duration: buffer?.duration,
    scale,
  });

  // Load mixdown from file if it exists
  useDeepEffect(() => {
    if (!file.url) return;

    setLoading(true);

    axios
      .get(file.url, { responseType: "blob" })
      .then((response) => {
        return new File([response.data], file.filename, {
          type: "audio/mp3",
        }).arrayBuffer();
      })
      .then((arrayBuffer) => getBuffer(arrayBuffer))
      .then((buffer) => {
        setBuffer(buffer);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [file.url]);

  // Set array buffer whenever scale changed
  useEffect(() => {
    if (!result) return;
    if (!Array.isArray(result.array)) return;
    if (!buffer || !canvas) return;
    if (result?.array.length > 0) {
      setArrayBuffer(result.array);
      draw(canvas, result.array);
    }
  }, [result]);

  // Draw stem whenever array buffer or track duration changes
  useEffect(() => {
    if (!buffer || !canvas) return;
    draw(canvas, arrayBuffer);
  }, [canvas, buffer]);

  // Play audio whenever user drags the needle
  const bind = useDrag(({ xy, down }) => {
    if (!buffer || !down) return;

    const rect = canvas?.getBoundingClientRect();
    if (!rect) return;

    const time = pixelsToTime(
      xy[0] - rect.left,
      debouncedWidth,
      buffer.duration
    );

    if (isPlaying) seek(time);
  }, {});

  const handlePlayPause = () => {
    if (playing) return playPause();

    if (!file.url) return;

    if (isPlaying) {
      return playPause();
    } else {
      load({
        src: file.url,
        name: getFilename(file),
        artist: file.project?.artist || file.user.displayName,
        artwork: file.project?.albumArt,
        fileId: file.id,
      });
    }
  };

  if (loading) return <Skeleton visible height={107} />;

  return (
    <>
      <Card
        className="mt-6 p-1 shadow-2xl touch-none"
        style={{
          background: `linear-gradient(90deg, ${theme.colors.indigo[7]}, ${theme.colors.rose[7]})`,
        }}
        data-testid="project-mixdown"
        data-quick-assist-id="project-mixdown"
        {...bind()}
      >
        <div style={{ position: "relative", touchAction: "none" }} ref={ref}>
          <div
            className="absolute px-4 -top-2 -left-4 h-[110%] bg-indigo-700 opacity-80 mix-blend-color-dodge rounded"
            style={{
              width: timeToPixels(currTime, debouncedWidth, duration) + 22,
              transition: "width 200ms linear",
            }}
          />

          <canvas
            className="animate-opacity"
            ref={setCanvas}
            style={{
              margin: 8,
              height: stemHeight,
            }}
          />
        </div>
      </Card>

      <Card className="relative flex items-center justify-center mb-24 md:mb-12 rounded-tr-none rounded-tl-none p-2">
        <div className="w-full absolute flex items-center gap-2 left-2">
          <MixdownTime />
        </div>

        <ActionIcon
          data-testid="audio-waveform-play-pause"
          variant={playing ? "light" : "filled"}
          color={playing ? "gray" : "primary"}
          onClick={handlePlayPause}
        >
          {playing ? <PauseRounded /> : <PlayArrowRounded />}
        </ActionIcon>
      </Card>
    </>
  );
};

export const draw = (
  canvas: HTMLCanvasElement,
  arrayBuffer: number[],
  height = stemHeight
) => {
  const cvs = canvas;
  if (!cvs) return;

  const ctx = cvs.getContext("2d");
  if (!ctx) return;

  // set width of canvas element
  const scale = window.devicePixelRatio; // Change to 1 on retina screens to see blurry canvas.
  const width = arrayBuffer.length * 4;

  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
  canvas.width = Math.floor(width * scale);
  canvas.height = Math.floor(height * scale);

  ctx.fillStyle = "white";
  ctx.strokeStyle = "white";

  const dpi = window.devicePixelRatio;
  ctx.scale(dpi, dpi);

  const max = Math.max(...arrayBuffer);

  for (let i = 1; i < arrayBuffer.length; i += 1) {
    const a = (arrayBuffer[i] * height) / (max * 2) - 0.5;
    const x2 = i * 4;
    const y2 = height / 2 - a;
    const x3 = i * 4;
    const y3 = height / 2 + a;
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(x2, y2);
    ctx.lineTo(x3, y3);
    ctx.stroke();
  }
};
