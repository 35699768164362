import { Button } from "@common/components";
import Skeleton from "@common/components/Skeleton";
import { notification } from "@common/utils/notification";
import { DragDropContext, OnDragEndResponder } from "@hello-pangea/dnd";
import { Badge } from "@mantine/core";
import { updateMarkups } from "@requests/project/markup";
import { Markup } from "@server/entities";
import { UpdateMarkupDto } from "@server/modules/project/markup/dto/update-markup.dto";
import React, { useEffect, useState } from "react";
import { Check } from "tabler-icons-react";

import { getMarkupColor } from "../../stemviewer/view/markup/components/MarkupStatusBadge";

import { TaskColumn } from "./TaskColumn";

export type TaskBoardState = {
  columns: {
    status: "pending" | "needs_approval" | "approved";
    markups: Markup[];
  }[];
};

interface TaskBoardProps {
  loading?: boolean;
  state?: TaskBoardState;
}

export const TaskBoard: React.FC<TaskBoardProps> = ({
  loading,
  state: initialState,
}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(initialState);
  }, [initialState]);

  const onDragEnd: OnDragEndResponder = ({ source, destination }) => {
    if (!destination) return;

    // Determine updates
    const sourceColumn = state?.columns.find(
      (column) => column.status === source.droppableId
    );
    const destinationColumn = state?.columns.find(
      (column) => column.status === destination.droppableId
    );
    const markup = sourceColumn?.markups.splice(source.index, 1)[0];

    if (!sourceColumn || !destinationColumn || !markup) return;

    destinationColumn.markups.splice(destination.index, 0, markup);

    const updates: UpdateMarkupDto[] = [
      {
        id: markup.id,
        status: destination.droppableId as any,
        index: destination.index,
      },
    ];

    // Update indices
    for (let i = 0; i < destinationColumn.markups.length; i++) {
      if (destinationColumn.markups[i].id === markup.id) continue;

      if (destinationColumn.markups[i].index !== i) {
        updates.push({
          id: destinationColumn.markups[i].id,
          index: i,
        });
      }
    }

    // Update markups
    updateMarkups({ markups: updates }).then(({ error }) => {
      if (error) notification.error(error.message);
    });
  };

  const handleClearAll = () => {
    const approvedColumn = state?.columns.find(
      (column) => column.status === "approved"
    );

    const updates = approvedColumn?.markups.map((markup) => ({
      id: markup.id,
      delete: true,
    }));

    setState({
      columns:
        state?.columns.map((column) => {
          if (column.status !== "approved") return column;
          return { ...column, markups: [] };
        }) || [],
    });

    if (!updates) return;

    updateMarkups({ markups: updates }).then(({ error }) => {
      if (error) notification.error(error.message);
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="bg-dark-800 p-4 rounded" data-testid="task-board">
        {loading && (
          <div className="grid grid-cols-3 gap-2">
            {Array.from({ length: 6 }).map((_, index) => (
              <Skeleton key={index} visible height={80} className="w-full" />
            ))}
          </div>
        )}

        <div className="flex items-center gap-2">
          {state?.columns.map((column) => (
            <div
              key={column.status}
              className="flex-1 flex justify-between items-center"
            >
              <Badge color={getMarkupColor(column.status)}>
                {column.status === "pending"
                  ? "To Do"
                  : column.status === "needs_approval"
                  ? "In Progress"
                  : "Done"}
              </Badge>
              {column.status === "approved" && (
                <Button
                  variant="subtle"
                  color="gray"
                  size="xs"
                  leftIcon={<Check className="w-3 h-3" />}
                  onClick={handleClearAll}
                >
                  Clear All
                </Button>
              )}
            </div>
          ))}
        </div>

        <div className="flex items-stretch gap-4">
          {state?.columns.map((column) => (
            <TaskColumn
              key={column.status}
              markups={column.markups}
              id={column.status}
            />
          ))}
        </div>
      </div>
    </DragDropContext>
  );
};
