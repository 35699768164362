import { AvatarsGroup, Textarea } from "@common/components";
import Confirm from "@common/components/Confirm";
import Skeleton from "@common/components/Skeleton";
import { UploadImageModal } from "@common/components/UploadImageModal";
import { getOverallColor } from "@common/utils/get-overall-color";
import { notification } from "@common/utils/notification";
import { UseAsyncFormReturnType } from "@common/utils/use-async-form";
import { Image } from "@mantine/core";
import { updateProjectPost } from "@requests/project/project";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import React, { useState } from "react";
import { AlertTriangle } from "tabler-icons-react";

import { useProject } from "../ProjectContext";

import { ProjectDescription } from "./ProjectDescription";
import { ProjectSocialIcons } from "./ProjectSocialIcons";

interface ProjectHeaderProps {
  form: UseAsyncFormReturnType<UpdateProjectDto>;
  onAddSocials?: () => void;
}

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  form,
  onAddSocials,
}) => {
  const [albumArtUrl, setAlbumArtUrl] = useState<string | null>(null);
  const { project, view, loading, mutate } = useProject();

  const uploadAlbumArt = (url: string) => {
    setAlbumArtUrl(null);
    getOverallColor(url).then((color) => {
      handleSave({
        albumArt: url,
        albumArtColor: color,
      }).catch();
    });
  };

  const handleUpload = async (url: string) => {
    if (project?.children && project.children.length > 0) {
      setAlbumArtUrl(url);
    } else {
      uploadAlbumArt(url);
    }
  };

  const handleBlur = () => {
    if (form.isDirty("name")) {
      handleSave(form.values).catch();
    }
  };

  const handleSave = async (values: UpdateProjectDto) => {
    if (!project) return;

    const { error } = await updateProjectPost(project?.id as string, values);

    if (error) return notification.error(error.message);

    // Set new initial values
    form.setValues((_values) => ({ ..._values, ...values }), true);

    // Get album art for children
    if (project?.children && project.children.length > 0) {
      mutate().catch();
    }
  };

  return (
    <div className="w-full lg:w-[16rem] xl:w-[24rem]">
      <Confirm
        color="yellow"
        icon={<AlertTriangle className="text-yellow-500 w-8 h-8" />}
        title="Updating album art"
        content="This will update the album art for all projects in this multitrack project. Are you sure you want to continue?"
        opened={!!albumArtUrl}
        onClose={() => setAlbumArtUrl(null)}
        onConfirm={() => albumArtUrl && uploadAlbumArt(albumArtUrl)}
      />

      <div className="flex-1 flex flex-row lg:flex-col items-center lg:items-stretch mb-6 gap-8 w-full">
        {loading ? (
          <Skeleton
            className="w-[10rem] md:w-[16rem] lg:w-full h-full aspect-square"
            visible
          />
        ) : view !== "viewer" ? (
          <UploadImageModal
            targetWidth={1280}
            targetHeight={1280}
            onUpload={handleUpload}
            projectId={project?.id}
            dataTestId="project-album-art"
          >
            <Image
              className="w-[10rem] md:w-[16rem] lg:w-full h-full children:h-full aspect-square"
              radius="md"
              src={form.values.albumArt || "/images/no-album-art.png"}
              withPlaceholder
            />
          </UploadImageModal>
        ) : (
          <Image
            data-testid="project-album-art"
            className="w-[10rem] md:w-[16rem] lg:w-full h-auto"
            radius="md"
            src={form.values.albumArt || "/images/no-album-art.png"}
            withPlaceholder
          />
        )}

        <div className="flex-1 md:w-full">
          {loading ? (
            <Skeleton className="w-full h-16 mb-8" visible />
          ) : (
            <Textarea
              data-testid="project-title"
              data-quick-assist-id="project-title"
              {...form.getInputProps("name")}
              classNames={{
                root: "flex-1",
                input: `w-full font-bold pl-2 -mx-2 bg-dark-1000 bg-opacity-0 ${
                  view !== "viewer"
                    ? "transition hover:bg-opacity-100"
                    : "border-none"
                }`,
              }}
              styles={{
                input: {
                  border: "1px solid transparent",
                  fontSize:
                    form.values.name && form.values.name.length >= 30
                      ? "2.5rem"
                      : "3rem",
                  lineHeight:
                    form.values.name && form.values.name.length >= 30
                      ? "2.5rem"
                      : "3rem",
                },
              }}
              placeholder="Untitled"
              autosize
              minRows={1}
              readOnly={view === "viewer"}
              onBlur={handleBlur}
            />
          )}

          <div className="flex items-center justify-between gap-4 mt-2">
            <div className="flex items-center gap-4">
              {project && (
                <AvatarsGroup
                  noAnimation
                  size="sm"
                  users={project.collaborators.map((collab) => collab?.user)}
                />
              )}

              {project?.artist && (
                <p className="text-lg text-indigo-500 z-50">{project.artist}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2">
        {project && (
          <ProjectSocialIcons form={form} onAddSocials={onAddSocials} />
        )}
      </div>

      <div className="mt-4">
        <ProjectDescription form={form} />
      </div>
    </div>
  );
};
