import { Button, Modal } from "@common/components";
import { ModalProps } from "@mantine/core";
import { Project } from "@server/entities";
import React, { useState } from "react";

import { SearchProjects } from "../../../components/SearchProjects";

interface AddProjectModalProps extends ModalProps {
  onAddProject: (project: Project) => void;
}

export const AddProjectModal: React.FC<AddProjectModalProps> = ({
  onAddProject,
  ...props
}) => {
  const [project, setProject] = useState<Project | null>(null);

  const handleClick = () => {
    if (!project) return;
    onAddProject(project);
    props.onClose();
  };

  return (
    <Modal
      {...props}
      title={<h2>Add project to multitrack</h2>}
      trapFocus={false}
    >
      <span className="text-gray-400">
        Select a project to add to the multitrack
      </span>

      <SearchProjects
        data-testid="search-projects"
        autoFocus={false}
        value={project}
        onChange={setProject}
      />

      <div className="flex justify-end gap-4 mt-4">
        <Button onClick={handleClick}>Add project</Button>
      </div>
    </Modal>
  );
};
