import { FilePondErrorDescription, FilePondFile } from "filepond";
import FilePondPluginValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import React, { useState } from "react";
import { FilePond, FilePondProps, registerPlugin } from "react-filepond";

import { Button, ButtonProps } from "./Button";
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginValidateSize);

export const filePondToFile = (file: FilePondFile) => {
  return new File([file.file], file.filename, {
    ...file.file,
    type: file.fileType,
  });
};

export interface DropzoneProps extends FilePondProps {
  loading?: boolean;
  allowMultiple?: boolean;
  maxFiles?: number;
  onUpload?: (files: File[]) => void;
  button?: ButtonProps;
}

const Dropzone: React.FC<DropzoneProps> = (props) => {
  const {
    loading,
    allowMultiple = true,
    maxFiles = 50,
    onUpload,
    button,
  } = props;

  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<FilePondErrorDescription | null>(null);
  const [filepondFiles, setFilepondFiles] = useState<FilePondFile[]>([]);

  const onUpdateFiles = (files: FilePondFile[]) => {
    setFiles(files.map(filePondToFile));
    setFilepondFiles(files);
  };

  const handleUploadPressed = async () => {
    if (files.length === 0) return;
    if (onUpload) onUpload(files);
    setFiles([]);
    setFilepondFiles([]);
  };

  return (
    <div data-testid="dropzone" data-quick-assist-id="dropzone">
      <div className="border border-dashed border-dark-400 rounded mb-4">
        <FilePond
          checkValidity
          allowFileTypeValidation
          allowFileSizeValidation
          maxFileSize={"150MB"}
          allowMultiple={allowMultiple}
          maxFiles={maxFiles}
          onupdatefiles={onUpdateFiles}
          onaddfile={(error, _) => setError(error)}
          dropValidation={true}
          files={filepondFiles as any}
          {...props}
        />
      </div>

      {files.length > 0 && (
        <div className="flex justify-end">
          <Button
            data-quick-assist-id="dropzone-upload"
            data-testid="dropzone-upload"
            loading={loading}
            disabled={files.length === 0 || !!error}
            onClick={handleUploadPressed}
            size="sm"
            {...button}
          >
            Upload
          </Button>
        </div>
      )}
    </div>
  );
};

export { Dropzone };
