import { Avatar } from "@common/components";
import { Badge, Loader, ScrollArea } from "@mantine/core";
import { getMe } from "@requests/auth/auth";
import { readChannel } from "@requests/social/channel";
import { User } from "@server/entities";
import dayjs from "dayjs";
import { groupBy } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { useChat } from "../../../contexts/Chat/ChatContext";
import { useMessages } from "../../../contexts/Chat/use-messages";
import { useSockets } from "../../../contexts/SocketContext";

import { Message } from "./Message";

interface ChatBodyProps {
  channelId: string;
  user: User;
}

const ChatBody: React.FC<ChatBodyProps> = ({ channelId, user }) => {
  const { data: me } = getMe();
  const { mutate, active } = useChat();
  const viewport = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const { loggedIn } = useSockets();
  const { messages, loading: loadingMessages } = useMessages({
    page,
    channelId,
  });
  const loading = loadingMessages || !loggedIn;

  const latestMessageMemo = useMemo(() => messages.slice(-1)[0], [messages]);

  useEffect(() => {
    if (!viewport.current) return;
    viewport.current.scrollTo({ top: viewport.current.scrollHeight });
  }, [latestMessageMemo, viewport]);

  useEffect(() => {
    const readChannelFn = async () => {
      if (active === channelId) {
        await readChannel(channelId);
        await mutate();
      }
    };

    readChannelFn().catch();
  }, [messages, active]);

  const handleScrollPosition = async ({ y }: { x: number; y: number }) => {
    // if (y !== 0 || loading || !latestMessageMemo) return;
    // setPage((page) => page + 1);
  };

  const groupedMessages = groupBy(messages, (message) =>
    dayjs(message.createdAt).startOf("hour")
  );

  return (
    <ScrollArea
      className="w-full h-full"
      viewportRef={viewport}
      onScrollPositionChange={handleScrollPosition}
    >
      <div className="flex flex-col items-center w-full h-full">
        {!loading && messages.length === 0 && (
          <div className="flex flex-col items-center p-4">
            <Avatar size="lg" className="mb-2" user={user} />
            <h2>
              {user.firstName} {user.lastName}
            </h2>
            <p>Start your conversation with {user.firstName}</p>
          </div>
        )}

        {loading && <Loader size="sm" className="mx-auto my-4" />}

        {Object.values(groupedMessages).map((messages) => (
          <div
            key={messages[0].id}
            className="flex w-full flex-col items-center gap-2 p-4"
          >
            <Badge
              size="xs"
              color="dark"
              className="my-4 bg-dark-700 bg-opacity-50 text-dark-400"
              variant="light"
            >
              {dayjs(messages[0].createdAt).format("ddd h:mm A")}
            </Badge>

            {messages.map((message) => (
              <Message
                key={message.id}
                message={message}
                self={message.senderId === me?.id}
              />
            ))}
          </div>
        ))}
      </div>
    </ScrollArea>
  );
};

export { ChatBody };
