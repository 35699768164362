import {
  ActionIcon,
  Button,
  Divider,
  Modal,
  RouterTab,
  RouterTabs,
} from "@common/components";
import { notification } from "@common/utils/notification";
import useAsyncForm from "@common/utils/use-async-form";
import { usePrompt } from "@common/utils/use-prompt";
import { CogIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { Skeleton } from "@mantine/core";
import { Pencil2Icon } from "@modulz/radix-icons";
import { Comment } from "@mui/icons-material";
import { updateProjectPost } from "@requests/project/project";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router";
import { Link } from "react-router-dom";
import { Books, Checkbox, Microphone, Share } from "tabler-icons-react";

import { SelectProjectStatus } from "../components/SelectProjectStatus";

import { useProject } from "./ProjectContext";
import { InviteUsers } from "./collaborators/InviteUsers";
import { ProjectCollaborators } from "./collaborators/ProjectCollaborators";
import { ProjectFiles } from "./files/ProjectFiles";
import { ProjectChildren } from "./home/ProjectChildren";
import { ProjectCover } from "./home/ProjectCover";
import { ProjectHeader } from "./home/ProjectHeader";
import { ProjectMixdown } from "./home/ProjectMixdown";
import { CreateNote } from "./notes/CreateNote";
import { ProjectNote } from "./notes/ProjectNote";
import { ProjectNotes } from "./notes/ProjectNotes";
import { ProjectSettings } from "./settings/ProjectSettings";
import { ProjectComments } from "./tasks/ProjectComments";
import { ProjectTasks } from "./tasks/ProjectTasks";

export const ProjectView = () => {
  const { project, view, mutate } = useProject();
  const form = useAsyncForm<UpdateProjectDto>({
    initialValues: project as UpdateProjectDto,
  });
  const [projectNote, setProjectNote] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);

  usePrompt(
    "You will lose your changes. Remember to save!",
    form.isDirty() && !form.isDirty("permalink")
  );

  const tabs: RouterTab[] = [
    {
      value: "home",
      label: "Comments",
      icon: <Comment className="w-5 h-5" />,
      "data-testid": "project-comments-tab",
      "data-quick-assist-id": "project-comments-tab",
      component: project ? <ProjectComments projectId={project.id} /> : null,
    },
    {
      value: "files",
      label: "Files",
      icon: <Books className="w-5 h-5" />,
      "data-testid": "project-media-library-tab",
      "data-quick-assist-id": "project-media-library-tab",
      component: <ProjectFiles />,
    },
    {
      value: "tasks",
      label: "Tasks",
      icon: <Checkbox className="w-5 h-5" />,
      "data-testid": "project-tasks-tab",
      "data-quick-assist-id": "project-tasks-tab",
      component: project ? <ProjectTasks projectId={project.id} /> : null,
    },
    {
      value: "threads",
      label: "Threads",
      icon: <Pencil2Icon className="w-5 h-5" />,
      "data-testid": "project-thread-tab",
      "data-quick-assist-id": "project-thread-tab",
      component: (
        <Routes>
          <Route
            path=":noteId"
            element={
              <ProjectNote
                onMount={() => setProjectNote(true)}
                onUnmount={() => setProjectNote(false)}
              />
            }
          />
          <Route path="create" element={<CreateNote />} />
          {project && <Route path="" element={<ProjectNotes />} />}
        </Routes>
      ),
    },
    {
      value: "collab",
      label: "Collaborators",
      icon: <UserGroupIcon className="w-5 h-5" />,
      "data-testid": "project-collaborators-tab",
      "data-quick-assist-id": "project-collaborators-tab",
      component: <ProjectCollaborators />,
    },
  ];

  const handleProjectStatus = async (status: string) => {
    if (!project?.id) return;

    form.setValues({ ...form.values, status }, true);

    const { error } = await updateProjectPost(project.id, {
      status,
    });

    if (error) return notification.error(error.message);
    await mutate();
  };

  const isAdmin = view === "admin";
  const isMultitrack = project?.id && project?.type === "multitrack";

  return (
    <div
      className="container max-w-[128rem]"
      data-testid="project-home"
      data-quick-assist-id="project-home"
    >
      <Helmet>
        <title>{project?.name || "Loading..."} | Synqup</title>
      </Helmet>

      <ProjectCover form={form} />

      <InviteUsers opened={shareModal} onClose={() => setShareModal(false)} />

      {isAdmin && (
        <Modal
          size="lg"
          opened={settingsModal}
          onClose={() => setSettingsModal(false)}
        >
          <ProjectSettings form={form} />
        </Modal>
      )}

      <div className="flex items-start flex-col lg:flex-row gap-16">
        <div className="w-full lg:w-auto">
          <div className="h-[4.5rem]" />

          <ProjectHeader
            form={form}
            onAddSocials={() => setSettingsModal(true)}
          />
        </div>

        <div className="flex-1 w-full">
          <div
            id="project-hotbar"
            className="flex flex-col md:flex-row-reverse items-start md:items-center justify-between gap-2 pb-4"
          >
            <div className="flex items-center justify-start gap-2">
              <SelectProjectStatus
                className="w-36"
                classNames={{ input: "my-0" }}
                size="sm"
                value={project?.status}
                onChange={handleProjectStatus}
              />

              {!isMultitrack && (
                <Link to={`/p/${project?.permalink}/viewer`}>
                  <Button
                    data-testid="launch-stemviewer"
                    size="sm"
                    variant="gradient"
                    leftIcon={<Microphone className="w-5 h-5" />}
                  >
                    Launch StemViewer
                  </Button>
                </Link>
              )}

              <div className="flex items-center gap-2">
                <ActionIcon
                  data-testid="project-share-button"
                  size="lg"
                  onClick={() => setShareModal(true)}
                >
                  <Share className="w-5 h-5" />
                </ActionIcon>

                {isAdmin && (
                  <ActionIcon
                    data-testid="project-settings-button"
                    size="lg"
                    onClick={() => setSettingsModal(true)}
                  >
                    <CogIcon className="w-5 h-5" />
                  </ActionIcon>
                )}
              </div>
            </div>
          </div>

          {project?.permalink ? (
            <>
              {!projectNote && (
                <div className="mb-4">
                  {!isMultitrack && <ProjectMixdown form={form} />}
                  {isMultitrack && <ProjectChildren />}
                </div>
              )}

              {project.mixdowns && project.mixdowns.length > 0 && <Divider />}

              <RouterTabs
                variant="pills"
                basePath={`/p/${project.permalink}`}
                defaultValue={"home"}
                styles={{
                  tabsList: {
                    display: !projectNote ? "flex" : "none",
                  },
                }}
                tabs={tabs}
              />
            </>
          ) : (
            <>
              <Skeleton visible width="100%" height={50} />
              <br />
              <Skeleton visible width="100%" height={100} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
