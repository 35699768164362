import { notification } from "@common/utils/notification";
import { Markup } from "@server/entities/project";
import { useDrag } from "@use-gesture/react";
import { clamp } from "lodash";
import React from "react";

import { useStemViewer } from "../../../contexts/StemViewerContext";

interface MarkupResizeProps {
  markup: Markup;
  isEnd?: boolean;
  onChangeEnd: (end: boolean) => void;
}

export const MarkupResize: React.FC<MarkupResizeProps> = ({
  markup,
  isEnd,
  onChangeEnd,
}) => {
  const { duration } = useStemViewer().track(["duration"]);
  const { track, markup: markupCtl } = useStemViewer().controllers;

  const bind = useDrag(
    ({ delta: [dx], pressed }) => {
      if (!pressed) {
        markupCtl
          .update(markup.id, {
            start: markup.start,
            end:
              markup.start?.toFixed(2) === markup.end?.toFixed(2)
                ? null
                : markup.end,
          })
          .catch(notification.error);
      }

      // User dragging end of markup
      if (isEnd && markup.end) {
        markupCtl.resize(markup.id, {
          start: markup.start,
          end: clamp(
            markup.end + track.pixelsToTime(dx),
            markup.start || 0,
            duration
          ),
        });
      }
      // User dragging single point markup
      else if (!markup.end) {
        markupCtl.resize(markup.id, {
          start: markup.start,
          end: markup.start,
        });
        if (dx > 0) onChangeEnd(true);
        else onChangeEnd(false);
      }
      // User dragging start of markup
      else if (!isEnd) {
        markupCtl.resize(markup.id, {
          start: clamp(
            (markup.start || 0) + track.pixelsToTime(dx),
            0,
            markup.end || duration
          ),
          end: markup.end,
        });
      }
    },
    { axis: "x" }
  );

  if (isEnd && markup.end)
    return (
      <div
        data-quick-assist-id="sv-markup-resize"
        className="absolute z-40 top-0 h-full w-2 touch-none cursor-ew-resize transition-opacity opacity-0 hover:opacity-50 active:opacity-100"
        style={{
          left: track.timeToPixels(markup.end) - 3,
        }}
        {...bind()}
      >
        <div className="translate-x-0 w-1 h-full bg-red-500" />
      </div>
    );

  if (isEnd && !markup.end) return <></>;

  return (
    <div
      className="absolute z-40 top-0 h-full w-2 touch-none cursor-ew-resize transition-opacity opacity-0 hover:opacity-50 active:opacity-100"
      style={{
        left: track.timeToPixels(markup.start || 0) - 3,
      }}
      {...bind()}
    >
      <div className="translate-x-0 w-1 h-full bg-red-500" />
    </div>
  );
};
