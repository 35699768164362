import { Socket } from "socket.io-client";
import { getRecoil, setRecoil } from "src/contexts/RecoilNexus";

import { socketState, StemViewerState, svState } from "../stemviewer.selector";

export type PartialSetterOrUpdater<T> = (
  valOrUpdater: ((currVal: T) => DeepPartial<T>) | DeepPartial<T>
) => void;

export class Controller {
  public _state: () => StemViewerState;
  public setState: PartialSetterOrUpdater<StemViewerState>;
  public _socket: () => Socket | null;

  get state() {
    return this._state();
  }

  get socket() {
    return this._socket();
  }

  constructor() {
    this._state = () => getRecoil(svState);
    this.setState = (state) => setRecoil(svState, state);
    this._socket = () => getRecoil(socketState);
  }
}
