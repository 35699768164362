import { QueryDto } from "@server/dto";
import { Notification } from "@server/entities";

import { usePatch, useSWR } from "./helpers";

export const getNotifications = (query?: QueryDto) =>
  useSWR<{
    notifications: Notification[];
    count: number;
  }>("/notif", query);

export const seeNotifications = () => usePatch("/notif");
