import { MantineTheme } from "@mantine/core";

import { mantineTheme } from "./config";

export { mantineTheme };

// Helper function to extend the mantine theme for an app
export const extendMantineTheme = (
  appSpecificTheme: DeepPartial<MantineTheme>
): MantineTheme => {
  return {
    ...mantineTheme,
    ...appSpecificTheme,
    // @ts-ignore
    colors: {
      ...mantineTheme.colors,
      ...(appSpecificTheme.colors || {}),
    },
  };
};
