import { Avatar } from "@common/components";
import { Badge } from "@mantine/core";
import { Note } from "@server/entities/project";
import moment from "moment/moment";
import React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Message } from "tabler-icons-react";

interface NoteListItemProps {
  note: Note;
}

const NoteListItem: React.FC<NoteListItemProps> = ({ note }) => {
  const navigate = useNavigate();
  const { permalink } = useParams<{ permalink: string }>();

  return (
    <div
      data-testid="thread-list-item"
      className="flex items-center gap-4 p-6 cursor-pointer bg-dark-700 bg-opacity-40 hover:bg-opacity-50 rounded"
      onClick={() => navigate(`/p/${permalink}/threads/${note.id}`)}
    >
      <div className="flex-1">
        <h3 data-testid="thread-title" className="flex items-center gap-2">
          {note.title}
          {note.stem && (
            <Badge color="green" radius="sm">
              {note.stem?.name}
            </Badge>
          )}
          <span className="text-dark-400 font-normal">#{note.num}</span>
        </h3>
        <p className="text-slate-400">
          Posted on {moment(note.createdAt).format("LL")}
        </p>
      </div>
      <div className="w-16 text-dark-400">
        <div className="flex items-center gap-2">
          {note.replies.length}
          <Message className="w-5 h-5" />
        </div>
      </div>
      <div className="w-16">
        <Avatar user={note.user} size="sm" />
      </div>
    </div>
  );
};

export { NoteListItem };
