import { useUsers } from "@requests/user/user";
import { User } from "@server/entities";
import React, { useState } from "react";

import { Search, SearchProps } from "./Search";
import UserItem from "./UserItem";

interface SearchUsersProps
  extends Omit<SearchProps<User>, "data" | "onChange" | "value"> {
  value: User | null;
  onChange: (user: User | null) => void;
}

export function SearchUsers({ value, onChange, ...props }: SearchUsersProps) {
  const [search, setSearch] = useState("");
  const { data: users, isValidating } = useUsers({ query: search, limit: 5 });

  return (
    <Search<User>
      loading={isValidating}
      name="search-users"
      nothingFound="No users found"
      placeholder="Search users"
      data={
        users?.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
          data: user,
        })) || []
      }
      onSearchChange={setSearch}
      renderOption={(option) => (
        <UserItem user={option.data} className="m-0 py-0" />
      )}
      value={value?.id}
      onChange={(value) => {
        onChange && onChange(users?.find((user) => user.id === value) || null);
      }}
      {...props}
    />
  );
}
