import { ActionIcon, Button, Modal } from "@common/components";
import { notification } from "@common/utils/notification";
import { useWorker } from "@common/utils/use-worker";
import { ModalProps, Progress } from "@mantine/core";
import { Download, PauseRounded, PlayArrowRounded } from "@mui/icons-material";
import { Player, PlayerRef } from "@remotion/player";
import { getRenderProgress, renderMixdown } from "@requests/project/mixdown";
import { useDrag } from "@use-gesture/react";
import { clamp } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { draw } from "src/common/components/PlayableAudioWaveform";
import {
  AudiogramComposition,
  AudioGramSchema,
  fps,
} from "src/common/remotion/Composition";

import { downloadUrl } from "../../../../../stemviewer/helpers/download-blob";
import { useProject } from "../../../ProjectContext";

const createWorker = () =>
  new Worker(
    new URL(
      "../../../../../stemviewer/helpers/workers/get-audio-array-buffer",
      import.meta.url
    ),
    { type: "module" }
  );

interface MixdownSnippetModalProps extends ModalProps {
  buffer: AudioBuffer;
  audio: string;
}

const pixelTimeScale = 6.1;

export const MixdownSnippetModal: React.FC<MixdownSnippetModalProps> = ({
  audio,
  buffer,
  ...props
}) => {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [playing, setPlaying] = useState(false);
  const playerRef = useRef<PlayerRef | null>(null);
  const [start, setStart] = useState(0);
  const { project } = useProject();
  const [rendering, setRendering] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [outputFile, setOutputFile] = useState<string | null>(null);

  const inputProps = {
    title: project?.name || "Untitled",
    artist: project?.artist || "Unknown",
    audio: audio,
    albumArt: project?.albumArt || "",
    cover: project?.coverArt || "",
    start,
  };

  const [{ result }] = useWorker<{ array: number[] }>(createWorker, {
    buffer: buffer?.getChannelData(0),
    duration: buffer?.duration,
    scale: 0.655 / buffer.duration,
  });

  // Draw stem whenever array buffer or track duration changes
  useEffect(() => {
    if (!result?.array || !canvas) return;
    if (result.array.length === 0) return;
    draw(canvas, result.array, 32);
  }, [canvas, result]);

  useEffect(() => {
    if (!playerRef.current) return;

    playerRef.current.addEventListener("ended", () => {
      setPlaying(false);
    });
  }, [playerRef.current]);

  // Move start position
  const bind = useDrag(({ down, delta }) => {
    if (!down) return;
    playerRef.current?.pauseAndReturnToPlayStart();
    setPlaying(false);
    setStart((start) =>
      clamp(start + -delta[0] / pixelTimeScale, 0, buffer.duration - 15)
    );
  }, {});

  const handlePlayPause = () => {
    if (!playerRef.current) return;

    setPlaying((playing) => {
      if (playing) {
        playerRef.current?.pause();
      } else {
        playerRef.current?.play();
      }
      return !playing;
    });
  };

  const handleRender = async () => {
    setRendering(true);
    const { data: query, error } = await renderMixdown(inputProps);

    if (error || !query) {
      setRendering(false);
      return notification.error(error?.message || "Oops! Something went wrong");
    }

    let done = false;

    while (!done) {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const { data, error } = await getRenderProgress(query);

      if (error) {
        done = true;
        setRendering(false);
        notification.error(error.message);
      }

      if (data?.done) {
        done = true;
        setOutputFile(data?.outputFile);
      }

      if (data?.fatalErrorEncountered) {
        done = true;
        notification.error("Failed to render mixdown");
      }

      setProgress(data?.overallProgress || 0);
    }

    setRendering(false);
  };

  const handleDownloadFile = () => {
    if (!outputFile) return;

    downloadUrl(outputFile, `${project?.name || "Untitled"}-mixdown.mp4`);
  };

  return (
    <Modal {...props}>
      <h2 className="mb-6">Choose 15 seconds of your track to show off</h2>

      <div
        className="h-[calc(100vh-24rem)] mx-auto my-6"
        style={{
          aspectRatio: `${1080} / ${1920}`,
        }}
      >
        <Player
          ref={playerRef}
          component={AudiogramComposition}
          fps={fps}
          compositionWidth={1080}
          compositionHeight={1920}
          // @ts-ignore
          schema={AudioGramSchema}
          inputProps={inputProps}
          className="w-full h-full"
          durationInFrames={15 * fps}
        />
      </div>

      {rendering ? (
        <Progress
          animate={progress === 0}
          value={progress === 0 ? 100 : progress * 100}
          size="sm"
        />
      ) : (
        <div
          {...bind()}
          className="relative bg-dark-700 rounded mt-6 pt-2 pb-1.5 px-[calc(50%-4rem)] overflow-hidden touch-none"
        >
          <ActionIcon
            data-testid="mixdown-snippet-play-pause"
            size="lg"
            className="absolute z-20 left-3 top-1/2 -translate-y-1/2"
            variant={playing ? "light" : "filled"}
            color={playing ? "gray" : "primary"}
            onClick={handlePlayPause}
          >
            {playing ? <PauseRounded /> : <PlayArrowRounded />}
          </ActionIcon>

          <div className="absolute h-full left-1/2 -translate-x-1/2 top-0 w-32 rounded bg-pink-600 mix-blend-color-dodge" />

          <canvas
            id="mixdown_snippet_canvas"
            className="animate-opacity"
            style={{
              transform: `translateX(-${start * pixelTimeScale}px)`,
            }}
            ref={setCanvas}
          />
        </div>
      )}

      <div className="flex justify-end gap-4 mt-6">
        {outputFile ? (
          <Button leftIcon={<Download />} onClick={handleDownloadFile}>
            Download
          </Button>
        ) : (
          <Button loading={rendering} onClick={handleRender}>
            Export
          </Button>
        )}
      </div>
    </Modal>
  );
};
