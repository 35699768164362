import {
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
} from "@mantine/core";
import React, { forwardRef } from "react";

import { getInputDefaults } from "./Input";

export interface SelectProps extends MantineSelectProps {
  renderOption?: { (option: any): React.ReactNode };
}

const Select: React.FC<SelectProps> = (props) => {
  const {
    renderOption = (o) => (
      <div className={`p-2 text-${props.color}-400`}>
        {o.label ? o.label : o}
      </div>
    ),
    ..._props
  } = props;

  const defaults: Omit<SelectProps, "data"> = getInputDefaults(props);

  const SelectableItem = forwardRef<HTMLDivElement>((props, ref) => {
    return (
      <div ref={ref} {...props} style={{ padding: 0 }}>
        {renderOption(props)}
      </div>
    );
  });

  return (
    <MantineSelect
      transition="fade"
      transitionDuration={100}
      transitionTimingFunction="ease"
      itemComponent={SelectableItem}
      {...defaults}
      {..._props}
      classNames={{
        input: `${defaults.classNames?.input || ""} ${props.classNames?.input}`,
        ...defaults.classNames,
      }}
    />
  );
};

export { Select };
