import { useAudioData, visualizeAudio } from "@remotion/media-utils";
import React, { useRef } from "react";
import {
  AbsoluteFill,
  Audio,
  Img,
  Sequence,
  useCurrentFrame,
  useVideoConfig,
} from "remotion";
import { z } from "zod";

export const fps = 30;

export const AudioGramSchema = z.object({
  title: z.string(),
  artist: z.string(),
  audio: z.string().refine((s) => s.endsWith(".mp3"), {
    message: "Audio file must be a .mp3 file",
  }),
  albumArt: z
    .string()
    .refine(
      (s) =>
        s.endsWith(".jpg") ||
        s.endsWith(".jpeg") ||
        s.endsWith(".png") ||
        s.endsWith(".bmp"),
      {
        message: "Album art file must be a .jpg / .jpeg / .png / .bmp file",
      }
    ),
  cover: z
    .string()
    .refine(
      (s) =>
        s.endsWith(".jpg") ||
        s.endsWith(".jpeg") ||
        s.endsWith(".png") ||
        s.endsWith(".bmp"),
      {
        message: "Image file must be a .jpg / .jpeg / .png / .bmp file",
      }
    ),
  start: z.number().optional(),
});

type AudiogramCompositionSchemaType = z.infer<typeof AudioGramSchema>;

const AudioViz: React.FC<{
  numberOfSamples: number;
  freqRangeStartIndex: number;
  waveLinesToDisplay: number;
  audioSrc: string;
}> = ({
  numberOfSamples,
  freqRangeStartIndex,
  waveLinesToDisplay,
  audioSrc,
}) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const audioData = useAudioData(audioSrc);

  if (!audioData) {
    return null;
  }

  const frequencyData = visualizeAudio({
    fps,
    frame,
    audioData,
    numberOfSamples, // Use more samples to get a nicer visualisation
  });

  // Pick the low values because they look nicer than high values
  // feel free to play around :)
  const frequencyDataSubset = frequencyData.slice(
    freqRangeStartIndex,
    freqRangeStartIndex + Math.round(waveLinesToDisplay / 2)
  );

  const frequenciesToDisplay = [
    ...frequencyDataSubset.slice(1).reverse(),
    ...frequencyDataSubset,
  ];

  return (
    <div className="flex items-end justify-between h-24 absolute bottom-24 w-[calc(100%-12rem)]">
      {frequenciesToDisplay.map((v, i) => {
        return (
          <div
            key={i}
            className="w-2 mix-blend-color-dodge rounded"
            style={{
              minWidth: "1px",
              backgroundColor: "#f5f5f6",
              height: `${
                6 +
                500 *
                  Math.sqrt(v) *
                  ((Math.sin((i + frame) / 6) + 1) / 2 / 2 + 1)
              }%`,
            }}
          />
        );
      })}
    </div>
  );
};

export const AudiogramComposition: React.FC<AudiogramCompositionSchemaType> = ({
  title,
  artist,
  audio,
  albumArt,
  cover,
  start,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const audioOffsetInFrames = Math.round((start || 0) * fps);

  return (
    <div ref={ref}>
      <AbsoluteFill>
        <Sequence from={-audioOffsetInFrames}>
          <Audio pauseWhenBuffering src={audio} />

          <div className="w-full flex items-center p-24 bg-black relative -z-10">
            <div className="absolute top-32 right-8 flex items-center gap-4 mx-8 my-3">
              <Img
                className="w-20 h-20"
                src="https://synqup-s3-dev.s3.ap-southeast-2.amazonaws.com/logo-v3.svg"
                alt="Synqup logo"
              />
              <h1 className="text-white text-6xl font-normal m-0 mb-1">
                synq
                <span className="font-bold bg-gradient-to-tr from-purple-400 to-blue-400 bg-clip-text text-transparent">
                  up
                </span>
              </h1>
            </div>

            <div className="w-full">
              <Img
                className="w-full h-auto cover rounded overflow-hidden"
                src={albumArt}
              />

              <Img
                className="absolute -z-30 w-full h-full object-cover left-0 top-0"
                src={cover}
              />
              <div className="absolute -z-20 w-full h-full left-0 top-0 from-black to-transparent bg-gradient-to-t" />

              <div className="text-8xl text-white mt-16">{title}</div>

              <div className="relative">
                <div
                  style={{ color: "#cecece" }}
                  className="text-5xl font-bold mix-blend-color-dodge mt-8"
                >
                  {artist}
                </div>

                <div
                  style={{ color: "#cecece" }}
                  className="text-5xl font-bold opacity-50 absolute top-0"
                >
                  {artist}
                </div>
              </div>

              <div>
                <AudioViz
                  audioSrc={audio}
                  numberOfSamples={Number(256)}
                  freqRangeStartIndex={20}
                  waveLinesToDisplay={64}
                />
              </div>
            </div>
          </div>
        </Sequence>
      </AbsoluteFill>
    </div>
  );
};
