import { DatePicker, Button, Textarea, Avatar } from "@common/components";
import Skeleton from "@common/components/Skeleton";
import { notification } from "@common/utils/notification";
import useAsyncForm from "@common/utils/use-async-form";
import { Table } from "@mantine/core";
import { useClickOutside, useLocalStorage } from "@mantine/hooks";
import { FetchResponse } from "@requests/helpers";
import { createMarkup } from "@requests/project/markup";
import { Markup } from "@server/entities";
import { CreateMarkupDto } from "@server/modules/project/markup/dto/create-markup.dto";
import React, { useRef, useState } from "react";
import { Plus } from "tabler-icons-react";

import { useUser } from "../../../contexts/UserContext";
import { useProject } from "../../projects/view/ProjectContext";

import SearchProjects from "./SearchProjects";
import TaskRow from "./TaskRow";

interface TasksTableProps {
  tasks?: Markup[];
  mutate: () => void;
  isProject?: boolean;
}

export const TasksTable: React.FC<TasksTableProps> = ({
  tasks,
  mutate,
  isProject,
}) => {
  const timeout = React.useRef<NodeJS.Timeout>();
  const [assignedToMe] = useLocalStorage({
    key: "tasks.assignedToMe",
    defaultValue: false,
  });
  const [creatingTask, setCreatingTask] = useState(false);

  const { user } = useUser();
  const { project } = useProject();

  const form = useAsyncForm<CreateMarkupDto>({
    initialValues: {
      title: "",
      type: "task",
      project: project,
      dueDate: null,
      assignee: assignedToMe ? user : undefined,
    },
  });

  const handleUpdate = () => {
    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      mutate();
    }, 1500);
  };

  const datePickerOpened = useRef(false);
  const searchProjectsOpened = useRef(false);

  const handleCreateTask = async () => {
    if (datePickerOpened.current || searchProjectsOpened.current) return;

    const { error } = await form.sendForm(async (values) => {
      if (!values.title || values.title === "" || !values.project) {
        setCreatingTask(false);
        return { error: undefined } as FetchResponse<any>;
      }

      return createMarkup({
        ...values,
        projectId: values.project?.id,
        reporter: user,
      });
    });

    if (error) return notification.error(error.message);

    setCreatingTask(false);
    form.reset();
    mutate();
  };

  const ref = useClickOutside(handleCreateTask);

  const inputClassname = "border-none rounded-none bg-transparent text-sm";

  if (!tasks) {
    return (
      <div className="mt-4">
        {Array.from({ length: 3 }).map((_, i) => (
          <Skeleton className="mb-2" key={i} visible height={40} />
        ))}
      </div>
    );
  }

  if (tasks.length === 0 && !creatingTask)
    return (
      <div className="flex flex-col items-center justify-center gap-2 my-8">
        <span>🎉</span>
        <span>You&apos;re all caught up!</span>
        <Button
          size="sm"
          className="mt-4 opacity-60 hover:opacity-100"
          leftIcon={<Plus className="w-4 h-4" />}
          color="purple"
          variant="subtle"
          onClick={() => setCreatingTask(true)}
        >
          Add a task
        </Button>
      </div>
    );

  return (
    <div data-testid="tasks-table">
      <Table
        ref={ref}
        className="divide-x-0 divide-y divide-solid divide-dark-700 mt-2"
      >
        <thead>
          <tr>
            <th className="w-12" />
            <th>Task</th>
            <th className="w-32" align="center">
              Project
            </th>
            {isProject && (
              <th className="w-32" align="center">
                Assignee
              </th>
            )}
            <th className="w-32" align="center">
              Due date
            </th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((markup) => (
            <TaskRow
              key={markup.id}
              markup={markup}
              isProject={isProject}
              onUpdate={handleUpdate}
            />
          ))}
          {creatingTask && (
            <tr>
              <td className="p-0" />
              <td className="p-0">
                <Textarea
                  data-testid="task-title"
                  value={form.values.title}
                  onChange={(e) => form.setFieldValue("title", e.target.value)}
                  placeholder="Task title"
                  classNames={{
                    input: inputClassname + " pl-3",
                  }}
                  minRows={1}
                  autosize
                />
              </td>
              {isProject && (
                <td>
                  <Avatar size="sm" user={form.values.assignee} placeholder />
                </td>
              )}
              <td className="p-0" align="center">
                <SearchProjects
                  data-testid="task-project"
                  onDropdownOpen={() => (searchProjectsOpened.current = true)}
                  onDropdownClose={() => (searchProjectsOpened.current = false)}
                  value={form.values.project}
                  onChange={(value) =>
                    value && form.setFieldValue("project", value)
                  }
                  classNames={{
                    input: inputClassname,
                  }}
                  rightSection={<></>}
                />
              </td>
              <td className="p-0 relative" align="center">
                <DatePicker
                  data-testid="task-due-date"
                  onDropdownOpen={() => (datePickerOpened.current = true)}
                  onDropdownClose={() => (datePickerOpened.current = false)}
                  value={form.values.dueDate}
                  onChange={(date) => form.setFieldValue("dueDate", date)}
                  placeholder="Due date"
                  inputFormat="D/M/YY"
                  classNames={{
                    input: inputClassname,
                  }}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {!creatingTask && (
        <Button
          data-testid="create-task"
          size="sm"
          leftIcon={<Plus className="w-4 h-4" />}
          color="purple"
          variant="subtle"
          className="mt-2 ml-10 opacity-60 hover:opacity-100"
          onClick={() => setCreatingTask(true)}
        >
          Add a task
        </Button>
      )}
    </div>
  );
};
