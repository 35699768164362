import { MultiSearch, MultiSearchProps } from "@common/components";
import { getGenres } from "@requests/project/genre";
import { Genre } from "@server/entities/project";
import { uniqBy } from "lodash";
import React, { useState } from "react";

interface SearchGenresProps
  extends Omit<MultiSearchProps<Genre>, "data" | "onChange" | "value"> {
  value: Genre[] | null;
  onChange: (genres: Genre[]) => void;
}

export function SearchGenres({ value, onChange, ...props }: SearchGenresProps) {
  const [query, setQuery] = useState("");
  const { data: genres } = getGenres(query);
  const allGenres = uniqBy(
    (genres || []).concat(value || []),
    (genre) => genre.id
  );

  const handleChange = (values: string[]) => {
    const newGenres: Genre[] = [];

    for (const value of values) {
      const genre = allGenres.find((genre) => genre.id === value);
      if (genre) newGenres.push(genre);
    }

    onChange && onChange(newGenres);
  };

  return (
    <div>
      <MultiSearch<Genre>
        name="search-genres"
        rightSection={<></>}
        nothingFound="No genres found"
        placeholder="Search genres"
        data={
          allGenres?.map((genre) => ({
            label: `${genre.name}`,
            value: genre.id,
            data: genre,
          })) || []
        }
        onSearchChange={setQuery}
        value={value?.map((genre) => genre.id)}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
}
