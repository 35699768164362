import { Badge, BadgeProps } from "@mantine/core";
import { Markup } from "@server/entities";
import React from "react";
import { FaPenNib } from "react-icons/fa";
import { Checkbox } from "tabler-icons-react";
import { humanize } from "underscore.string";

interface TaskTypeBadgeProps extends BadgeProps {
  value: Markup["type"];
}

export function getTaskColor(type: Markup["type"]) {
  switch (type) {
    case "task":
      return "blue";
    default:
      return "red";
  }
}

export function getTaskIcon(type: Markup["type"]) {
  switch (type) {
    case "task":
      return <Checkbox className="w-3 h-3 translate-y-0.5" />;
    default:
      return <FaPenNib className="translate-y-0.5" />;
  }
}

export const TaskTypeBadge: React.FC<TaskTypeBadgeProps> = ({
  value,
  ...props
}) => {
  return (
    <Badge
      {...props}
      color={getTaskColor(value)}
      leftSection={getTaskIcon(value)}
      radius={4}
    >
      {humanize(value)}
    </Badge>
  );
};
