import {
  genericSelector,
  RecoilStates,
  useSelector,
} from "../helpers/selectors";

import { CollabState, joinedState, meState, miceState } from "./collab.atom";

export const states: RecoilStates<CollabState> = {
  me: meState,
  mice: miceState,
  joined: joinedState,
};

export const collabState = genericSelector("collabState", states);

export function useCollabSelector<K extends keyof CollabState>(
  keys: K[] = []
): Pick<CollabState, K> {
  return useSelector(keys, states);
}
