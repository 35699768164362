import { ActionIcon } from "@common/components";
import { Dialog, Progress } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { ChevronRight, Minus } from "tabler-icons-react";

import { useMediaLibraryState } from "../media-library.selector";

export const UploadProgressModal: React.FC = () => {
  const [minimized, setMinimized] = useState(false);

  const { uploading, uploaded, currentUpload, total, percent } =
    useMediaLibraryState([
      "uploading",
      "uploaded",
      "currentUpload",
      "total",
      "percent",
    ]);

  useEffect(() => {
    setMinimized(false);
  }, [total]);

  return (
    <Dialog
      className="relative"
      classNames={{
        root: minimized ? "-translate-x-[120%]" : "",
      }}
      opened={uploading}
      position={{ top: 84, left: 28 }}
      size="md"
    >
      <b>
        Uploading files{" "}
        <span className="text-indigo-400">
          ({uploaded + 1}/{total || 1})
        </span>
      </b>
      <p className="text-indigo-400">{currentUpload?.file.name}</p>

      <Progress
        animate={percent === 0}
        value={percent === 0 ? 100 : percent}
        size="sm"
      />

      <ActionIcon
        variant="subtle"
        className="absolute right-2 top-2"
        onClick={() => setMinimized(true)}
      >
        <Minus />
      </ActionIcon>

      {minimized && (
        <ActionIcon
          className="absolute translate-x-[70%] -right-[20%] top-2"
          color="purple"
          variant="light"
          size="lg"
          radius="xl"
          onClick={() => setMinimized(false)}
        >
          <ChevronRight className="w-5 h-5" />
        </ActionIcon>
      )}
    </Dialog>
  );
};
