import {
  Card as MantineCard,
  CardProps as MantineCardProps,
  CardSection,
} from "@mantine/core";
import React, {
  forwardRef,
  ForwardRefExoticComponent,
  HTMLProps,
  RefAttributes,
} from "react";

type CardType = ForwardRefExoticComponent<
  CardProps &
    Omit<HTMLProps<HTMLDivElement>, "ref"> & {
      variant?: "outlined" | undefined;
    } & RefAttributes<HTMLDivElement>
> & { Section: typeof CardSection };

type CardProps = MantineCardProps &
  Omit<HTMLProps<HTMLDivElement>, "ref"> & {
    variant?: "outlined" | undefined;
  };

const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const defaults: CardProps = {
    children: props.children,
    p: "lg",
  };

  let className = `bg-dark-700 shadow-md ${props.className}`;
  defaults.style = { overflow: "visible" };

  if (props.variant === "outlined") {
    className = `border border-solid border-dark-600 ${props.className}`;
    defaults.style = { ...defaults.style, backgroundColor: "transparent" };
  }

  return (
    <MantineCard ref={ref} {...defaults} {...props} className={className}>
      {props.children}
    </MantineCard>
  );
}) as CardType;

Card.Section = MantineCard.Section;

export { Card };
