import { useSocketListener } from "@requests/helpers/use-socket-listener";
import { Collaborator } from "@server/entities/project";
import { UpdateMouseDto } from "@server/modules/project/collaborator/dto/update-mouse.dto";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { useSockets } from "../../../../contexts/SocketContext";
import { useProject } from "../../../projects/view/ProjectContext";
import { useStemViewer } from "../../contexts/StemViewerContext";

import { joinedState } from "./collab.atom";

export const useCollabSocket = () => {
  const { project } = useProject();
  const { svSocket: socket } = useSockets();
  const setJoined = useSetRecoilState(joinedState);
  const { collab } = useStemViewer().controllers;

  // Join stemviewer once socket connection established, leave when stemviewer left
  useSocketListener(
    socket,
    "connect",
    () => {
      if (project?.id) socket.emit("collab:join", { projectId: project.id });
    },
    [project]
  );

  // Or, if socket connection already established, join stemviewer
  useEffect(() => {
    if (socket.connected && project)
      socket.emit("collab:join", { projectId: project.id });

    return () => {
      if (socket.connected && project)
        socket.emit("collab:leave", { projectId: project.id });
    };
  }, [socket, project]);

  // Handle joined users
  useSocketListener(socket, "collab:joined", (data: Collaborator[]) => {
    setJoined(data.map((collab) => collab.user));
  });

  // Handle mice
  useSocketListener(socket, "collab:mice", (data: UpdateMouseDto) => {
    collab.receiveMouse(data);
  });
};
