import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { createTheme, ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { RecoilRoot } from "recoil";

import { App } from "./app";
import { PlayerProvider } from "./contexts/Player/PlayerContext";
import RecoilNexus from "./contexts/RecoilNexus";
import { theme } from "./mantine.config";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://9292f7cb2a81f216aca0f34a43242783@o4506931894812672.ingest.us.sentry.io/4506931896123392",
  environment: import.meta.env.VITE_ENVIRONMENT || "local",
  release: import.meta.env.VITE_VERSION_NO || "local",
  enabled: import.meta.env.PROD,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="/*" element={<App />} />)
);

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = createRoot(container);

root.render(
  <RecoilRoot>
    <RecoilNexus />
    <MantineProvider theme={theme as MantineThemeOverride}>
      <ThemeProvider theme={createTheme({})}>
        <NotificationsProvider position="top-center">
          <PlayerProvider>
            <RouterProvider router={router} />
          </PlayerProvider>
        </NotificationsProvider>
      </ThemeProvider>
    </MantineProvider>
  </RecoilRoot>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
