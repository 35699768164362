import {
  ActionIcon,
  Avatar,
  Button,
  Card,
  Divider,
  RichText,
} from "@common/components";
import Confirm from "@common/components/Confirm";
import Skeleton from "@common/components/Skeleton";
import { isEmpty } from "@common/utils/editor";
import useAsyncForm from "@common/utils/use-async-form";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Textarea, Timeline, Transition } from "@mantine/core";
import {
  deleteNote,
  useNote,
  getProjectNotes,
  patchNote,
  postComment,
} from "@requests/project/note";
import { Note as NoteEntity } from "@server/entities/project";
import { UpdateNoteDto } from "@server/modules/project/note/dto";
import React, { useEffect, useState } from "react";
import { BsFloppy } from "react-icons/bs";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { errorNavigator } from "src/modules/redirects/error-navigator";
import { ChevronLeft } from "tabler-icons-react";

import { useUser } from "../../../../contexts/UserContext";
import { useProject } from "../ProjectContext";

import { Note } from "./Note";

interface ProjectNoteProps {
  onMount?: () => void;
  onUnmount?: () => void;
}

const ProjectNote: React.FC<ProjectNoteProps> = ({ onMount, onUnmount }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { project } = useProject();
  const { noteId } = useParams<{ noteId: string }>();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [body, setBody] = useState("");
  const { mutate: mutateAllNotes } = getProjectNotes(project?.id as string);

  const {
    data: note,
    isValidating: loading,
    mutate,
    error,
  } = useNote(noteId as string);

  const form = useAsyncForm<UpdateNoteDto>({
    initialValues: {
      title: note?.title || "",
    },
  });

  useEffect(() => {
    onMount?.();

    return () => onUnmount?.();
  }, []);

  if (error) return errorNavigator(error);

  const handlePost = async () => {
    if (isEmpty(body) || !note) return;

    setBody("");
    await postComment(note.id, { body });
    await mutate(
      (note) =>
        ({
          ...note,
          replies: [
            ...(note?.replies || []),
            {
              body,
              user,
            } as NoteEntity,
          ],
        } as NoteEntity)
    );
  };

  const handleSaveTitle = async () => {
    if (!form.isDirty()) return;
    if (!note?.id) return;
    await form.sendForm((values) => patchNote(note.id, values), {
      resetInitial: true,
    });
  };

  const handleDelete = async () => {
    if (!deleteId) return;
    setDeleteId(null);
    await deleteNote(deleteId);
    if (deleteId === note?.id) {
      navigate(`/p/${project?.permalink}/threads`);
      await mutateAllNotes();
    } else await mutate();
  };

  return (
    <div className="flex items-start gap-6">
      <ActionIcon variant="subtle" color="purple" onClick={() => navigate(-1)}>
        <ChevronLeft />
      </ActionIcon>

      <div className="flex-1 -mt-4">
        <Confirm
          title={`Delete ${note?.id === deleteId ? "thread" : "comment"}?`}
          icon={<TrashIcon className="w-8 h-8" />}
          color="red"
          content={`Are you sure you would like to delete this ${
            note?.id === deleteId ? "thread" : "comment"
          }?`}
          opened={!!deleteId}
          onClose={() => setDeleteId(null)}
          onConfirm={handleDelete}
        />

        {note ? (
          <div className="flex items-center gap-6">
            <Textarea
              data-testid="thread-title-textarea"
              {...form.getInputProps("title")}
              classNames={{
                root: "flex-1",
                input:
                  "w-full font-bold text-3xl pl-2 -mx-2 bg-dark-1000 bg-opacity-0 transition hover:bg-opacity-100",
              }}
              styles={{
                input: { border: "1px solid transparent" },
              }}
              autosize
              minRows={1}
              readOnly={note.userId !== user?.id}
            />

            <Transition transition="fade" mounted={form.isDirty()}>
              {(styles) => (
                <ActionIcon
                  data-testid="thread-title-save"
                  color="purple"
                  variant="light"
                  onClick={handleSaveTitle}
                  style={styles}
                >
                  <BsFloppy />
                </ActionIcon>
              )}
            </Transition>
            <span className="text-dark-400 font-normal text-xl">
              #{note.num}
            </span>
          </div>
        ) : (
          <Skeleton visible height={28} />
        )}

        <Divider />

        <Timeline lineWidth={2}>
          <Timeline.Item bulletSize={36} bullet={<Avatar user={note?.user} />}>
            {!note ? (
              <Skeleton visible height={128} />
            ) : (
              <Note main note={note} onDelete={setDeleteId} mutate={mutate} />
            )}
          </Timeline.Item>

          {note?.replies?.map((note, index) => (
            <Timeline.Item
              key={index}
              bulletSize={36}
              bullet={<Avatar user={note.user} />}
            >
              <Note note={note} onDelete={setDeleteId} mutate={mutate} />
            </Timeline.Item>
          ))}

          <Timeline.Item bulletSize={36} bullet={<Avatar user={user} />}>
            <Card variant="outlined" className="overflow-hidden">
              <RichText
                data-testid="thread-comment-input"
                users={project?.collaborators
                  .map((c) => c.user)
                  .filter((c) => c.id !== user?.id)}
                placeholder="Add a comment"
                value={body}
                onChange={(value) => setBody(value)}
              />

              <Button
                data-testid="thread-comment-button"
                className="mt-4 float-right"
                variant="light"
                size="sm"
                onClick={handlePost}
              >
                Comment
              </Button>
            </Card>
          </Timeline.Item>
        </Timeline>
      </div>
    </div>
  );
};

export { ProjectNote };
