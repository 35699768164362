import { HttpExceptionClient } from "@requests/helpers";

import { InternalError } from "./InternalError";
import { NotFound } from "./NotFound";
import { Unauthorized } from "./Unauthorized";

export const errorNavigator = (
  error: { response?: HttpExceptionClient | undefined } | undefined = undefined
) => {
  if (!error) return <InternalError />;
  if (error.response?.status === 404) return <NotFound />;
  if (error.response?.status === 401)
    return <Unauthorized error={error.response} />;
  return <InternalError />;
};
