import {
  ActionIcon,
  Input,
  Divider,
  Tooltip,
  DotsMenu,
} from "@common/components";
import { AvatarsGroup } from "@common/components/AvatarsGroup";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { Loader, Transition } from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";
import {
  Archive,
  Download,
  FirstPageRounded,
  PauseRounded,
  PlayArrowRounded,
} from "@mui/icons-material";
import moment from "moment";
import React, { memo, useState } from "react";
import { FaPenNib } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeft,
  History,
  Notes,
  ZoomIn,
  ZoomOut,
} from "tabler-icons-react";

import { InviteUsers } from "../../../projects/view/collaborators/InviteUsers";
import { useStemViewer } from "../../contexts/StemViewerContext";
import { CreateMarkup } from "../markup/CreateMarkup";
import { MarkupModal } from "../markup/MarkupModal";

import { NotesDrawer } from "./NotesDrawer";
import { ExportMixdown } from "./components/ExportMixdown";
import { ExportTracks } from "./components/ExportTracks";
import { ResetStemviewer } from "./components/ResetStemviewer";

const Panel = memo<{ stemLength: number }>(
  ({ stemLength }) => {
    const navigate = useNavigate();
    const { joined } = useStemViewer().collab(["joined"]);
    const { project, currTime, playing, scale, notesOpen } =
      useStemViewer().track([
        "scale",
        "project",
        "currTime",
        "playing",
        "notesOpen",
      ]);
    const { saving } = useStemViewer().markup(["saving"]);
    const { stems } = useStemViewer().stem(["stems"]);
    const { track, markup } = useStemViewer().controllers;
    const [mixdown, setMixdown] = useState(false);
    const [tracks, setTracks] = useState(false);
    const [createMarkup, setCreateMarkup] = useState(false);
    const [inviteUser, setInviteUser] = useState(false);
    const [resetStemviewer, setResetStemviewer] = useState(false);

    useHotkeys([
      ["mod+M", () => setCreateMarkup(true)],
      ["ctrl+M", () => setCreateMarkup(true)],
    ]);

    const numStemsLoaded = stems.filter((s) => !s.loading).length;
    const totalStems = stems.filter((s) => !s.error).length;

    return (
      <>
        <ExportMixdown opened={mixdown} onClose={() => setMixdown(false)} />
        <ExportTracks opened={tracks} onClose={() => setTracks(false)} />
        <ResetStemviewer
          opened={resetStemviewer}
          onClose={() => setResetStemviewer(false)}
          onMixdown={() => setMixdown(true)}
        />

        <MarkupModal onClose={() => markup.view(null)} />

        <CreateMarkup
          opened={createMarkup}
          onClose={() => setCreateMarkup(false)}
        />
        <InviteUsers opened={inviteUser} onClose={() => setInviteUser(false)} />

        <NotesDrawer opened={notesOpen} onClose={() => track.closeNotes()} />

        <div className="bg-dark-1000 flex items-center gap-2 justify-between p-5">
          <div className="flex items-center gap-2">
            <ActionIcon
              data-testid="sv-exit"
              variant="light"
              className="mr-4"
              onClick={() => project && navigate(`/p/${project.permalink}`)}
              size="sm"
            >
              <ChevronLeft />
            </ActionIcon>
            <h2 className="mb-1 mr-4 max-w-2xl truncate">
              {project?.name || "Untitled"}
            </h2>

            <ActionIcon
              data-testid="sv-stop"
              data-quick-assist-id="sv-stop"
              variant="light"
              size="lg"
              onClick={() => track.stop()}
            >
              <FirstPageRounded />
            </ActionIcon>

            <ActionIcon
              data-testid="sv-play-pause"
              data-quick-assist-id="sv-play-pause"
              variant={playing ? "subtle" : "light"}
              size="lg"
              onClick={() => track.pausePlay()}
            >
              {playing ? <PauseRounded /> : <PlayArrowRounded />}
            </ActionIcon>

            <Input
              size="sm"
              classNames={{ input: "w-32 text-lg text-center" }}
              value={moment("2015-01-01")
                .startOf("day")
                .seconds(currTime)
                .format("mm:ss")}
            />

            <Divider orientation="vertical" className="h-8 mt-2" />

            <Tooltip label="View threads">
              <ActionIcon
                data-testid="sv-threads"
                data-quick-assist-id="sv-threads"
                variant="light"
                size="lg"
                onClick={() => track.openNotes()}
              >
                <Notes className="w-5 h-5" />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Add markup">
              <ActionIcon
                data-testid="sv-create-markup"
                data-quick-assist-id="sv-create-markup"
                variant="light"
                size="lg"
                color="red"
                onClick={() => setCreateMarkup(true)}
              >
                <FaPenNib className="w-5 h-5" />
              </ActionIcon>
            </Tooltip>

            <Divider orientation="vertical" className="h-8 mt-2" />

            <Tooltip label="Zoom out">
              <ActionIcon
                data-testid="sv-zoom-out"
                data-quick-assist-id="sv-zoom-out"
                variant="light"
                size="lg"
                color="dark"
                onClick={() => track.scale(scale + 1)}
              >
                <ZoomOut className="w-5 h-5" />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Zoom in">
              <ActionIcon
                data-testid="sv-zoom-in"
                data-quick-assist-id="sv-zoom-in"
                variant="light"
                size="lg"
                color="dark"
                onClick={() => track.scale(scale - 1)}
              >
                <ZoomIn className="w-5 h-5" />
              </ActionIcon>
            </Tooltip>
          </div>

          <div className="flex items-center gap-2">
            <Transition
              transition="fade"
              mounted={saving || numStemsLoaded !== totalStems}
            >
              {(style) => (
                <div className="flex items-center gap-2 mr-2" style={style}>
                  <Loader size="xs" color="dark" />
                  <p className="text-xs text-gray-400 opacity-80">
                    {numStemsLoaded !== totalStems
                      ? `${numStemsLoaded}/${totalStems} tracks loaded`
                      : `Saving...`}
                  </p>
                </div>
              )}
            </Transition>

            <Tooltip label="Export Mixdown">
              <ActionIcon
                data-testid="sv-export-mixdown"
                data-quick-assist-id="sv-export-mixdown"
                variant="light"
                size="lg"
                onClick={() => setMixdown(true)}
                disabled={stemLength === 0}
              >
                <Download className="w-5 h-5" />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Export Tracks">
              <ActionIcon
                data-testid="sv-export"
                data-quick-assist-id="sv-export"
                variant="light"
                size="lg"
                onClick={() => setTracks(true)}
                disabled={stemLength === 0}
              >
                <Archive className="w-5 h-5" />
              </ActionIcon>
            </Tooltip>

            <AvatarsGroup bordered className="ml-4" users={joined} />

            <Tooltip label="Invite User">
              <ActionIcon
                data-quick-assist-id="sv-invite-user"
                variant="light"
                size="lg"
                radius={32}
                onClick={() => setInviteUser(true)}
                disabled={stemLength === 0}
              >
                <UserPlusIcon className="w-5 h-5" />
              </ActionIcon>
            </Tooltip>

            <DotsMenu vertical>
              <DotsMenu.Dropdown>
                <DotsMenu.Item
                  color="red"
                  icon={<History className="w-4 h-4" />}
                  onClick={() => setResetStemviewer(true)}
                >
                  Reset Stemviewer
                </DotsMenu.Item>
              </DotsMenu.Dropdown>
            </DotsMenu>
          </div>
        </div>
      </>
    );
  },
  (prev, next) => prev.stemLength === next.stemLength
);

export { Panel };
