import { Modal } from "@common/components";
import { Button } from "@common/components/Button";
import RadioGroup from "@common/components/RadioGroup";
import FakeProgress from "@common/utils/fake-progress";
import { ModalProps } from "@mantine/core";
import { Album, AudioFileRounded, Download } from "@mui/icons-material";
import React, { useState } from "react";
import { Fade } from "react-reveal";

import { useStemViewer } from "../../../contexts/StemViewerContext";

interface ExportMixdownProps extends ModalProps {}

const ExportMixdown: React.FC<ExportMixdownProps> = ({ ...props }) => {
  const { stems } = useStemViewer().stem(["stems"]);
  const { exports } = useStemViewer().controllers;
  const [exporting, setExporting] = useState(false);
  const [percent, setPercent] = useState<number | null>(null);
  const [fileType, setFileType] = useState("MP3");

  const options = [
    {
      icon: <AudioFileRounded className="mr-6 ml-2" />,
      label: "MP3",
      value: "MP3",
      description: "Smaller file size. Lower quality.",
    },
    {
      icon: <Album className="mr-6 ml-2" />,
      label: "WAV",
      value: "WAV",
      description: "Bigger file size. Higher quality.",
    },
  ];

  /**
   * Merges all tracks and download
   */
  const mergeDownload = async () => {
    if (exporting) return;
    setExporting(true);
    setPercent(0);

    if (fileType === "MP3") await exports.export({ stems: stems, type: "mp3" });
    else await exports.export({ stems: stems, type: "wav" });

    setPercent(100);
  };

  const handleClose = () => {
    if (percent && percent >= 100) setExporting(false);
    props.onClose();
  };

  return (
    <Modal
      title={
        <div className="flex items-center mb-4">
          <Download className="mr-2 w-5 h-5" />
          <h3>Export Mixdown</h3>
        </div>
      }
      {...props}
      onClose={handleClose}
    >
      <Fade when={!exporting} duration={300} collapse>
        <RadioGroup
          options={options}
          value={fileType}
          onChange={setFileType}
          renderOption={(option, active) => (
            <div
              className={`radio-group-option ${active ? "active" : ""}`}
              data-testid={`export-option-${option.value}`}
            >
              {option.icon}

              <div>
                <h4>{option.label}</h4>
                <p className="text-sm">{option.description}</p>
              </div>
            </div>
          )}
        />
      </Fade>

      <Fade when={exporting} delay={300} collapse>
        <FakeProgress
          loading={exporting}
          percent={percent || 0}
          setPercent={setPercent}
          predictedTime={
            stems.length > 0
              ? 0.05 *
                stems.map((stem) => stem.duration).reduce((s, i) => s + i)
              : 0
          }
        />
      </Fade>

      <Button
        data-testid="export-button"
        variant="light"
        className="mt-8 float-right"
        disabled={stems.length === 0 || exporting}
        onClick={mergeDownload}
      >
        Export
      </Button>
    </Modal>
  );
};

export { ExportMixdown };
