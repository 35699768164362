import React, { useEffect, useState } from "react";
import { Swiper as _Swiper } from "swiper";

import { getNonNullKeys } from "../utils/remove-nulls";
import { UseAsyncFormReturnType } from "../utils/use-async-form";

import { Swiper, SwiperProps } from "./Swiper";

interface SwiperFormProps<T extends object> extends SwiperProps {
  form: UseAsyncFormReturnType<T>;
  validateFields: Array<Array<keyof T | string>>;
}

function SwiperForm<T extends object>(props: SwiperFormProps<T>) {
  const [swiper, setSwiper] = useState<_Swiper | null>(null);
  const { form, validateFields, ..._props } = props;

  // Slide to relevant slide when error returned
  useEffect(() => {
    const errorFields = Object.keys(getNonNullKeys(form.errors));

    // Iterate through fields and find the first slide with an error
    validateFields.forEach((fields, slide) => {
      fields.forEach((field) => {
        if (errorFields.some((error) => error === field)) {
          return swiper?.slideTo(slide);
        }
      });
    });
  }, [form.errors]);

  // Resize slide when required
  useEffect(() => {
    swiper?.update();
  }, [form.values, form.errors]);

  // Validate fields on the current slide
  const onSlideNext = async (slide: number) => {
    let error = false;

    if (slide < validateFields.length) {
      for (const field of validateFields[slide]) {
        try {
          // @ts-ignore
          if (await form.validateFieldAsync(field)) {
            error = true;
          }
        } catch (e) {}
      }
    }

    if (error) return true;
    if (_props.onSlideNext) return _props.onSlideNext(slide);
    return false;
  };

  return (
    <Swiper
      className="swiper-form"
      onSwiper={(swiper) => setSwiper(swiper)}
      {..._props}
      onSlideNext={onSlideNext}
    >
      {_props.children}
    </Swiper>
  );
}

export { SwiperForm };
