import { axiomLogger } from "@requests/helpers/axiom";
import { User } from "@server/entities";
import React, { useEffect } from "react";
import { useLocation } from "react-router";

interface AxiomRouteLoggerProps {
  user?: User;
}

export const AxiomRouteLogger: React.FC<AxiomRouteLoggerProps> = ({ user }) => {
  const location = useLocation();

  useEffect(() => {
    axiomLogger.log({
      type: "route",
      route: location.pathname,
      search: location.search,
      userId: user?.id,
      device: navigator.userAgent,
    });
  }, [location]);

  return <></>;
};
