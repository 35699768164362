import { RichText } from "@common/components";
import { isEmpty } from "@common/utils/editor";
import { useClickOutside } from "@mantine/hooks";
import React, { useState } from "react";

import { useUser } from "../../../../../contexts/UserContext";
import { useProject } from "../../../../projects/view/ProjectContext";

interface MarkupDescriptionProps {
  value: string;
  onChange: (value: string) => void;
}

const MarkupDescription: React.FC<MarkupDescriptionProps> = ({
  value,
  onChange,
}) => {
  const [editingDesc, setEditingDesc] = useState(false);
  const { user } = useUser();
  const { project } = useProject();
  const ref = useClickOutside(() => setEditingDesc(false));

  return (
    <div>
      <div className="mb-2">
        <b>Description</b>
      </div>

      {editingDesc ? (
        <div ref={ref}>
          <RichText
            data-testid="sv-markup-description-rte"
            users={project?.collaborators
              .map((c) => c.user)
              .filter((c) => c.id !== user?.id)}
            value={value}
            onChange={(value) => onChange(value)}
          />
        </div>
      ) : (
        <div
          data-testid="sv-markup-description"
          className="ql-editor transition opacity-80 rounded p-2 -m-2 children:text-[1rem] hover:bg-dark-900"
          onClick={() => setEditingDesc(true)}
          dangerouslySetInnerHTML={{
            __html: !isEmpty(value) ? `${value}` : "Add a description",
          }}
        />
      )}
    </div>
  );
};

export { MarkupDescription };
