import { Button } from "@common/components";
import React from "react";
import { Link } from "react-router-dom";

import { useUser } from "../../../contexts/UserContext";

export const ProfileSettings: React.FC = () => {
  const { user } = useUser();

  return (
    <div className="p-10 container">
      <h2 className="mb-4">Profile Settings</h2>
      <p className="text-gray-400">
        To edit your profile details, please click on the link that will take
        you to your profile.
      </p>

      <Link to={`/u/${user?.username}/edit`}>
        <Button color="dark">Go to Profile</Button>
      </Link>
    </div>
  );
};
