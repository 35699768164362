import {
  Input,
  InputWrapperBaseProps,
  SegmentedControl as MantineSegmentedControl,
  SegmentedControlProps as MantineSegmentedControlProps,
} from "@mantine/core";
import React, { forwardRef } from "react";

import { getInputDefaults } from "./Input";

export type SegmentedControlProps = MantineSegmentedControlProps &
  InputWrapperBaseProps;

const SegmentedControl = forwardRef<HTMLDivElement, SegmentedControlProps>(
  (props, ref) => {
    const { ..._props } = props;

    const defaults = getInputDefaults(props);

    return (
      <Input.Wrapper {...defaults}>
        <MantineSegmentedControl
          ref={ref}
          color="synqup"
          {...defaults}
          {..._props}
        />
      </Input.Wrapper>
    );
  }
);

export { SegmentedControl };
