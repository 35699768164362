import React from "react";
import { Route, Routes } from "react-router-dom";

import { FilePage } from "../files/pages/FilePage";
import { Files } from "../files/pages/Files";
import { Home } from "../home/pages/Home";
import { Messages } from "../messages/pages/Messages";
import { Profile } from "../profile/pages/Profile";
import { Projects } from "../projects/Projects";
import { ProjectCreate } from "../projects/create/pages/ProjectCreate";
import { ProjectContext } from "../projects/view/ProjectContext";
import { NotFound } from "../redirects/NotFound";
import { Register } from "../register/pages/Register";
import { VerifyEmail } from "../register/pages/VerifyEmail";
import { SettingsAndPrivacy } from "../settings/pages/SettingsAndPrivacy";
import { Tasks } from "../tasks/pages/Tasks";

export function AppRoutes() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />

      <Route path="/messages/:channelId?" element={<Messages />} />

      {/*<Route path="/find-collaborators" element={<FindCollaborators />} />*/}
      <Route path="/u/:username/:tab?" element={<Profile />} />
      <Route path="/settings/:tab?" element={<SettingsAndPrivacy />} />

      <Route path="/p/:permalink/*" element={<ProjectContext />} />
      <Route path="/projects/create" element={<ProjectCreate />} />
      <Route path="/projects/*" element={<Projects />} />

      <Route path="/tasks/*" element={<Tasks />} />

      <Route path="/files/:fileId" element={<FilePage />} />
      <Route path="/files/*" element={<Files />} />

      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/register" element={<Register />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
}
