import {
  genericSelector,
  RecoilStates,
  useSelector,
} from "../helpers/selectors";

import {
  bpmState,
  currTimeState,
  durationState,
  projectState,
  playingState,
  scaleState,
  TrackState,
  regionState,
  scrollState,
  notesOpenState,
} from "./track.atom";

export const states: RecoilStates<TrackState> = {
  project: projectState,
  currTime: currTimeState,
  playing: playingState,
  scale: scaleState,
  bpm: bpmState,
  duration: durationState,
  region: regionState,
  scroll: scrollState,
  notesOpen: notesOpenState,
};

export const trackState = genericSelector("trackState", states);

export function useTrackSelector<K extends keyof TrackState>(
  keys: K[] = []
): Pick<TrackState, K> {
  return useSelector(keys, states);
}
