import { Input } from "@common/components";
import { UseAsyncFormReturnType } from "@common/utils/use-async-form";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import React from "react";

interface EditProfileUserDetailsProps {
  form: UseAsyncFormReturnType<UpdateUserDto>;
}

export const EditProfileUserDetails: React.FC<EditProfileUserDetailsProps> = ({
  form,
}) => {
  return (
    <div>
      <div className="flex flex-col gap-2">
        <Input
          {...form.getInputProps("email")}
          description="Email address"
          placeholder="Email address"
          size="sm"
        />

        <div className="flex gap-4">
          <Input
            {...form.getInputProps("firstName")}
            className="flex-1"
            description="First name"
            placeholder="First name"
            size="sm"
          />
          <Input
            {...form.getInputProps("lastName")}
            className="flex-1"
            description="Last name"
            placeholder="Last name"
            size="sm"
          />
        </div>

        <Input
          {...form.getInputProps("pseudonym")}
          description="Pseudonym"
          placeholder="Pseudonym"
          size="sm"
        />

        <Input
          {...form.getInputProps("phoneNumber")}
          description="Phone number"
          placeholder="Phone number"
          size="sm"
        />

        <Input
          {...form.getInputProps("address")}
          description="Address"
          placeholder="Address"
          size="sm"
        />
      </div>
    </div>
  );
};
