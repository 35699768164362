import { Image } from "@mantine/core";
import imageSrc from "@res/images/redirects/401.svg";
import React from "react";

export function InternalError() {
  return (
    <div className="container max-w-2xl p-10 md:p-20 lg:p-32 text-center">
      <Image width={300} className="inline-block mx-auto" src={imageSrc} />
      <h2 className="text-4xl my-4">Oops! Something went wrong!</h2>
      <p className="text-xl">We apologise for the inconvenience!</p>
    </div>
  );
}
