import { AvatarsGroup, Tooltip } from "@common/components";
import { notification } from "@common/utils/notification";
import { Avatar, Badge } from "@mantine/core";
import { starProject } from "@requests/project/project";
import { Project } from "@server/entities/project";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Star } from "tabler-icons-react";

import { useUser } from "../../../contexts/UserContext";

import { ProjectStatusBadge } from "./ProjectStatusBadge";

const ProjectRow = ({
  project,
  className = "",
  ...props
}: {
  project: Project;
  className?: string;
}) => {
  const navigate = useNavigate();
  const { user, starred, mutateStarred } = useUser();
  const [hovered, setHovered] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/p/${project.permalink}`);
  };

  const handleMiddleClick = (e: React.MouseEvent) => {
    if (e.button === 1 || e.metaKey || e.ctrlKey) {
      open(`/p/${project.permalink}`, "_blank");
    }
  };

  const handleStar = async (e) => {
    e.stopPropagation();
    const { error } = await starProject(project.id);
    if (error) return notification.error(error.message);
    await mutateStarred();
  };

  const isStarred = (project: Project) =>
    starred?.some((p) => p.id === project.id);

  return (
    <tr
      onClick={handleClick}
      onMouseDown={handleMiddleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="align-middle cursor-pointer hover:bg-dark-900 hover:shadow-md"
      data-testid={`project-row-${project.id}`}
      {...props}
    >
      <td className="w-full">
        <div className="flex items-center gap-4 py-1">
          <Avatar size="md" radius="sm" src={project.albumArt} />

          <h3
            data-testid="project-card-title"
            className="text-md font-medium my-0 py-0 whitespace-pre-wrap line-clamp-1"
          >
            {project.name}
          </h3>
        </div>
      </td>

      <td align="center">
        <ProjectStatusBadge
          className="mt-2"
          icon
          size="xs"
          status={project.status}
        />
      </td>

      <td>
        <AvatarsGroup
          noAnimation
          size="sm"
          className="ml-auto"
          users={project.collaborators.map((collab) => collab?.user)}
        />
      </td>

      <td className="hidden md:table-cell">
        <div className="flex items-center gap-2">
          <Tooltip label="My markups">
            <Badge className="tracking-normal" color="red" size="xs">
              {project.markups.filter(
                (markup) =>
                  markup.assigneeId === user?.id && markup.status !== "approved"
              ).length || 0}
            </Badge>
          </Tooltip>

          <Tooltip label="All markups">
            <Badge className="tracking-normal" size="xs">
              {
                project.markups.filter((markup) => markup.status !== "approved")
                  .length
              }
            </Badge>
          </Tooltip>
        </div>
      </td>

      <td
        style={{
          opacity: hovered || isStarred(project) ? 1 : 0,
          paddingTop: 12,
        }}
      >
        {!isStarred(project) ? (
          <Star
            onClick={handleStar}
            data-testid="star-project"
            data-quick-assist-id="star-project"
            className="w-4 h-4 text-dark-300 hover:text-dark-400 z-10"
          />
        ) : (
          <Star
            onClick={handleStar}
            className="w-4 h-4 stroke-0 fill-amber-400 hover:fill-amber-300 z-10"
          />
        )}
      </td>
    </tr>
  );
};

export { ProjectRow };
