import {
  ActionIcon,
  Avatar,
  Button,
  Card,
  EmojiSelect,
  RichText,
} from "@common/components";
import { RichTextReadOnly } from "@common/components/RichTextReadOnly";
import { CONTROLS, isEmpty } from "@common/utils/editor";
import { notification } from "@common/utils/notification";
import { TrashIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";
import { patchNote } from "@requests/project/note";
import { postReaction } from "@requests/project/reaction";
import { Note as NoteEntity } from "@server/entities/project";
import { EmojiType } from "@server/entities/project/reaction.entity";
import moment from "moment";
import React, { useState } from "react";
import { KeyedMutator } from "swr";

import { useUser } from "../../../../contexts/UserContext";
import { useProject } from "../ProjectContext";

interface NoteProps {
  note: NoteEntity;
  mutate: KeyedMutator<NoteEntity>;
  onDelete: (id: string) => void;
  main?: boolean;
}

const Note: React.FC<NoteProps> = ({ note, main, onDelete, mutate }) => {
  const { user } = useUser();
  const { project } = useProject();
  const [editing, setEditing] = useState(false);
  const [body, setBody] = useState(note.body);

  const handleUpdate = async () => {
    if (isEmpty(body)) return;

    setEditing(false);
    setBody(body);

    await patchNote(note.id, { body });
    await mutate();
  };

  const handleReact = async ({ type }: { type: EmojiType }) => {
    const { error } = await postReaction(note.id, {
      type,
      reactionFor: "note",
    });
    if (error) return notification.error(error.message);
    mutate && (await mutate());
  };

  if (!note.user) return <></>;

  if (note.action)
    return (
      <div className="flex items-center h-[36px]">
        <Avatar user={note.user} className="mr-2" />
        {note.user?.firstName} {note.user?.lastName}
        <span className="ml-1 opacity-70">
          {body} {moment(note.createdAt).fromNow()}
        </span>
      </div>
    );

  if (editing)
    return (
      <Card variant="outlined" className="overflow-visible">
        <RichText
          data-testid="thread-body-input"
          users={project?.collaborators
            .map((c) => c.user)
            .filter((c) => c.id !== user?.id)}
          value={body}
          onChange={(value) => setBody(value)}
          controls={CONTROLS}
        />

        <div className="flex items-center gap-2 justify-end mt-4">
          <Button variant="subtle" size="sm" onClick={() => setEditing(false)}>
            Cancel
          </Button>

          <Button
            data-testid="thread-update-button"
            variant="light"
            size="sm"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
      </Card>
    );

  return (
    <Card
      data-testid="thread-card"
      variant="outlined"
      className="overflow-hidden"
    >
      <div
        className={`flex items-center absolute z-10 w-[calc(100%+1px)] h-11 -m-6 p-3 border-b border-0 border-solid border-dark-700`}
      >
        {note.user?.firstName} {note.user?.lastName}
        <span className="ml-1 opacity-70">
          posted {moment(note.createdAt).fromNow()}
        </span>
      </div>

      <div
        className={`absolute z-0 w-[110%] h-11 -m-6 p-3
            ${main ? "bg-indigo-700" : "bg-dark-1000"}
            opacity-10`}
      ></div>

      <RichTextReadOnly
        data-testid="thread-body"
        className="pt-6 -m-2 border-none bg-transparent"
        value={note.body}
      />

      <div className="flex justify-between mt-4">
        <EmojiSelect
          value={note.reactions?.find(
            (reaction) => reaction.userId === user?.id
          )}
          reactions={note.reactions}
          onEmojiClick={handleReact}
        />

        {user?.id === note.user?.id ? (
          <div className="flex gap-2">
            <ActionIcon
              data-testid="thread-delete"
              variant="light"
              color="red"
              size="sm"
              radius="sm"
              onClick={() => onDelete(note.id)}
            >
              <TrashIcon className="w-4 h-4" />
            </ActionIcon>

            <ActionIcon
              data-testid="thread-edit"
              variant="light"
              size="sm"
              radius="sm"
              onClick={() => setEditing(true)}
            >
              <PencilIcon className="w-4 h-4" />
            </ActionIcon>
          </div>
        ) : null}
      </div>
    </Card>
  );
};

export { Note };
