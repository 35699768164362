import { showNotification } from "@mantine/notifications";
import { PriorityHigh } from "@mui/icons-material";
import React from "react";

export const axiosErrHandler = (error: any) => {
  const errBody = {
    icon: <PriorityHigh className="w-5 h-5" />,
    color: "red",
    title: "Error",
  };

  console.error(error);

  if (!error)
    return showNotification({
      ...errBody,
      message: "Something went wrong...",
    });

  if (!error.message) {
    return showNotification({
      ...errBody,
      message: error.toString(),
    });
  }

  return showNotification({
    ...errBody,
    message: error.message,
  });
};
