import { ActionIcon } from "@common/components";
import { UploadFilesModal } from "@common/components/UploadFilesModal";
import { Textarea } from "@mantine/core";
import { PaperPlaneIcon } from "@modulz/radix-icons";
import { createMessage } from "@requests/social/message";
import React, { useState } from "react";
import { Paperclip } from "tabler-icons-react";

interface ChatInputProps {
  channelId?: string;
}

const ChatInput: React.FC<ChatInputProps> = ({ channelId }) => {
  const [body, setBody] = useState("");
  const [sending, setSending] = useState(false);
  const [upload, setUpload] = useState(false);

  const handleSendMessage = async () => {
    if (body.trim() === "" || !channelId) return;
    if (sending) return;
    setSending(true);

    await createMessage({
      channelId,
      type: "text",
      text: body,
    });

    setBody("");
    setSending(false);
  };

  const handleUpload = async (files: File[]) => {
    if (files.length === 0 || !channelId) return;
    setSending(true);

    await createMessage({
      channelId,
      type: "file",
      file: files[0],
    });

    setSending(false);
  };

  return (
    <div className="flex items-center relative">
      <UploadFilesModal
        allowMultiple={false}
        opened={upload}
        onClose={() => setUpload(false)}
        onUpload={handleUpload}
      />

      <ActionIcon
        data-testid="upload-file-button"
        variant="transparent"
        onClick={() => setUpload(true)}
        loading={sending}
        disabled={!channelId}
      >
        <Paperclip />
      </ActionIcon>

      <Textarea
        data-testid="chat-input"
        autosize
        placeholder="Type your message..."
        className="flex-1 w-full pl-3"
        classNames={{
          input: "pr-12",
        }}
        value={body}
        onChange={({ target }) => setBody(target.value.replace("\n", ""))}
        onKeyDown={({ key }) => (key === "Enter" ? handleSendMessage() : {})}
      />

      <ActionIcon
        data-testid="send-message-button"
        className="absolute right-2 top-1/2 -translate-y-1/2"
        variant="transparent"
        onClick={handleSendMessage}
        loading={sending}
        disabled={!channelId}
      >
        <PaperPlaneIcon />
      </ActionIcon>
    </div>
  );
};

export { ChatInput };
