import { useHotkeys } from "@mantine/hooks";
import React from "react";

import { useStemViewer } from "../../contexts/StemViewerContext";

const KeyboardShortcuts: React.FC = () => {
  const { track } = useStemViewer().controllers;

  useHotkeys([["space", () => track.pausePlay()]]);

  return <></>;
};

export { KeyboardShortcuts };
