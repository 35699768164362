import { Message } from "@server/entities/social/message.entity";
import { CreateMessageDto } from "@server/modules/social/message/dto/create-message.dto";

import { useMultipartForm } from "../helpers";

export const createMessage = (data: CreateMessageDto) => {
  return useMultipartForm<Message>("/social/message", {
    ...data,
    filetype: "file",
  });
};
