import { Select, SelectProps } from "@common/components";
import { Badge } from "@mantine/core";
import { Project } from "@server/entities/project";
import React from "react";

import { ProjectStatusBadge, projectStatusData } from "./ProjectStatusBadge";

interface SelectProjectStatusProps extends Omit<SelectProps, "data"> {
  value?: Project["status"];
  onChange: (role: Project["status"]) => void;
}

const SelectProjectStatus: React.FC<SelectProjectStatusProps> = ({
  ...props
}) => {
  return (
    <div className="relative">
      {props.value && (
        <ProjectStatusBadge
          status={props.value}
          className="absolute z-10 top-1/2 -translate-y-1/2 left-3"
        />
      )}

      <Select
        {...props}
        data={projectStatusData}
        styles={{
          input: {
            color: "transparent !important",
          },
        }}
        renderOption={(option) => (
          <div className="flex items-center gap-2 m-1">
            <Badge
              radius="sm"
              color={
                projectStatusData.find((d) => d.value === option.value)?.color
              }
            >
              {option.label}
            </Badge>
          </div>
        )}
      />
    </div>
  );
};

export { SelectProjectStatus };
