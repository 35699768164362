import { Dispatch, SetStateAction, useRef, useState } from "react";

import { Pool, PooledWorker, QueuedWorker } from "./pool";
import { useDeepEffect } from "./use-deep-effect";

/**
 * Expose worker
 *
 * You can expose any function that returns:
 * - A value
 * - A promise
 * - An iterable
 * - An async iterable
 */
export function exposeWorker(
  func: (data: any) => any,
  getOptions?: () => WindowPostMessageOptions
) {
  self.onmessage = async (e: MessageEvent) => {
    try {
      const r = func(e.data);
      if (r && r[Symbol.asyncIterator]) {
        for await (const i of r) self.postMessage(i, getOptions?.());
      } else if (r && r[Symbol.iterator]) {
        for (const i of r) self.postMessage(i, getOptions?.());
      } else {
        self.postMessage(await r, getOptions?.());
      }
    } catch (e) {
      self.postMessage({ error: e.toString() }, getOptions?.());
    }
  };
}

type State<Result> = {
  result?: Result;
  loading: boolean;
  error?: "error" | "messageerror";
  workerId?: string;
};

const initialState = {
  loading: true,
};

declare global {
  interface Window {
    Pool: Pool;
  }
}

/**
 * use worker
 *
 * The createWorker function should be stable to keep the worker running.
 * If it's referentially changed, it will create a new worker and terminate the old one
 */
export function useWorker<Result, Input = any>(
  createWorker: () => Worker,
  input: Input,
  deps: Array<any> = [input]
): [State<Result>, Dispatch<SetStateAction<State<Result>>>] {
  const [state, setState] = useState<State<Result>>(initialState);

  const pooledWorker = useRef<PooledWorker | null>(null);

  useDeepEffect(() => {
    window.Pool = window.Pool || new Pool(8);

    if (!pooledWorker.current) {
      window.Pool.addWorker({
        createWorker,
        onWorkerInitialized: (_pooledWorker) => {
          if (!pooledWorker) return;
          setState(initialState);
          pooledWorker.current = _pooledWorker;
          pooledWorker.current.worker.postMessage(input);
        },
        onMessage: (e) => setState({ result: e.data, loading: false }),
        onError: (e) => setState({ error: "error", loading: false }),
        onMessageError: (e) =>
          setState({ error: "messageerror", loading: false }),
        onFinish: () => {
          pooledWorker.current = null;
        },
      });
    }
  }, [...deps]);

  return [state, setState];
}

/**
 * use worker
 *
 * The createWorker function should be stable to keep the worker running.
 * If it's referentially changed, it will create a new worker and terminate the old one
 */
export function useAsyncWorker<WorkerArgs, WorkerResponse>(
  createWorker: () => Worker
): (args: WorkerArgs) => Promise<WorkerResponse> {
  const queuedWorker = useRef<QueuedWorker | null>(null);

  return async (args: WorkerArgs) => {
    window.Pool = window.Pool || new Pool(8);
    if (queuedWorker.current !== null) {
      window.Pool.removeFromQueue(queuedWorker.current.id);
      queuedWorker.current = null;
    }

    return new Promise<WorkerResponse>((resolve, reject) => {
      queuedWorker.current = window.Pool.addWorker({
        createWorker,
        onWorkerInitialized: (pooledWorker) => {
          if (!pooledWorker) return reject();
          pooledWorker.worker.postMessage(args);
        },
        onMessage: (e) => {
          if (e.data.error) reject(e.data.error);
          resolve(e.data);
        },
        onError: (e) => reject(e.error),
        onMessageError: (e) => reject(e.data),
        onFinish: () => {
          queuedWorker.current = null;
        },
      });
    });
  };
}
