import { ActionIcon, Button, Input, Tooltip } from "@common/components";
import { notification } from "@common/utils/notification";
import useAsyncForm from "@common/utils/use-async-form";
import { useClipboard } from "@mantine/hooks";
import { Link1Icon } from "@modulz/radix-icons";
import { createShareableCode } from "@requests/project/project";
import { CollaboratorRole } from "@server/entities/project";
import React, { useEffect } from "react";
import { Copy } from "tabler-icons-react";

import { useProject } from "../ProjectContext";

import { SelectRole } from "./SelectRole";

export const GetShareableLink: React.FC = () => {
  const { project, mutate } = useProject();
  const clipboard = useClipboard({ timeout: 500 });
  const form = useAsyncForm<{ role: CollaboratorRole }>({
    initialValues: {
      role: project?.shareableRole || "editor",
    },
  });

  useEffect(() => {
    if (!project?.shareableCode) return;
    (async () => {
      const { error } = await createShareableCode(project.id, form.values.role);
      if (error) return notification.error(error.message);
      await mutate();
    })();
  }, [form.values.role]);

  const generateLink = async () => {
    if (!project) return;
    const { error } = await createShareableCode(project.id, form.values.role);
    if (error) return notification.error(error.message);
    await mutate();
    notification.success("Shareable link generated!");
  };

  const handleCopy = () => {
    clipboard.copy(
      `https://www.synqup.com.au/p/${project?.permalink}?scode=${project?.shareableCode}`
    );
    notification.success("Link copied");
  };

  return (
    <div className="flex items-end gap-4">
      <div className="flex-1">
        {project?.shareableCode ? (
          <div className="flex items-end">
            <Tooltip label="Copy link">
              <ActionIcon
                color="green"
                variant="light"
                className="mb-4"
                onClick={handleCopy}
              >
                <Copy className="w-5 h-5" />
              </ActionIcon>
            </Tooltip>

            <Input
              className="flex-1 ml-4"
              value={`https://www.synqup.com.au/p/${project.permalink}?scode=${project.shareableCode}`}
            />
          </div>
        ) : (
          <Button
            variant="light"
            className="mb-3"
            leftIcon={<Link1Icon className="w-5 h-5" />}
            onClick={generateLink}
          >
            Get shareable link
          </Button>
        )}
      </div>

      <SelectRole
        description="Invitee role"
        className="w-32"
        {...form.getInputProps("role")}
      />
    </div>
  );
};
