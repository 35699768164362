import { UploadFileDto } from "@server/modules/file/dto/upload-file.dto";
import axios, { AxiosRequestConfig } from "axios";
import { serialize } from "object-to-formdata";

import { axiomLogger } from "./axiom";

export type ErrorDetails = {
  property: string;
  message: string;
};

export type HttpExceptionClient = {
  status: number;
  message: string;
  errors: ErrorDetails[];
};

export type FetchResponse<T> = {
  data?: T;
  error?: HttpExceptionClient;
};

async function fetchWithMethod<R, T = any>(
  url: string,
  config: AxiosRequestConfig
): Promise<FetchResponse<R>> {
  return new Promise(async (resolve) => {
    const response = {};

    const _url = ["/api", url];

    try {
      const res = await axios({
        ...config,
        url: _url.join(""),
      });

      response["data"] = res.data;
      resolve(response);
    } catch (err: any) {
      if (err.response?.status >= 500) {
        axiomLogger.log({
          type: "fetch",
          path: _url.join(""),
          method: config.method,
          status: err.response.status,
          payload: JSON.stringify(config.data),
        });
      }

      response["error"] = err.response?.data || { message: String(err) };
      resolve(response);
    }
  });
}

export async function useGet<R = any, T = any>(
  url: string,
  params?: T,
  config?: AxiosRequestConfig<T>
) {
  return fetchWithMethod<R, T>(url, { method: "GET", params, ...config });
}

export async function usePost<R = any, T = any>(
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>
) {
  return fetchWithMethod<R, T>(url, { method: "POST", data, ...config });
}

export async function usePatch<R = any, T = any>(
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>
) {
  return fetchWithMethod<R, T>(url, { method: "PATCH", data, ...config });
}

export async function usePut<R = any, T = any>(
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>
) {
  return fetchWithMethod<R, T>(url, { method: "PUT", data, ...config });
}

export async function useDelete<R = any>(
  url: string,
  config?: AxiosRequestConfig<any>
) {
  return fetchWithMethod<R>(url, { method: "DELETE", ...config });
}

export async function useMultipartForm<R = any, T extends UploadFileDto = any>(
  url: string,
  data: T,
  config?: AxiosRequestConfig<T>
) {
  const formData = serialize(data);

  const defaultConfig = {
    "Content-Type": "multipart/form-data",
    ...config,
  };

  return fetchWithMethod<R, T>(url, {
    method: "POST",
    data: formData,
    ...defaultConfig,
  });
}
