import { Modal as MantineModal, ModalProps } from "@mantine/core";
import React from "react";

const Modal: React.FC<ModalProps> = (props) => {
  const modalClassName = `modal ${props.size}`;

  const defaults: ModalProps = {
    ...props,
    shadow: "sm",
    radius: "md",
    withCloseButton: false,
    centered: true,
    classNames: {
      modal: modalClassName,
    },
  };

  return (
    <MantineModal {...defaults} {...props}>
      {props.children}
    </MantineModal>
  );
};

export { Modal };
