import React from "react";
import { Books } from "tabler-icons-react";

import { MediaLibrary } from "../../../contexts/MediaLibrary/MediaLibrary";
import { withAuth } from "../../routes/ProtectedRoute";

export const Files: React.FC = withAuth(() => {
  return (
    <div className="container">
      <h1 className="flex items-center gap-4 md:my-8">
        <Books className="text-primary-400 opacity-75 w-8 h-8" />
        Files
      </h1>

      <MediaLibrary queryable={true} />
    </div>
  );
});
