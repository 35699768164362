import { Badge, BadgeProps, MantineGradient } from "@mantine/core";
import { Classification } from "@server/entities/user/classification.entity";
import React from "react";

import { getRandomColor } from "../utils/get-random-color";

interface ClassificationBadgeProps extends BadgeProps {
  classification: Pick<Classification, "name" | "level">;
}

export const ClassificationBadge: React.FC<ClassificationBadgeProps> = ({
  classification,
  ...props
}) => {
  let gradient: MantineGradient = { from: "orange", to: "yellow" };

  // if (classification.level === "established")
  //   gradient = { from: "teal", to: "green" };
  //
  // if (classification.level === "well-known")
  //   gradient = { from: "cyan", to: "blue" };
  //
  // if (classification.level === "legendary")
  //   gradient = { from: "indigo", to: "grape" };

  return (
    <Badge
      classNames={{ root: "p-3 font-bold" }}
      variant="gradient"
      gradient={{
        from: getRandomColor(classification.name.slice(0, 1)),
        to: getRandomColor(classification.name.slice(1, 2)),
      }}
      radius="sm"
      {...props}
    >
      {classification.name}
    </Badge>
  );
};
