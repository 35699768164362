import { Droppable } from "@hello-pangea/dnd";
import { useMantineTheme } from "@mantine/core";
import { Markup } from "@server/entities";
import React from "react";

import { TaskCard } from "./TaskCard";

interface TaskColumnProps {
  id: string;
  markups: Markup[];
}

export const TaskColumn: React.FC<TaskColumnProps> = ({ markups, id }) => {
  const theme = useMantineTheme();

  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          data-testid={`task-column-${id}`}
          className="flex-1 rounded -mx-2 my-2 p-2 -pb-4"
          style={{
            background: snapshot.isDraggingOver
              ? theme.fn.rgba(theme.colors.dark[4], 0.2)
              : "transparent",
          }}
        >
          {markups.map((markup, index) => (
            <TaskCard key={markup.id} markup={markup} index={index} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
