import { Input } from "@common/components";
import { Checkbox, Divider } from "@mantine/core";
import React, { useState } from "react";

import { RegisterProps } from "../pages/Register";

const RegisterStage3: React.FC<RegisterProps> = ({ form }) => {
  const [pseudonym, setPseudonym] = useState(false);

  return (
    <div className="flex h-[calc(75vh)] items-center p-6">
      <div className="w-full">
        <h3 className="text-dark-400">
          STEP <span className="text-white">2 </span>
          OF <span className="text-white">2 </span>
        </h3>
        <h1>Tell us a little about yourself</h1>

        <Divider className="my-8" />

        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <Input
              {...form.getInputProps("firstName")}
              className="flex-1"
              size="xl"
              placeholder="First name"
              data-testid="first-name"
            />
            <Input
              {...form.getInputProps("lastName")}
              className="flex-1"
              size="xl"
              placeholder="Last name"
              data-testid="last-name"
            />
          </div>

          <Input
            {...form.getInputProps("phoneNumber")}
            size="xl"
            placeholder="Phone number"
          />

          <Checkbox
            checked={pseudonym}
            onChange={({ target }) => setPseudonym(target.checked)}
            label={`Do you have a pseudonym you would like to go by?`}
          />

          {pseudonym ? (
            <Input
              size="xl"
              placeholder="Pseudonym"
              {...form.getInputProps("pseudonym")}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { RegisterStage3 };
