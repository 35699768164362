import { ActionIcon, Avatar, Card, RichText } from "@common/components";
import { formatBytes } from "@common/utils/constants";
import { notification } from "@common/utils/notification";
import useAsyncForm from "@common/utils/use-async-form";
import { StopCircleIcon } from "@heroicons/react/24/solid";
import { Badge, Popover } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { Editor } from "@mantine/rte";
import { Cross1Icon } from "@modulz/radix-icons";
import { createMarkupComment } from "@requests/project/markup";
import { User } from "@server/entities";
import { CreateMarkupCommentDto } from "@server/modules/project/markup/dto/create-markup-comment.dto";
import moment from "moment/moment";
import React, { useRef, useState } from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import { VoiceMemo } from "src/common/components/VoiceMemo";
import { readAsDataUrl } from "src/modules/stemviewer/recoil/helpers/stem";
import { Microphone, Paperclip } from "tabler-icons-react";

import { useTaskState } from "../../../../../contexts/Tasks/task.selector";
import { useUser } from "../../../../../contexts/UserContext";
import { useProject } from "../../../../projects/view/ProjectContext";
import { MarkupState } from "../../../recoil/markup/markup.atom";

import { MarkupComment } from "./MarkupComment";

interface MarkupCommentsProps {
  markup?: MarkupState["view"];
  mutate?: () => Promise<void>;
}

const MarkupComments: React.FC<MarkupCommentsProps> = ({ markup, mutate }) => {
  const { user } = useUser();

  const { project: taskProject } = useTaskState(["project"]);
  const { project: viewProject } = useProject();
  let project = taskProject || viewProject;

  const ref = useRef<Editor | null>(null);
  const recorder = useRef<MediaRecorder | null>();
  const [recording, setRecording] = useState(false);
  const [duration, setDuration] = useState(0);
  const [voiceMemo, setVoiceMemo] = useState<Blob | null>(null);

  const { start, stop } = useInterval(() => {
    setDuration((duration) => {
      if (duration >= 60000) {
        recorder.current?.stop();
        return duration;
      }
      return duration + 1000;
    });
  }, 1000);

  const form = useAsyncForm<CreateMarkupCommentDto>({
    initialValues: {
      body: "",
      markupId: markup?.id,
    },
  });

  // const handleKeyDown = useCallback(
  //   (event: KeyboardEvent) => {
  //     if (event.key === "Enter") handleSend().catch();
  //   },
  //   [form.values]
  // );

  const handleReply = async (user: User) => {
    ref.current?.getEditor().getModule("mention").insertItem(
      {
        id: user.id,
        value: user.displayName,
        denotationChar: "@",
      },
      true
    );
  };

  const handleUpload = () => {
    const input = document.getElementById(
      "markup-comment-file-input"
    ) as HTMLInputElement;
    input.click();
  };

  const handleSend = async () => {
    if (voiceMemo) {
      form.values.audio = await readAsDataUrl(voiceMemo);
      if (!form.values.audio)
        return notification.error("Oops! Something went wrong");
      form.values.body = `${user?.displayName} sent a voice memo`;
    }

    if (form.values.file) {
      form.values.body = `${user?.displayName} sent a file`;
    }

    const { error } = await form.sendForm(createMarkupComment);
    if (error) return notification.error(error.message);
    mutate && (await mutate());
    form.setFieldValue("body", "");
    setVoiceMemo(null);
  };

  const handleRecord = async () => {
    const recorder = await getRecorder();
    if (!recording) recorder?.start();
    else recorder?.stop();
  };

  const getRecorder = async () => {
    if (recorder.current) return recorder.current;
    return navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        recorder.current = new MediaRecorder(stream, {
          mimeType: "audio/webm",
        });

        recorder.current.ondataavailable = (e) => {
          const blob = new Blob([e.data], { type: "audio/webm" });
          setVoiceMemo(blob);
        };

        recorder.current.onstart = () => {
          setRecording(true);
          setDuration(0);
          start();
        };

        recorder.current.onstop = () => {
          setRecording(false);
          stop();
        };

        return recorder.current;
      })
      .catch((e) => {
        console.error(e);
        return null;
      });
  };

  if (!markup?.comments) return <></>;

  return (
    <Card className="bg-dark-900">
      {markup?.comments.length === 0 ? (
        <p className="text-gray-500">No comments yet...</p>
      ) : (
        markup?.comments.map((comment) => (
          <MarkupComment
            key={comment.id}
            comment={comment}
            onReply={handleReply}
            mutate={mutate}
          />
        ))
      )}

      <div className="relative flex items-start gap-4 mt-8">
        <Avatar user={user} className="mt-3" size="sm" />

        {voiceMemo ? (
          <div className="flex items-center gap-2 w-fit">
            <VoiceMemo className="w-64 mt-0.5" audio={voiceMemo} />
            <ActionIcon
              onClick={() => setVoiceMemo(null)}
              color="red"
              variant="light"
              size="xs"
            >
              <Cross1Icon />
            </ActionIcon>
          </div>
        ) : form.values.file ? (
          <div className="flex items-center gap-2 w-fit mt-3">
            <p className="m-0">{form.values.file.name}</p>
            <Badge>{formatBytes(form.values.file.size)}</Badge>
            <ActionIcon
              onClick={() => form.setFieldValue("file", null)}
              color="red"
              variant="light"
              size="xs"
            >
              <Cross1Icon />
            </ActionIcon>
          </div>
        ) : (
          <RichText
            ref={ref}
            data-testid="sv-markup-comment-input"
            users={project?.collaborators
              .map((c) => c.user)
              .filter((c) => c.id !== user?.id)}
            classNames={{ toolbar: "hidden", root: "pr-28" }}
            className="flex-1"
            placeholder="Add a comment"
            {...form.getInputProps("body")}
            // onKeyDown={handleKeyDown}
          />
        )}

        <div className="flex items-center gap-2 absolute right-4 mt-2.5">
          <Popover position="top" opened={recording}>
            <Popover.Target>
              <ActionIcon
                variant={recording ? "filled" : "light"}
                color="red"
                onClick={handleRecord}
              >
                {recording ? <StopCircleIcon /> : <Microphone />}
              </ActionIcon>
            </Popover.Target>

            <Popover.Dropdown>
              <p className="font-medium text-gray-400">
                {moment("2015-01-01")
                  .startOf("day")
                  .milliseconds(duration)
                  .format("mm:ss")}
              </p>
            </Popover.Dropdown>
          </Popover>

          <input
            id="markup-comment-file-input"
            data-testid="sv-markup-comment-file-input"
            type="file"
            onChange={(e) => form.setFieldValue("file", e.target.files?.[0])}
            className="hidden"
          />

          <ActionIcon
            data-testid="sv-markup-comment-file"
            variant="light"
            color="purple1"
            onClick={handleUpload}
          >
            <Paperclip />
          </ActionIcon>

          <ActionIcon
            data-testid="sv-markup-comment-send"
            variant="filled"
            onClick={handleSend}
            loading={form.loading}
          >
            <RiSendPlaneFill />
          </ActionIcon>
        </div>
      </div>
    </Card>
  );
};

export { MarkupComments };
