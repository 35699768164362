import { ActionIcon, Avatar, Card } from "@common/components";
import { Loader } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { Cross1Icon } from "@modulz/radix-icons";
import { getMe } from "@requests/auth/auth";
import { useChannel } from "@requests/social/channel";
import React from "react";
import { Link } from "react-router-dom";

import { useChat } from "../../../contexts/Chat/ChatContext";
import { getOtherUser } from "../components/MessagesMenu";

import { ChatBody } from "./ChatBody";
import { ChatInput } from "./ChatInput";

interface ChatPopupProps {
  id: string;
  index: number;
}

const ChatPopup: React.FC<ChatPopupProps> = ({ id, index }) => {
  const { close, setActive } = useChat();
  const { data: me } = getMe();
  const { data: channel, isValidating: loading } = useChannel(id);
  const ref = useClickOutside(() => setActive(null));

  const otherUser = getOtherUser(channel, me);

  return (
    <Card
      ref={ref}
      radius="lg"
      style={{ marginRight: `${index * 25}rem` }}
      className="w-96 fixed z-[199] right-[21rem] -bottom-4 flex flex-col overflow-hidden"
      withBorder
      onClick={() => setActive(id)}
    >
      <Card.Section>
        <div className="bg-dark-1000 flex items-center justify-between p-4">
          <Link to={`/u/${otherUser?.username}`}>
            <div className="flex items-center gap-4">
              <Avatar size="sm" user={otherUser} />
              <h3>{otherUser?.displayName}</h3>
            </div>
          </Link>

          <ActionIcon
            variant="transparent"
            onClick={(e) => {
              e.stopPropagation();
              close(id);
            }}
          >
            <Cross1Icon />
          </ActionIcon>
        </div>
      </Card.Section>

      <Card.Section className="flex-1 bg-dark-900">
        <div className="h-[22rem]">
          {loading ? (
            <div className="flex justify-center">
              <Loader size="sm" className="mx-auto my-4" />
            </div>
          ) : channel?.id && otherUser ? (
            <ChatBody channelId={channel?.id} user={otherUser} />
          ) : (
            <></>
          )}
        </div>
      </Card.Section>

      <Card.Section className="bg-dark-1000 flex items-center gap-4 px-3 pt-3 pb-8">
        <div className="w-full relative">
          <ChatInput channelId={channel?.id} />
        </div>
      </Card.Section>
    </Card>
  );
};

export { ChatPopup };
