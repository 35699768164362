import { ActionIcon } from "@common/components";
import { PlusIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

import { stemHeight, stemPanelWidth } from "../../../helpers/constants";

import { UploadStem } from "./UploadStem";

const TrackFooter: React.FC = () => {
  const [uploadStem, setUploadStem] = useState(false);

  return (
    <tfoot className="z-50 h-full">
      <UploadStem
        opened={uploadStem}
        onClose={() => {
          setUploadStem(false);
        }}
      />

      <tr>
        <td
          className="sticky z-50 left-0 shadow-xl"
          style={{ width: stemPanelWidth }}
        >
          <div
            className="flex justify-center items-center bg-dark-900"
            style={{ height: stemHeight }}
          >
            <ActionIcon
              data-testid="sv-upload"
              data-quick-assist-id="sv-upload"
              onClick={() => setUploadStem(true)}
              size="md"
            >
              <PlusIcon />
            </ActionIcon>
          </div>
        </td>
        <td />
      </tr>

      <tr className="h-full">
        <td
          className="sticky z-50 left-0 bg-dark-1000 shadow-xl"
          style={{ width: stemPanelWidth }}
        />
        <td className="bg-dark-900" />
      </tr>
    </tfoot>
  );
};

export { TrackFooter };
