import {
  Avatar as MantineAvatar,
  AvatarProps,
  Skeleton,
  useMantineTheme,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { User } from "@server/entities";
import React, { forwardRef } from "react";
import seedrandom from "seedrandom";

import { Tooltip } from "./Tooltip";

interface UserAvatarProps extends AvatarProps {
  user?: User;
  bordered?: boolean;
  placeholder?: boolean;
  tooltip?: boolean;
}

export function getUserColor(user: Pick<User, "id">) {
  const colors = [
    "blue",
    "cyan",
    "grape",
    "red",
    "pink",
    "violet",
    "green",
    "lime",
    "teal",
    "indigo",
    "yellow",
    "orange",
  ];

  const random = seedrandom(user.id);
  const diff = Math.floor(random() * colors.length);

  return colors[diff];
}

const Avatar = forwardRef<HTMLDivElement, UserAvatarProps>((props, ref) => {
  const theme = useMantineTheme();
  const { user, bordered, placeholder, tooltip, ..._props } = props;
  const { ref: elementRef, height } = useElementSize();

  if (!user && placeholder)
    return (
      <MantineAvatar
        radius="xl"
        {..._props}
        classNames={{ placeholder: "bg-black", ..._props.classNames }}
      />
    );

  if (!user)
    return (
      <Skeleton
        ref={ref}
        circle
        height={height}
        className={`flex-none -ml-1 ${_props.className}`}
      >
        <MantineAvatar ref={elementRef} {..._props} />
      </Skeleton>
    );

  const defaults: AvatarProps = {
    radius: "xl",
    src: user.avatar,
    color: getUserColor(user),
  };

  const component = (
    <MantineAvatar
      ref={ref}
      styles={{
        root: {
          border: bordered
            ? `1.5px solid ${theme.colors[getUserColor(user)][6]} !important`
            : "",
        },
      }}
      {...defaults}
      {..._props}
      className={`rounded-full ${_props.className}`}
    >
      {user.firstName && user.lastName
        ? user.firstName[0] + user.lastName[0]
        : null}
    </MantineAvatar>
  );

  if (tooltip)
    return (
      <Tooltip className="w-fit" position="top" label={user.displayName}>
        {component}
      </Tooltip>
    );
  return component;
});

export { Avatar };
