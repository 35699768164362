import { Avatar } from "@common/components";
import { Draggable } from "@hello-pangea/dnd";
import { useMantineTheme } from "@mantine/core";
import { Markup } from "@server/entities";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useTasks } from "../../../contexts/Tasks/TaskContext";

import { getTaskColor, TaskTypeBadge } from "./TaskTypeBadge";

interface TaskCardProps {
  markup: Markup;
  index: number;
}

export const TaskCard: React.FC<TaskCardProps> = ({ markup, index }) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { viewMarkup } = useTasks();

  const handleClick = () => {
    if (markup.stemId) {
      navigate(`/p/${markup.project?.permalink}/viewer/markup/${markup.id}`);
    } else {
      viewMarkup(markup.id);
    }
  };

  return (
    <Draggable draggableId={markup.id} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
        <div
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
          data-testid="task-draggable-card"
          className="ml-1 mb-2 px-4 py-3 rounded cursor-pointer"
          style={{
            boxShadow: `-0.25rem 0 0 0 ${
              theme.colors[getTaskColor(markup.type)][5]
            }`,
            background: snapshot.isDragging
              ? theme.colors.dark[5]
              : theme.colors.dark[6],
            ...draggableProps.style,
          }}
          onClick={handleClick}
        >
          <div className="flex items-center justify-between gap-1">
            <p className="m-0 select-none">{markup.title}</p>
            <Avatar size="sm" placeholder user={markup.assignee} />
          </div>

          <TaskTypeBadge className="mt-1" size="xs" value={markup.type} />
        </div>
      )}
    </Draggable>
  );
};
