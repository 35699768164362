import {
  ActionIcon as MantineActionIcon,
  ActionIconProps as MantineActionIconProps,
  MantineTheme,
  ThemeIcon,
} from "@mantine/core";
import React, { forwardRef } from "react";

import { rgba } from "./Button";
import { Tooltip } from "./Tooltip";

export type ActionIconProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  Omit<MantineActionIconProps, "gradient"> & {
    active?: boolean;
    gradient?: boolean;
    tooltip?: string;
  };

const getTheme = (theme: MantineTheme, props: MantineActionIconProps) => {
  if (props.variant === "transparent")
    return {
      "&:hover": {
        background: rgba(theme, 5, 0.2, props),
      },
    };

  if (props.variant === "filled")
    return {
      background: rgba(theme, 8, 0.9, props),
      border: `1px solid ${rgba(theme, 8, 0.9, props)}`,
      "&:hover": {
        background: rgba(theme, 8, 1, props),
      },
    };

  if (props.variant === "light")
    return {
      background: rgba(theme, 6, 0.4, props),
      "&:hover": {
        background: rgba(theme, 6, 0.6, props),
      },
    };

  return {};
};

const ActionIcon = forwardRef<HTMLButtonElement, ActionIconProps>(
  (props, ref) => {
    const { active, gradient, tooltip, ..._props } = props;
    const color = "dark";

    const className = "transition " + props.className;

    const defaults: MantineActionIconProps = {
      size: "md",
      variant: "filled",
      color,
      radius: "sm",
      p: 4,
      style: {},
      sx: (theme) =>
        getTheme(theme, {
          color,
          ...props,
          gradient: undefined,
        }),
    };

    if (gradient)
      return (
        <MantineActionIcon
          {...defaults}
          {..._props}
          ref={ref}
          className={className}
          p={0}
          variant="transparent"
        >
          <ThemeIcon
            style={{ backgroundOrigin: "border-box" }}
            size={props.size || defaults.size}
            radius={props.radius || defaults.radius}
            p={props.p || defaults.p}
            variant="gradient"
            gradient={{ from: "indigo", to: "pink", deg: 60 }}
          >
            {props.children}
          </ThemeIcon>
        </MantineActionIcon>
      );

    let component = (
      <MantineActionIcon
        {...defaults}
        {..._props}
        ref={ref}
        className={className}
        variant={active ? "filled" : props.variant || defaults.variant}
      />
    );

    if (tooltip) component = <Tooltip label={tooltip}>{component}</Tooltip>;

    return component;
  }
);

export { ActionIcon };
