import {
  Avatar,
  Button,
  Card,
  Divider,
  EmojiSelect,
  RichText,
} from "@common/components";
import Checkbox from "@common/components/Checkbox";
import Confirm from "@common/components/Confirm";
import Skeleton from "@common/components/Skeleton";
import { formatTime } from "@common/utils/format-time";
import useAsyncForm from "@common/utils/use-async-form";
import { Editor } from "@mantine/rte";
import { createMarkup, deleteMarkup } from "@requests/project/markup";
import { User } from "@server/entities";
import { CreateMarkupDto } from "@server/modules/project/markup/dto/create-markup.dto";
import React, { useEffect, useRef, useState } from "react";
import { Comment } from "src/common/components/Comment";
import { Trash } from "tabler-icons-react";

import { currTimeState, usePlayerState } from "../../../../contexts/Player";
import { getRecoil } from "../../../../contexts/RecoilNexus";
import { useUser } from "../../../../contexts/UserContext";
import { useProject } from "../ProjectContext";

export const ProjectComments: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const ref = useRef<Editor | null>(null);
  const { user } = useUser();
  const { project, currMixdownId, markups, mutateMarkups } = useProject();
  const { playing, currTime } = usePlayerState(["playing", "currTime"]);

  const comments = markups.filter((m) => m.type === "comment");

  const [delId, setDelId] = useState<string | null>(null);
  const [includeTime, setIncludeTime] = useState(false);

  const form = useAsyncForm<CreateMarkupDto>({
    initialValues: {
      title: "",
      projectId,
      type: "comment",
    },
  });

  useEffect(() => {
    if (playing) setIncludeTime(true);
  }, [playing]);

  const handleSubmit = async () => {
    const currTime = getRecoil(currTimeState);

    const { error } = await form.sendForm((values) =>
      createMarkup({
        ...values,
        mixdownId: currMixdownId || undefined,
        start: includeTime ? currTime : undefined,
      })
    );

    if (error) return;
    form.setFieldValue("title", "");
    await mutateMarkups();
  };

  const handleDelete = async () => {
    if (!delId) return;
    await deleteMarkup(delId);
    setDelId(null);
    await mutateMarkups();
  };

  const handleReply = async (user: User) => {
    ref.current?.getEditor().getModule("mention").insertItem(
      {
        id: user.id,
        value: user.displayName,
        denotationChar: "@",
      },
      true
    );
  };

  const handleEmojiClick = ({ emoji }) => {
    const currPosition = ref.current?.getEditor().getSelection()?.index;
    ref.current?.getEditor().insertText(currPosition || -1, emoji);
  };

  if (!project) return null;

  return (
    <div>
      {!comments ? (
        <Skeleton visible width="100%" height={100} />
      ) : (
        <Card className="bg-dark-800">
          <div>
            {comments.length === 0 ? (
              <p className="text-dark-300">No comments yet</p>
            ) : (
              <div className="flex flex-col gap-6">
                {comments.map((comment) => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    onDelete={(id) => setDelId(id)}
                    onReply={handleReply}
                    mutate={mutateMarkups}
                    taggableUsers={project.collaborators
                      .map((c) => c.user)
                      .filter((c) => c.id !== user?.id)}
                  />
                ))}
              </div>
            )}
          </div>

          {user && (
            <div>
              <Divider className="my-6" />

              <div className="flex gap-4">
                <Avatar user={user} className="mt-1" />

                <RichText
                  {...form.getInputProps("title")}
                  data-testid="project-comment-input"
                  ref={ref}
                  users={project?.collaborators
                    .map((c) => c.user)
                    .filter((c) => c.id !== user?.id)}
                  classNames={{ toolbar: "hidden" }}
                  className="flex-1"
                  placeholder="Leave a comment"
                />
              </div>

              <div className="flex justify-between mt-4 pl-16">
                <div className="flex gap-6 items-center">
                  <EmojiSelect onEmojiClick={handleEmojiClick} />

                  <div className="flex gap-2 items-center">
                    <Checkbox
                      size="sm"
                      checked={includeTime}
                      onChange={() => setIncludeTime(!includeTime)}
                    />
                    <span className="text-dark-300">Comment at</span>
                    <span className="text-dark-100">
                      {formatTime(currTime)}
                    </span>
                  </div>
                </div>

                <Button
                  data-testid="project-comment-submit"
                  onClick={handleSubmit}
                  loading={form.loading}
                >
                  Comment
                </Button>
              </div>
            </div>
          )}
        </Card>
      )}

      <Confirm
        color="red"
        title="Delete comment"
        content="Are you sure you want to delete this comment?"
        icon={<Trash className="w-6 h-6" />}
        opened={!!delId}
        onClose={() => setDelId(null)}
        onConfirm={handleDelete}
      />
    </div>
  );
};
