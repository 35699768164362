import { Badge } from "@mantine/core";
import { Cross1Icon, TrashIcon } from "@modulz/radix-icons";
import { Reply } from "@mui/icons-material";
import { updateMarkup } from "@requests/project/markup";
import { postReaction } from "@requests/project/reaction";
import { Markup, User } from "@server/entities";
import { EmojiType } from "@server/entities/project/reaction.entity";
import { UpdateMarkupDto } from "@server/modules/project/markup/dto/update-markup.dto";
import { Avatar } from "@synqup/ui/src/components/Avatar";
import { Button } from "@synqup/ui/src/components/Button";
import { EmojiSelect } from "@synqup/ui/src/components/EmojiSelect";
import { RichText } from "@synqup/ui/src/components/RichText";
import { RichTextReadOnly } from "@synqup/ui/src/components/RichTextReadOnly";
import { formatTime } from "@synqup/ui/src/utils/format-time";
import { getRandomColor } from "@synqup/ui/src/utils/get-random-color";
import { notification } from "@synqup/ui/src/utils/notification";
import useAsyncForm from "@synqup/ui/src/utils/use-async-form";
import dayjs from "dayjs";
import React, { useState } from "react";
import { KeyedMutator } from "swr";
import { Edit } from "tabler-icons-react";

import { usePlayer } from "../../contexts/Player";
import { useUser } from "../../contexts/UserContext";

interface CommentProps {
  comment: Markup;
  onDelete: (id: string) => void;
  onReply: (user: User) => void;
  mutate: KeyedMutator<any>;
  taggableUsers: User[];
}

export const Comment: React.FC<CommentProps> = ({
  comment,
  onDelete,
  onReply,
  mutate,
  taggableUsers,
}) => {
  const { user } = useUser();
  const { seek } = usePlayer();
  const [edit, setEdit] = useState(false);

  const form = useAsyncForm<UpdateMarkupDto>({
    initialValues: {
      title: comment.title,
    } as UpdateMarkupDto,
  });

  const handleEditCancel = () => {
    setEdit((edit) => !edit);
    form.reset();
  };

  const handleSave = async () => {
    const { error } = await form.sendForm(
      (values) =>
        updateMarkup(comment.id, {
          title: values.title,
        }),
      {
        resetInitial: true,
      }
    );
    if (error) return;
    setEdit(false);
  };

  const handleReact = async ({ type }: { type: EmojiType }) => {
    const { error } = await postReaction(comment.id, {
      type,
      reactionFor: "markup",
    });
    if (error) return notification.error(error.message);
    await mutate();
  };

  return (
    <div>
      <div className="flex gap-4 items-start">
        <Avatar user={comment.reporter} />

        <div className="flex-1">
          <p className="text-indigo-400 font-medium m-0">
            {comment.reporter?.displayName}{" "}
            <span className="text-dark-400 font-normal ml-1">
              {dayjs(comment.createdAt).fromNow()}
            </span>
            {comment.mixdown && (
              <Badge
                className="ml-2"
                size="xs"
                color={getRandomColor(comment.mixdown.id)}
              >
                {comment.mixdown.name}
              </Badge>
            )}
          </p>

          <div>
            {comment.start && (
              <span
                onClick={() => comment.start && seek(comment.start)}
                className="bg-dark-500 text-indigo-400 text-xs font-medium px-1 py-0.5 rounded mr-2"
              >
                {formatTime(comment.start)}
              </span>
            )}

            {edit ? (
              <div className="relative">
                <RichText
                  {...form.getInputProps("title")}
                  data-testid="comment-body-input"
                  classNames={{ toolbar: "hidden" }}
                  className="mt-2 flex-1"
                  users={taggableUsers}
                />

                <Button
                  data-testid="comment-save"
                  onClick={handleSave}
                  size="xs"
                  className="mt-4"
                >
                  Save
                </Button>
              </div>
            ) : (
              <RichTextReadOnly
                data-testid="comment-body"
                value={form.values.title || comment.title}
              />
            )}

            <div className="flex gap-2 mt-2 -ml-2">
              <EmojiSelect
                value={comment.reactions?.find(
                  (reaction) => reaction.userId === user?.id
                )}
                reactions={comment.reactions}
                onEmojiClick={handleReact}
              />

              <Button
                data-testid="comment-reply"
                color="dark"
                variant="subtle"
                size="xs"
                leftIcon={<Reply className="w-3" />}
                onClick={() => comment.reporter && onReply(comment.reporter)}
              >
                Reply
              </Button>

              {comment.reporterId === user?.id && (
                <>
                  <Button
                    data-testid="comment-edit"
                    variant="subtle"
                    size="xs"
                    leftIcon={
                      edit ? (
                        <Cross1Icon className="w-3" />
                      ) : (
                        <Edit className="w-3" />
                      )
                    }
                    onClick={handleEditCancel}
                  >
                    {edit ? "Cancel" : "Edit"}
                  </Button>

                  <Button
                    data-testid="comment-delete"
                    color="red"
                    variant="subtle"
                    size="xs"
                    leftIcon={<TrashIcon className="w-3" />}
                    onClick={() => onDelete(comment.id)}
                  >
                    Delete
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
