import { Skeleton as MantineSkeleton, SkeletonProps } from "@mantine/core";
import React from "react";

const Skeleton: React.FC<SkeletonProps> = (props) => {
  const defaults: SkeletonProps = {
    ...props,
  };

  if (!props.visible) return <></>;

  return <MantineSkeleton {...defaults} {...props} />;
};

export default Skeleton;
