import {
  RecoilStates,
  useSelector,
} from "../../modules/stemviewer/recoil/helpers/selectors";

import { markupState, projectState, TaskState } from "./task.atom";

export const states: RecoilStates<TaskState> = {
  project: projectState,
  markup: markupState,
};

export function useTaskState<K extends keyof TaskState>(
  keys: K[] = []
): Pick<TaskState, K> {
  return useSelector(keys, states);
}
