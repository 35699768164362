import { Avatar, AvatarsGroup, Button, Tooltip } from "@common/components";
import Checkbox from "@common/components/Checkbox";
import { formatBytes } from "@common/utils/constants";
import { Avatar as MantineAvatar } from "@mantine/core";
import { useMantineTheme } from "@mantine/core";
import { useHover, useMediaQuery } from "@mantine/hooks";
import { File } from "@server/entities";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { downloadUrl } from "../../../modules/stemviewer/helpers/download-blob";
import { useMediaLibrary } from "../MediaLibraryContext";

import { FileIcon } from "./FileIcon";
import { getFilename } from "./FileLibrary";
import { FileMenu } from "./FileMenu";

interface FileRowProps {
  file: File;
  projectId?: string;
  onFileSelect?: (file: File) => void;
  sharedWithMe?: boolean;
}

interface FileHeaderProps {
  files?: File[];
  projectId?: string;
  sharedWithMe?: boolean;
}

export const FileHeader: React.FC<FileHeaderProps> = ({
  files,
  projectId,
  sharedWithMe,
}) => {
  const theme = useMantineTheme();
  const { isFileChecked, onFileChecked } = useMediaLibrary();
  const { hovered, ref } = useHover();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  const isAllFilesChecked =
    files && files.length > 0 && files.every((file) => isFileChecked(file.id));

  return (
    <tr ref={ref as any} className="relative">
      <th className="w-12 border-none">
        <Checkbox
          data-testid="select-all"
          radius="sm"
          checked={isAllFilesChecked}
          onChange={() => onFileChecked("all")}
          style={{
            visibility:
              hovered || isAllFilesChecked || isMobile ? "visible" : "hidden",
          }}
        />
      </th>
      <th className="pl-1">Name</th>
      {!projectId && <th className="w-24 text-center">Project</th>}
      {!isMobile && !sharedWithMe && <th className="w-32">Shared with</th>}
      {(projectId || sharedWithMe) && !isMobile && (
        <th className="w-24 text-center">Owner</th>
      )}
      {!isMobile && <th className="w-32">Date added</th>}
      <th className="w-28">Size</th>
    </tr>
  );
};

export const FileRow: React.FC<FileRowProps> = ({
  file,
  projectId,
  onFileSelect,
  sharedWithMe,
}) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { hovered, ref } = useHover();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  const {
    closeMediaLibrary,
    onFileDelete,
    isFileChecked,
    onFileChecked,
    onFilesShare,
  } = useMediaLibrary();

  const hoveredStyle = hovered
    ? {
        backgroundColor: theme.colors.dark[8],
      }
    : {};

  const hoveredHiddenStyle: any = hovered
    ? {
        visibility: "hidden",
      }
    : {};

  const handleClick = () => {
    navigate(`/files/${file.id}`);
  };

  const handleDownload = async (e) => {
    e.stopPropagation();
    setLoading(true);
    await downloadUrl(file.url, file.filename);
    setLoading(false);
  };

  const handleShare = (e) => {
    e.stopPropagation();
    onFilesShare([file]);
  };

  return (
    <tr
      ref={ref as any}
      className="relative cursor-pointer"
      data-testid={`file-row-${file.id}`}
    >
      <td className="w-10 border-none">
        <Checkbox
          data-testid="file-checkbox"
          radius="sm"
          checked={isFileChecked(file.id)}
          onChange={() => onFileChecked(file.id)}
          style={{
            visibility:
              hovered || isFileChecked(file.id) || isMobile
                ? "visible"
                : "hidden",
          }}
        />
      </td>
      <td
        className="pl-1 truncate max-w-[1px] border-dark-800"
        style={hoveredStyle}
        onClick={handleClick}
      >
        <div className="flex items-center gap-2">
          <FileIcon file={file} />
          <p data-testid="file-name">{getFilename(file)}</p>
        </div>
      </td>
      {!projectId && (
        <td
          data-testid="file-project-name"
          className="text-center border-dark-800"
          style={hoveredStyle}
          onClick={handleClick}
        >
          {file.project ? (
            <Tooltip label={file.project?.name}>
              <MantineAvatar
                radius="sm"
                src={file.project?.albumArt}
                size="sm"
                className="mx-auto"
              />
            </Tooltip>
          ) : (
            "-"
          )}
        </td>
      )}
      {!isMobile && !sharedWithMe && (
        <td
          style={hoveredStyle}
          className="border-dark-800"
          onClick={handleClick}
        >
          <AvatarsGroup
            data-testid="file-shared-with"
            size="sm"
            limit={2}
            users={file.sharedWith}
          />
        </td>
      )}
      {!isMobile && (projectId || sharedWithMe) && (
        <td
          data-testid="file-owner"
          style={hoveredStyle}
          className="border-dark-800"
          onClick={handleClick}
        >
          <Avatar size="sm" className="mx-auto" user={file.user} />
        </td>
      )}
      {!isMobile && (
        <td
          style={hoveredStyle}
          className="border-dark-800"
          onClick={handleClick}
        >
          <p className="text-dark-400 m-0" style={hoveredHiddenStyle}>
            {dayjs(file.createdAt).format("D/M/YY")}
          </p>
        </td>
      )}

      <td
        style={hoveredStyle}
        className="relative border-dark-800"
        onClick={handleClick}
      >
        <p className="text-dark-400 m-0 mr-4" style={hoveredHiddenStyle}>
          {formatBytes(file.size)}
        </p>

        <div
          className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-2"
          style={{
            visibility: hovered ? "visible" : "hidden",
          }}
        >
          {onFileSelect ? (
            <Button
              variant="filled"
              color="dark"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onFileSelect(file);
                closeMediaLibrary();
              }}
            >
              Select
            </Button>
          ) : (
            <>
              <Button
                data-testid="download-file"
                size="sm"
                onClick={handleDownload}
                loading={loading}
              >
                Download
              </Button>

              <Button
                data-testid="share-file"
                size="sm"
                variant="light"
                color="gray"
                onClick={handleShare}
              >
                Share
              </Button>
            </>
          )}

          <FileMenu file={file} onFileDelete={onFileDelete} />
        </div>
      </td>
    </tr>
  );
};
