import { FormActionButtons } from "@common/components/FormActionButtons";
import useAsyncForm from "@common/utils/use-async-form";
import { getUserByUsername, patchUser } from "@requests/user/user";
import { LinkedAccount } from "@server/entities/user";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import { unionBy } from "lodash";
import React from "react";

import { useUser } from "../../../contexts/UserContext";
import { EditProfileLinkedAccounts } from "../../profile/components/EditProfileLinkedAccounts";

export const LinkedAccountSettings: React.FC = () => {
  const { user: me } = useUser();
  const { data: user } = getUserByUsername(me?.username as string);

  const form = useAsyncForm<UpdateUserDto>({
    initialValues: {
      ...user,
      linkedAccounts: unionBy(
        user?.linkedAccounts,
        [
          { type: "instagram" },
          { type: "spotify" },
          { type: "tiktok" },
          { type: "youtube" },
          { type: "soundcloud" },
        ] as LinkedAccount[],
        "type"
      ),
    } as UpdateUserDto,
  });

  const handleSave = async () => {
    if (!user) return;
    await form.sendForm((values) => patchUser(user.id, values), {
      successMessage: "User updated!",
      resetInitial: true,
    });
  };

  return (
    <div className="p-10 container">
      <h2 className="mb-4">Linked Accounts</h2>

      <EditProfileLinkedAccounts form={form} />

      <FormActionButtons form={form} onSave={handleSave} />
    </div>
  );
};
