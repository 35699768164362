import { Button, ButtonProps, RichText } from "@common/components";
import { FormActionButtons } from "@common/components/FormActionButtons";
import useAsyncForm from "@common/utils/use-async-form";
import { Loader, Popover } from "@mantine/core";
import { Check, Upload } from "@mui/icons-material";
import { createMarkupComment, updateMarkup } from "@requests/project/markup";
import { Markup } from "@server/entities/project";
import { CreateMarkupCommentDto } from "@server/modules/project/markup/dto/create-markup-comment.dto";
import React, { useCallback, useState } from "react";
import { useSetRecoilState } from "recoil";
import { Pencil } from "tabler-icons-react";

import { useUser } from "../../../../../contexts/UserContext";
import { useProject } from "../../../../projects/view/ProjectContext";
import { useStemViewer } from "../../../contexts/StemViewerContext";
import { replaceState } from "../../../recoil/stem";

interface MarkupStatusButtonProps extends ButtonProps {
  markup?: Markup;
}

const MarkupStatusButton: React.FC<MarkupStatusButtonProps> = ({
  markup,
  ...props
}) => {
  const { user } = useUser();
  const { project } = useProject();
  const { markup: markupCtl } = useStemViewer().controllers;
  const setReplace = useSetRecoilState(replaceState);
  const [reverting, setReverting] = useState(false);
  const [opened, setOpened] = useState(false);
  const [approving, setApproving] = useState(false);

  const form = useAsyncForm<CreateMarkupCommentDto>({
    initialValues: {
      body: "",
      type: "request_changes",
      markupId: markup?.id,
    },
  });

  const handleApprove = useCallback(async () => {
    if (!markup) return;
    setApproving(true);
    await markupCtl.approve(markup?.id);
    setApproving(false);
  }, [markup]);

  const handleRequestChanges = useCallback(async () => {
    if (!markup?.id) return;
    await form.sendForm((values) => createMarkupComment(values));
    await updateMarkup(markup.id, { status: "pending" });
    markupCtl.view(markup.id);
    setOpened(false);
  }, [form, markup]);

  if (!markup) return <Loader size="sm" />;

  if (markup.status === "needs_approval" && user?.id === markup.reporter?.id)
    return (
      <div className="flex items-center gap-2">
        <Button
          data-quick-assist-id="sv-approve"
          data-testid="sv-approve"
          loading={approving}
          color="green"
          leftIcon={<Check className="w-4 h-4" />}
          {...props}
          onClick={handleApprove}
        >
          Approve
        </Button>

        <Popover
          opened={opened}
          onClose={() => setOpened(false)}
          withinPortal
          width={400}
          position="bottom-end"
        >
          <Popover.Target>
            <Button
              data-quick-assist-id="sv-request-changes"
              onClick={() => setOpened(true)}
              loading={reverting}
              color="dark"
              variant="outline"
              leftIcon={<Pencil className="w-4 h-4" />}
              {...props}
            >
              Request changes
            </Button>
          </Popover.Target>

          <Popover.Dropdown>
            <div>
              <RichText
                description="Add your comment"
                users={project?.collaborators
                  .map((c) => c.user)
                  .filter((c) => c.id !== user?.id)}
                classNames={{ toolbar: "hidden", root: "bg-dark-900" }}
                className="flex-1"
                placeholder="Add a comment"
                {...form.getInputProps("body")}
                // onKeyDown={handleKeyDown}
              />

              <FormActionButtons
                form={form}
                onSave={handleRequestChanges}
                saveText="Submit"
                size="xs"
              />
            </div>
          </Popover.Dropdown>
        </Popover>
      </div>
    );

  if (markup.status === "pending" || user?.id === markup.assignee?.id)
    return (
      <Button
        data-quick-assist-id="sv-markup-upload"
        leftIcon={<Upload className="w-4 h-4" />}
        {...props}
        onClick={() => markup?.stem?.stemId && setReplace(markup.stem?.stemId)}
      >
        Upload
      </Button>
    );

  return <></>;
};

export { MarkupStatusButton };
