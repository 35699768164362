import { MenuItemProps, MenuProps } from "@mantine/core";
import React from "react";

import { Menu } from "./Menu";

interface ItemProps extends MenuItemProps {
  name: string;
  color?: string;
  icon?: React.ReactNode;
  onClick?: () => any;
  disabled?: boolean;
}

interface ContextMenuProps extends MenuProps {
  children?: React.ReactElement | undefined;
  context: { left: number; top: number };
  items: ItemProps[];
}

const ContextMenu = ({ items, context, ..._props }: ContextMenuProps) => {
  return (
    <Menu
      withinPortal
      position="bottom-start"
      transition="pop-top-left"
      {..._props}
    >
      <Menu.Target>
        <div
          style={{
            position: "fixed",
            left: context.left,
            top: context.top,
          }}
        />
      </Menu.Target>

      <Menu.Dropdown className="p-1">
        {items.map((item, index) => (
          <Menu.Item
            {...item}
            p={8}
            className="text-xs"
            key={index}
            color={item.color}
            icon={item.icon}
            onClick={item.onClick}
            disabled={item.disabled}
          >
            {item.name}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export { ContextMenu };
