import { Button, Card } from "@common/components";
import Confirm from "@common/components/Confirm";
import Skeleton from "@common/components/Skeleton";
import { notification } from "@common/utils/notification";
import { Table } from "@mantine/core";
import { ExitIcon } from "@modulz/radix-icons";
import { GroupAdd, PermIdentity, PersonRemove } from "@mui/icons-material";
import { removeCollab, updateCollab } from "@requests/project/collaborator";
import { Collaborator, Project } from "@server/entities/project";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useProject } from "../ProjectContext";

import { CollaboratorRow } from "./CollaboratorRow";
import { InviteUsers } from "./InviteUsers";

const ProjectCollaborators: React.FC = () => {
  const navigate = useNavigate();
  const { project, loading, mutate } = useProject();
  const [invite, setInvite] = useState(false);
  const [removeUser, setRemoveUser] = useState<Collaborator | null>(null);
  const [removeUserLoading, setRemoveUserLoading] = useState(false);
  const [makeAdmin, setMakeAdmin] = useState<Collaborator | null>(null);
  const [makeAdminLoading, setMakeAdminLoading] = useState(false);
  const [leaveUser, setLeaveUser] = useState<Collaborator | null>(null);
  const [leaveUserLoading, setLeaveUserLoading] = useState(false);

  const handleRemove = async () => {
    if (!removeUser) return;
    setRemoveUserLoading(true);
    const { error } = await removeCollab(removeUser.id);
    if (error) return notification.error(error.message);
    await mutate();
    setRemoveUserLoading(false);
    setRemoveUser(null);
    notification.success("User removed!");
  };

  const mutateRole = async (
    collab: Collaborator,
    role: Collaborator["role"]
  ) => {
    await mutate(
      (data) => ({
        ...data,
        project: {
          ...project,
          collaborators: data?.project?.collaborators.map((c) =>
            c.id === collab.id
              ? ({
                  ...c,
                  role,
                } as Collaborator)
              : c
          ),
        } as Project,
      }),
      { revalidate: false }
    );
  };

  const handleRoleChange = async (
    collab: Collaborator,
    role: Collaborator["role"]
  ) => {
    if (role === "admin") return setMakeAdmin(collab);
    const { error } = await updateCollab(collab.id, { role });
    if (error) return notification.error(error.message);
    await mutateRole(collab, role);
    notification.success("Role updated!");
  };

  const handleAdmin = async () => {
    if (!makeAdmin) return;
    setMakeAdminLoading(true);
    const { error } = await updateCollab(makeAdmin.id, { role: "admin" });
    if (error) return notification.error(error.message);
    await mutateRole(makeAdmin, "admin");
    setMakeAdminLoading(false);
    setMakeAdmin(null);
    notification.success("Role updated!");
  };

  const handleLeave = async () => {
    if (!project?.id) return;
    if (!leaveUser) return;
    setLeaveUserLoading(true);
    const { error } = await removeCollab(leaveUser.id);
    setLeaveUser(null);
    setLeaveUserLoading(false);
    if (error) return notification.error(error.message);
    navigate("/projects");
  };

  return (
    <div>
      <InviteUsers opened={invite} onClose={() => setInvite(false)} />
      <br />

      <Confirm
        opened={!!removeUser}
        loading={removeUserLoading}
        onClose={() => setRemoveUser(null)}
        color="red"
        icon={<PersonRemove fontSize="large" />}
        title="Remove user from project?"
        content={`Are you sure you want to remove ${removeUser?.user.displayName} from
                the project? They will no longer have access to the project,
                and any related markups will be unassigned.`}
        onConfirm={handleRemove}
      />

      <Confirm
        opened={!!makeAdmin}
        loading={makeAdminLoading}
        onClose={() => setMakeAdmin(null)}
        icon={<PermIdentity fontSize="large" />}
        title="Make this user an admin?"
        content={`Once you make this user an admin, you cannot
                undo this change. This is to protect your shared work.`}
        onConfirm={handleAdmin}
      />

      <Confirm
        opened={!!leaveUser}
        loading={leaveUserLoading}
        onClose={() => setLeaveUser(null)}
        color="red"
        icon={<ExitIcon fontSize="large" />}
        title="Leave this project?"
        content="Are you sure you want to leave this project? You will lose access to all associated data."
        onConfirm={handleLeave}
      />

      <div className="flex justify-end mb-4">
        <Button
          data-testid="project-invite-user"
          data-quick-assist-id="project-invite-user"
          leftIcon={<GroupAdd />}
          onClick={() => setInvite(true)}
        >
          Invite new user
        </Button>
      </div>

      {loading ? (
        Array(3)
          .fill(0)
          .map((i, index) => (
            <Skeleton key={index} height={48} className="my-2" />
          ))
      ) : (
        <Card variant="outlined" p={0}>
          <Table verticalSpacing={1}>
            <tbody>
              {project?.collaborators?.map((collab) => (
                <CollaboratorRow
                  key={collab.id}
                  collab={collab}
                  onChange={handleRoleChange}
                  onRemove={setRemoveUser}
                  onLeave={setLeaveUser}
                />
              ))}
            </tbody>
          </Table>
        </Card>
      )}
    </div>
  );
};

export { ProjectCollaborators };
