import { Card } from "@common/components";
import { FormActionButtons } from "@common/components/FormActionButtons";
import useAsyncForm from "@common/utils/use-async-form";
import { Divider, Switch } from "@mantine/core";
import { getUserByUsername, patchUser } from "@requests/user/user";
import { NotificationSettings as NotificationSettingsEntity } from "@server/entities/user/notification-settings.entity";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import React, { ChangeEvent } from "react";

import { useUser } from "../../../contexts/UserContext";

type NotificationSettingOption = {
  heading: string;
  description: string;
  value: keyof NotificationSettingsEntity;
};

export const NotificationSettings: React.FC = () => {
  const { user: me } = useUser();
  const { data: user } = getUserByUsername(me?.username as string);

  const form = useAsyncForm<UpdateUserDto>({
    initialValues: {
      notificationSettings:
        user?.notificationSettings ||
        ({
          projectInvite: true,
          projectUpdated: true,
          projectNoFiles: true,
          projectNotAccessed: true,
          projectNotes: true,
          markupAssigned: true,
          markupUpdated: true,
          markupComments: true,
          markupMentions: true,
          mixdownUpdates: true,
          fileUpdates: true,
          messages: true,
          friendRequest: true,
        } as NotificationSettingsEntity),
    },
  });

  const handleSave = async () => {
    if (!user) return;
    await form.sendForm((values) => patchUser(user.id, values), {
      successMessage: "Settings updated!",
      resetInitial: true,
    });
  };

  const handleUnsubscribeAll = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!form.values.notificationSettings) return;

    form.setValues({
      notificationSettings: Object.keys(
        form.values.notificationSettings
      ).reduce(
        (acc, key) => ({
          ...acc,
          [key]: key === "id" ? acc[key] : !target.checked,
        }),
        {}
      ) as NotificationSettingsEntity,
    });
  };

  const isAllUnsubscribed = !form.values.notificationSettings
    ? false
    : Object.values(form.values.notificationSettings).every((value) => !value);

  const options: NotificationSettingOption[] = [
    {
      value: "projectInvite",
      heading: "Project Invite",
      description:
        "This notification informs you when a User has invited you to a New Project.",
    },
    {
      value: "projectUpdated",
      heading: "Project Updated",
      description:
        "This notification informs you when a Collaborator has updated Project details. This includes adding or editing comments and notes.",
    },
    {
      value: "projectNoFiles",
      heading: "Project Not Accessed",
      description:
        "This notification informs you when a Project has no files uploaded after a certain period of time.",
    },
    {
      value: "projectNotAccessed",
      heading: "Project Not Accessed",
      description:
        "This notification informs you when a Project has not been accessed after a certain period of time.",
    },
    {
      value: "projectNotes",
      heading: "Project Notes",
      description:
        "This notification informs you when a Collaborator has added a new Note to a Project.",
    },
    {
      value: "markupAssigned",
      heading: "Markup Assigned",
      description:
        "This notification informs you when a new Markup is created and assigned to you.",
    },
    {
      value: "markupUpdated",
      heading: "Markup Updated",
      description:
        "This notification informs you when Markup details are updated.",
    },
    {
      value: "markupComments",
      heading: "Markup Comments",
      description:
        "This notification informs you when someone has commented on your Markup",
    },
    {
      value: "markupMentions",
      heading: "Markup Mentions",
      description:
        "This notification informs you when someone has mentioned you in a Markup comment.",
    },
    {
      value: "mixdownUpdates",
      heading: "Mixdown Updates",
      description:
        "This notification informs you when a Mixdown has been uploaded or commented on.",
    },
    {
      value: "fileUpdates",
      heading: "File Updates",
      description:
        "This notification informs you when a File has been shared with you or commented on.",
    },
    {
      value: "messages",
      heading: "Messages",
      description:
        "This notification informs you when you receive a new message.",
    },
    {
      value: "friendRequest",
      heading: "Friend Request",
      description:
        "This notification informs you when you receive a new Friend Request.",
    },
  ];

  return (
    <div className="p-10 container">
      <h2 className="mb-4">Notifications</h2>
      <p className="text-gray-400">
        Notifications are sent to{" "}
        <span className="text-indigo-400">{user?.email}</span>. Toggle the
        buttons below to choose your preferences.
      </p>

      <div className="flex flex-col gap-4">
        {options.map((opt) => (
          <Card key={opt.value}>
            <div className="flex items-center justify-between gap-4 -my-1">
              <div>
                <h4>{opt.heading}</h4>
                <p className="text-gray-400 mt-2">{opt.description}</p>
              </div>

              <Switch
                {...form.getInputProps(`notificationSettings.${opt.value}`, {
                  type: "checkbox",
                })}
              />
            </div>
          </Card>
        ))}
      </div>

      <Divider className="my-8" />

      <Card>
        <div className="flex items-center justify-between gap-4 -my-1">
          <div>
            <h4>Unsubscribe from all</h4>
            <p className="text-gray-400 mt-2">
              Turn off all notifications at once.
            </p>
          </div>

          <Switch checked={isAllUnsubscribed} onChange={handleUnsubscribeAll} />
        </div>
      </Card>

      <FormActionButtons form={form} onSave={handleSave} />
    </div>
  );
};
