import { DotsMenu } from "@common/components";
import { File } from "@server/entities";
import React from "react";
import { Trash } from "tabler-icons-react";

import { useMediaLibrary } from "../MediaLibraryContext";

interface FileMenuProps {
  file: File;
  onFileDelete: (file: File) => void;
  shared?: boolean;
}

export const FileMenu: React.FC<FileMenuProps> = ({
  file,
  onFileDelete,
  shared,
}) => {
  const { onFilesShare } = useMediaLibrary();

  return (
    <DotsMenu>
      <DotsMenu.Dropdown>
        {!shared && (
          <DotsMenu.Item
            color="red"
            icon={<Trash className="w-4 h-4" />}
            onClick={(e) => {
              e.stopPropagation();
              onFileDelete(file);
            }}
          >
            Delete file
          </DotsMenu.Item>
        )}
      </DotsMenu.Dropdown>
    </DotsMenu>
  );
};
