import { Button, Modal } from "@common/components";
import { ModalProps } from "@mantine/core";
import React, { useState } from "react";
import { History } from "tabler-icons-react";

import { useStemViewer } from "../../../contexts/StemViewerContext";

interface ResetStemviewerProps extends ModalProps {
  onMixdown: () => void;
}

export const ResetStemviewer: React.FC<ResetStemviewerProps> = ({
  onMixdown,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const { stems } = useStemViewer().stem(["stems"]);
  const { stem: stemCtl } = useStemViewer().controllers;

  const handleReset = async () => {
    setLoading(true);
    for (const stem of stems) {
      await stemCtl.delete(stem.stemId);
    }
    setLoading(false);
    props.onClose();
  };

  return (
    <Modal
      title={
        <div className="flex items-center">
          <History size={20} className="mr-2" />
          <h3>Reset Stemviewer</h3>
        </div>
      }
      size="sm"
      {...props}
    >
      <p>
        This action will delete all the stems in the Stemviewer. You may want to{" "}
        <span className="text-primary-400">export the mixdown</span> before
        resetting.
      </p>

      <div className="flex justify-end gap-2">
        <Button
          size="sm"
          variant="filled"
          onClick={() => {
            onMixdown();
            props.onClose();
          }}
        >
          Save Mixdown
        </Button>

        <Button
          onClick={handleReset}
          loading={loading}
          size="sm"
          variant="light"
          color="red"
        >
          Reset
        </Button>
      </div>
    </Modal>
  );
};
