import { Card } from "@common/components";
import { ClassificationBadge } from "@common/components/ClassificationBadge";
import Skeleton from "@common/components/Skeleton";
import { Badge } from "@mantine/core";
import React from "react";

import { useProfile } from "../pages/Profile";

interface ProfileClassificationsProps {}

export const ProfileClassifications: React.FC<
  ProfileClassificationsProps
> = ({}) => {
  const { user } = useProfile();

  return (
    <Card>
      <h3>Classification</h3>

      <div className="flex flex-col items-start gap-2 mt-4">
        {user?.classifications ? (
          user.classifications.length === 0 ? (
            <p>Nothing here yet!</p>
          ) : (
            user.classifications.map((cls) => (
              <ClassificationBadge
                key={cls.id}
                className="mr-auto"
                classification={cls}
              />
            ))
          )
        ) : (
          Array(2)
            .fill(0)
            .map((item, index) => (
              <Skeleton key={index} visible className="w-24 mr-auto rounded-xl">
                <Badge size="sm" />
              </Skeleton>
            ))
        )}
      </div>
    </Card>
  );
};
