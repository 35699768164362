import { formatBytes } from "@common/utils/constants";
import { useMantineTheme } from "@mantine/core";
import { Message as MessageEntity } from "@server/entities/social/message.entity";
import React from "react";

interface MessageProps {
  message: MessageEntity;
  self: boolean;
}

const Message: React.FC<MessageProps> = ({ message, self }) => {
  const theme = useMantineTheme();

  let body = <div />;

  if (message.type === "text")
    body = (
      <p className="break-words" data-testid="message-text">
        {message.text}
      </p>
    );

  if (message.type === "file")
    body = (
      <a href={message.file?.url}>
        <p
          className="text-md font-bold -mb-1 leading-2 truncate"
          data-testid="message-file"
        >
          {message.file?.filename}
        </p>
        <p className="text-xs opacity-70">
          {formatBytes(Number(message.file?.size), 1)}
        </p>
      </a>
    );

  return (
    <div
      style={{
        borderTopLeftRadius: self ? 16 : 0,
        borderTopRightRadius: !self ? 16 : 0,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
        backgroundColor: self ? theme.colors.primary[6] : theme.colors.dark[6],
        marginLeft: self ? "auto" : 0,
        marginRight: self ? 0 : "auto",
      }}
      className="inline-block px-3 py-2 max-w-[14rem]"
      data-testid={self ? "message-self" : "message-other"}
    >
      {body}
    </div>
  );
};

export { Message };
