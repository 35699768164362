import { DatePicker, Input, Modal, RichText } from "@common/components";
import { FormActionButtons } from "@common/components/FormActionButtons";
import useAsyncForm from "@common/utils/use-async-form";
import { ModalProps } from "@mantine/core";
import { createMarkup } from "@requests/project/markup";
import { CreateMarkupDto } from "@server/modules/project/markup/dto/create-markup.dto";
import React from "react";
import { object } from "yup";

import { SearchCollab } from "../../modules/projects/components/SearchCollab";
import { SearchProjects } from "../../modules/projects/components/SearchProjects";
import { useUser } from "../UserContext";

import { useTaskState } from "./task.selector";

interface CreateMarkupModalProps extends ModalProps {
  initialValues?: CreateMarkupDto;
  onCreate: () => void;
}

export const CreateMarkupModal: React.FC<CreateMarkupModalProps> = ({
  initialValues,
  onCreate,
  ...props
}) => {
  const { user } = useUser();
  const { project } = useTaskState(["project"]);

  const form = useAsyncForm<CreateMarkupDto>({
    initialValues: {
      type: "task",
      project: project ? project : undefined,
      reporter: user,
      ...initialValues,
    },
    schema: object().shape({
      project: object().required("Project is required"),
    }),
  });

  const handleSave = async () => {
    const { error } = await form.sendForm((values) =>
      createMarkup({ ...values, projectId: values.project?.id })
    );
    if (error) return;

    form.reset();
    props.onClose();
    onCreate();
  };

  return (
    <Modal title={<h2>Create Task</h2>} {...props}>
      <div className="flex flex-col gap-2">
        <Input
          data-testid="create-task-title"
          required
          size="sm"
          label="Title"
          {...form.getInputProps("title")}
        />

        <RichText
          data-testid="create-task-description"
          label="Description"
          value={form.values.description || ""}
          onChange={(value) => form.setFieldValue("description", value)}
        />

        <DatePicker
          data-testid="create-task-due-date"
          label="Due Date"
          type="date"
          size="sm"
          {...form.getInputProps("dueDate")}
        />

        <SearchProjects
          required
          data-quick-assist-id="create-task-project"
          data-testid="create-task-project"
          label="Project"
          size="sm"
          {...form.getInputProps("project")}
        />

        <SearchCollab
          required
          data-quick-assist-id="create-task-reporter"
          data-testid="create-task-reporter"
          label="Reporter"
          size="sm"
          project={form.values.project}
          {...form.getInputProps("reporter")}
        />

        <SearchCollab
          data-quick-assist-id="create-task-assignee"
          data-testid="create-task-assignee"
          label="Assignee"
          size="sm"
          project={form.values.project}
          {...form.getInputProps("assignee")}
        />
      </div>

      <FormActionButtons
        data-testid="save-button"
        form={form}
        onSave={handleSave}
      />
    </Modal>
  );
};
