import { Menu } from "@common/components";
import { Markup } from "@server/entities/project";
import React from "react";

import { MarkupStatusBadge, MarkupStatusProps } from "./MarkupStatusBadge";

interface MarkupStatusSelectProps extends MarkupStatusProps {
  onChange: (value: Markup["status"]) => void;
}

export const MarkupStatusSelect: React.FC<MarkupStatusSelectProps> = ({
  value,
  onChange,
  ...props
}) => {
  const options: Markup["status"][] = ["pending", "needs_approval", "approved"];

  return (
    <Menu withinPortal position="bottom-start">
      <Menu.Target>
        <div data-quick-assist-id="markup-status" data-testid="markup-status">
          <MarkupStatusBadge value={value} {...props} />
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        {options.map((option) => (
          <Menu.Item
            key={option}
            data-testid={`markup-status-${option}`}
            p={4}
            onClick={() => onChange(option)}
          >
            <MarkupStatusBadge value={option} className="cursor-pointer" />
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
