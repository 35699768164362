import { ActionIcon, RichText } from "@common/components";
import { FormActionButtons } from "@common/components/FormActionButtons";
import { RenderHTML } from "@common/components/RenderHTML";
import useAsyncForm from "@common/utils/use-async-form";
import { useClickOutside } from "@mantine/hooks";
import { patchUser } from "@requests/user/user";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import React, { useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { Edit } from "tabler-icons-react";

import { useProfile } from "../pages/Profile";

interface ProfileBioProps {}

const ProfileBio: React.FC<ProfileBioProps> = () => {
  const [editBio, setEditBio] = useState(false);
  const { user, mutate, isMe } = useProfile();
  const ref = useClickOutside(() => setEditBio(false));
  const form = useAsyncForm<UpdateUserDto>({
    initialValues: { bio: user?.bio } as UpdateUserDto,
  });

  const handleSave = async () => {
    if (!user) return;
    await form.sendForm((values) => patchUser(user.id, values), {
      resetInitial: true,
    });
    await mutate();
    setEditBio(false);
  };

  if (!user?.bio && !isMe) return <></>;

  return (
    <section id="bio" className="py-10">
      <h2 className="mb-4">Biography</h2>

      <div ref={ref} className="bg-dark-800 rounded-xl p-6">
        {editBio ? (
          <div>
            <RichText {...form.getInputProps("bio")} />

            <FormActionButtons
              form={form}
              onSave={handleSave}
              onCancel={() => setEditBio(false)}
            />
          </div>
        ) : (
          <div>
            {user?.bio ? (
              <div className="relative">
                {isMe && (
                  <ActionIcon
                    className="absolute right-0 top-0"
                    variant="light"
                    size="sm"
                    onClick={() => setEditBio(true)}
                  >
                    <Edit />
                  </ActionIcon>
                )}

                <RenderHTML className="text-xl" html={user.bio} />
              </div>
            ) : (
              <div
                className="flex items-center justify-center gap-4 py-24 cursor-pointer transition opacity-50 hover:opacity-100"
                onClick={() => setEditBio(true)}
              >
                <BsPlusCircleFill className="w-8 h-8" />
                <p className="text-2xl">Add biography</p>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export { ProfileBio };
