import { PaginatedListDto } from "@server/dto/paginated-list.dto";
import { File } from "@server/entities";
import { QueryFileDto } from "@server/modules/file/dto/query-file.dto";
import { UploadFileDto } from "@server/modules/file/dto/upload-file.dto";
import { createContext, Dispatch, SetStateAction } from "react";
import { KeyedMutator } from "swr";

export interface MediaLibraryContextType {
  loading: boolean;
  openMediaLibrary: (
    type?: File["type"],
    callback?: (file: File) => void
  ) => void;
  closeMediaLibrary: () => void;
  checked: File[];
  isFileChecked: (id: string) => boolean;
  onFileDelete: (file: File) => void;
  onBulkDelete: () => void;
  onFileChecked: (id: string) => void;
  setChecked: Dispatch<SetStateAction<File[]>>;
  onFilesShare: (files: File[]) => void;
  files?: PaginatedListDto<File>;
  mutate: KeyedMutator<any>;
  query: QueryFileDto;
  setQuery: Dispatch<SetStateAction<QueryFileDto>>;
  uploadFiles: (uploads: UploadFileDto[]) => Promise<void>;
  handleNextPage: () => void;
}

export const initialState = {} as MediaLibraryContextType;

export const MediaLibraryContext =
  createContext<MediaLibraryContextType>(initialState);
MediaLibraryContext.displayName = "MediaLibraryContext";
