import { Badge, BadgeProps, Popover } from "@mantine/core";
import React from "react";

import { StemHowl } from "../../../recoil/stem";

interface VersionPickerProps extends BadgeProps {
  previewVersionId?: string;
  stem: StemHowl;
  onChange?: (versionId: string) => void;
}

export const VersionPicker: React.FC<VersionPickerProps> = ({
  previewVersionId,
  stem,
  onChange,
  ...props
}) => {
  if (!stem.previousVersionId) return <div />;

  props.style = { cursor: "pointer" };

  return (
    <Popover
      withinPortal
      position="bottom-start"
      classNames={{ dropdown: "p-1 rounded-sm" }}
    >
      <Popover.Target data-testid="sv-version-picker">
        {previewVersionId === stem.previousVersionId ? (
          <Badge {...props} color="blue">
            Previous
          </Badge>
        ) : (
          <Badge {...props} color="green">
            Current
          </Badge>
        )}
      </Popover.Target>

      <Popover.Dropdown>
        <div className="flex flex-col gap-1">
          <Badge
            {...props}
            color="green"
            onClick={() =>
              onChange && stem.versionId && onChange(stem.versionId)
            }
            className="hover:bg-dark-500"
            data-testid="sv-version-picker-current"
          >
            Current
          </Badge>

          <Badge
            {...props}
            color="blue"
            onClick={() =>
              onChange &&
              stem.previousVersionId &&
              onChange(stem.previousVersionId)
            }
            className="hover:bg-dark-500"
            data-testid="sv-version-picker-previous"
          >
            Previous
          </Badge>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
