import {
  Input as MantineInput,
  PasswordInput,
  InputBaseProps,
  DefaultProps,
  InputWrapperBaseProps,
  InputWrapperStylesNames,
  TextInputProps,
} from "@mantine/core";
import { QuestionMarkCircledIcon } from "@modulz/radix-icons";
import React, { forwardRef } from "react";

import { Tooltip } from "./Tooltip";

export function getInputDefaults(
  props: InputWrapperBaseProps & DefaultProps & { tooltip?: string }
): InputBaseProps & DefaultProps<InputWrapperStylesNames> {
  return {
    size: "md",
    radius: "sm",
    className: `relative ${props.error ? "animate-shake" : ""} ${
      props.className
    }`,
    label: props.label,
    error: props.error,
    description: props.description ? (
      <div className="text-xs mb-2">
        {props.description}
        {props.tooltip && (
          <Tooltip className="absolute right-0 top-1.5" label={props.tooltip}>
            <QuestionMarkCircledIcon />
          </Tooltip>
        )}
      </div>
    ) : undefined,
    classNames: {
      error: "text-sm",
      label: "text-sm",
      input: "dark:bg-dark-900 my-2",
    },
  };
}

const Input = forwardRef<
  HTMLInputElement,
  TextInputProps & {
    tooltip?: string;
    password?: boolean;
  }
>((props, ref) => {
  const {
    password,
    tooltip: _,
    color = props.error ? "red" : undefined,
    ..._props
  } = props;

  const defaults = getInputDefaults(_props);

  if (password)
    return (
      <PasswordInput {...defaults} {..._props} className={defaults.className} />
    );

  if (color) {
    defaults.styles = {
      ...defaults.styles,
      // @ts-ignore
      input: {
        border: "1px solid " + color,
      },
    };
  }

  return (
    <MantineInput.Wrapper {...defaults} required={props.required}>
      <MantineInput
        ref={ref}
        {...defaults}
        {..._props}
        className={defaults.className}
      />
    </MantineInput.Wrapper>
  );
});

export { Input };
