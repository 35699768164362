import { Button, Card, Divider } from "@common/components";
import Skeleton from "@common/components/Skeleton";
import { Badge, ScrollArea } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { groupBy } from "lodash";
import React, { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { useUser } from "../../contexts/UserContext";
import {
  loadingState,
  markupsState,
  selectedState,
} from "../stemviewer/recoil/markup/markup.atom";
import { projectState } from "../stemviewer/recoil/track";

import { MarkupCard } from "./components/MarkupCard";

const TaskBucket: React.FC = () => {
  const { user, markups: userMarkups } = useUser();
  const project = useRecoilValue(projectState);
  const projectMarkups = useRecoilValue(markupsState);
  const projectLoading = useRecoilValue(loadingState);
  const setSelected = useSetRecoilState(selectedState);
  const ref = useClickOutside(() => setSelected(null));
  const [onlyMyIssues, setOnlyMyIssues] = useState(false);

  const handleOnlyMyIssues = () => setOnlyMyIssues((prev) => !prev);

  const markups = project?.id ? projectMarkups : userMarkups.data?.data || [];
  const loading = project?.id ? projectLoading : userMarkups.isValidating;

  const filteredMarkups = markups.filter((markup) =>
    project?.id && onlyMyIssues ? markup.assigneeId === user?.id : true
  );

  const groupedMarkups = groupBy(
    filteredMarkups,
    (markup) => markup.project?.name
  );

  return (
    <Card className="flex-1" data-quick-assist-id="task-bucket">
      <h3 className="mb-4">Task Bucket</h3>

      {project?.id && (
        <Button
          className="mb-4"
          variant={onlyMyIssues ? "filled" : "light"}
          size="xs"
          onClick={handleOnlyMyIssues}
        >
          Only my markups
        </Button>
      )}

      {/* @ts-ignore */}
      <ScrollArea.Autosize ref={ref} maxHeight={400}>
        <div ref={ref} className="flex flex-col gap-2">
          {!loading ? (
            filteredMarkups.length !== 0 ? (
              Object.keys(groupedMarkups).map((key) => (
                <div key={key}>
                  {!project?.id && (
                    <Divider
                      label={
                        <Badge color="dark" variant="light">
                          {key}
                        </Badge>
                      }
                      labelPosition="center"
                      className="my-2"
                    />
                  )}
                  <div className="flex flex-col gap-2">
                    {groupedMarkups[key].map((markup) => (
                      <MarkupCard key={markup.id} markup={markup} />
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <p>No markups to show</p>
            )
          ) : (
            Array(3)
              .fill(0)
              .map((_, index) => <Skeleton visible key={index} height={70} />)
          )}
        </div>
      </ScrollArea.Autosize>
    </Card>
  );
};

export { TaskBucket };
