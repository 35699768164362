import { Divider } from "@common/components";
import { FolderIcon } from "@heroicons/react/24/solid";
import { Table } from "@mantine/core";
import { File } from "@server/entities";
import dayjs from "dayjs";
import { groupBy, orderBy } from "lodash";
import React, { useEffect } from "react";

import { useMediaLibrary } from "../MediaLibraryContext";

import { FileHeader, FileRow } from "./FileRow";

/**
 * Gets the order weight of a time difference string
 * Lower numbers appear first
 */
function getTimeGroupWeight(timeGroup: string): number {
  if (timeGroup === "Just now") return 0;
  if (timeGroup === "Today") return 1;
  if (timeGroup === "Yesterday") return 2;
  if (timeGroup.endsWith("days ago")) {
    const days = parseInt(timeGroup);
    return days + 2;
  }
  // For "MMM YYYY" format, convert to timestamp
  // This ensures proper ordering of older dates
  return dayjs(timeGroup, "MMM YYYY").valueOf();
}

/**
 * Ensures time groups are ordered chronologically
 */
function getSortedTimeGroups(groups: Record<string, any[]>): string[] {
  // First, separate recent and old dates
  const recentDates: string[] = [];
  const oldDates: string[] = [];

  Object.keys(groups).forEach((date) => {
    if (
      date === "Just now" ||
      date === "Today" ||
      date === "Yesterday" ||
      date.endsWith("days ago")
    ) {
      recentDates.push(date);
    } else {
      oldDates.push(date);
    }
  });

  // Sort recent dates by weight
  const sortedRecentDates = orderBy(recentDates, [getTimeGroupWeight], ["asc"]);

  // Sort old dates by their actual date
  const sortedOldDates = orderBy(
    oldDates,
    [(date) => dayjs(date, "MMM YYYY").valueOf()],
    ["desc"]
  );

  return [...sortedRecentDates, ...sortedOldDates];
}

interface FileLibraryProps {
  files?: File[];
  onFileSelect?: (file: File) => void;
  projectId?: string;
  sharedWithMe?: boolean;
}

export interface FileRowType {
  type: "file";
  file: File;
  name?: never;
  diff: string;
}

export interface FolderRowType {
  type: "folder";
  name: string;
  file?: never;
  diff: string;
}

export const getFilename = (file: File) =>
  file.filename.split(".").slice(0, -1).join(".");

export const getFiletype = (file: File) =>
  file.filename.split(".").slice(-1)[0];

/**
 * Gets the time difference between the file creation date and now
 * @param file the file to get the time difference for
 * @returns the time difference
 */
export function getTimeDifference(file: File): string {
  const createdAt = dayjs(file.createdAt).startOf("day");
  const diff = dayjs().diff(createdAt, "day");
  if (diff > 30) return dayjs(file.createdAt).format("MMM YYYY");
  if (diff > 1) return `${diff} days ago`;
  if (dayjs().isAfter(createdAt, "day")) return "Yesterday";
  if (dayjs().isSame(createdAt, "day")) return "Today";
  return "Just now";
}

/**
 * Gets files for a specific path
 * grouped by date
 * @param files the files to get
 * @param path the path to get files for
 */
export function getFolderizedFilesForPath(files?: File[], path = "/") {
  if (!files) return [];

  const filesAtPath: FileRowType[] = files
    .filter((file) => file.path === path)
    .map((file) => ({
      type: "file",
      file,
      diff: getTimeDifference(file),
    }));

  const foldersAtPath: FolderRowType[] = [];

  for (const file of files) {
    if (file.path === path) continue;
    if (file.path.startsWith(path) === false) continue;

    const folderName = file.path.split("/")[path.split("/").length - 1];
    if (foldersAtPath.some((folder) => folder.name === folderName)) continue;

    foldersAtPath.push({
      type: "folder",
      name: folderName,
      diff: getTimeDifference(file),
    });
  }

  return [...foldersAtPath, ...filesAtPath];
}

export const FileLibrary: React.FC<FileLibraryProps> = ({
  files,
  onFileSelect,
  projectId,
  sharedWithMe,
}) => {
  const { setChecked } = useMediaLibrary();

  // Clear checked files on unmount
  useEffect(() => {
    return () => setChecked([]);
  }, []);

  const folderizedFiles = getFolderizedFilesForPath(files);
  const groupedFiles = groupBy(folderizedFiles, "diff");
  const sortedTimeGroups = getSortedTimeGroups(groupedFiles);

  return (
    <Table className="overflow-visible -ml-[1rem] w-[calc(100%+1rem)]  md:-ml-[3rem] md:w-[calc(100%+3rem)]">
      <thead>
        <FileHeader
          files={files}
          projectId={projectId}
          sharedWithMe={sharedWithMe}
        />
      </thead>

      <tbody>
        {files?.length === 0 && (
          <tr>
            <td colSpan={7}>
              <p className="text-center text-dark-400 m-0 py-4">
                No files found
              </p>
            </td>
          </tr>
        )}

        {sortedTimeGroups.map((date) => (
          <React.Fragment key={date}>
            <tr>
              <td className="border-none" />
              <td className="border-dark-800 pl-0" colSpan={6}>
                <div className="flex items-center gap-2 mb-2 mt-3">
                  <p className="text-gray-200 m-0">{date}</p>
                  <Divider className="flex-1 border-primary-500 bg-primary-700" />
                </div>
              </td>
            </tr>

            {groupedFiles[date].map(({ type, file, name }) =>
              type === "file" ? (
                <FileRow
                  key={file.id}
                  file={file}
                  projectId={projectId}
                  onFileSelect={onFileSelect}
                  sharedWithMe={sharedWithMe}
                />
              ) : (
                <tr key={name}>
                  <td className="border-none" />
                  <td className="border-dark-800 pl-1" colSpan={6}>
                    <div className="flex items-center gap-2">
                      <FolderIcon className="text-white w-5 h-5" />
                      <p data-testid="folder-name">{name}</p>
                    </div>
                  </td>
                </tr>
              )
            )}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};
