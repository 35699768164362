import { Card, Divider } from "@common/components";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import {
  Badge,
  MantineColor,
  MantineGradient,
  useMantineTheme,
} from "@mantine/core";
import React from "react";

import { useUser } from "../../../contexts/UserContext";

type Plan = {
  name: string;
  price: number;
  description: string;
  gradient: MantineGradient;
  textColor: MantineColor;
};

export const plans: Array<Plan> = [
  {
    name: "Free",
    price: 0,
    description: "Free forever",
    gradient: { from: "black", to: "black" },
    textColor: "white",
  },
  {
    name: "Basic",
    price: 7.99,
    description: "For individuals",
    gradient: { from: "white", to: "white" },
    textColor: "indigo",
  },
  {
    name: "Pro",
    price: 9.99,
    description: "For professionals",
    gradient: { from: "cyan", to: "indigo" },
    textColor: "white",
  },
  {
    name: "Unlimited",
    price: 12.99,
    description: "Full access",
    gradient: { from: "purple", to: "pink" },
    textColor: "white",
  },
];

export const MembershipSettings: React.FC = () => {
  const { user } = useUser();
  const theme = useMantineTheme();

  return (
    <div className="p-10 container">
      <h2 className="mb-4">Membership</h2>
      <h4 className="mt-4"> Membership Type</h4>
      <Badge
        className="my-2"
        variant="gradient"
        gradient={{ from: "purple", to: "pink" }}
      >
        Exclusive Beta
      </Badge>

      <div className="relative py-4 my-4 rounded overflow-hidden">
        <div className="absolute flex flex-col items-center justify-center w-full h-full text-white z-50 backdrop-blur bg-black bg-opacity-75">
          <LockClosedIcon className="w-6 h-6 mb-4" />
          <h3>Exclusive Beta</h3>
          <p>No other plans available</p>
        </div>

        <div className="flex flex-col lg:flex-row items-stretch gap-6 max-w-4xl mx-auto my-4">
          {plans.map((plan) => (
            <Card
              key={plan.name}
              withBorder
              className="flex-1 cursor-pointer"
              sx={(theme) => ({
                background: theme.fn.gradient(plan.gradient),
              })}
            >
              <h4
                className="text-center font-extrabold"
                style={{ color: theme.fn.themeColor(plan.textColor, 6, false) }}
              >
                {plan.name}
              </h4>
              <p
                className="text-center mt-auto"
                style={{ color: theme.fn.themeColor(plan.textColor, 6, false) }}
              >
                {plan.description}
              </p>

              <Divider />

              <h2
                className="text-center mt-auto"
                style={{ color: theme.fn.themeColor(plan.textColor, 6, false) }}
              >
                ${plan.price}
                <span className="text-sm"> / month</span>
              </h2>
            </Card>
          ))}
        </div>
      </div>

      <Divider />
      <h2 className="mt-8">Billing Information</h2>
      <p className="text-gray-400">
        You will need to add a payment method in order to purchase a membership
      </p>

      <div className="relative w-full h-64">
        <div className="absolute flex flex-col items-center justify-center w-full h-full text-white z-50 backdrop-blur bg-black bg-opacity-75">
          <LockClosedIcon className="w-6 h-6 mb-4" />
          <h3>Exclusive Beta</h3>
          <p>No payment necessary</p>
        </div>
      </div>
    </div>
  );
};
