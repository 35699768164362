import { QueryDto } from "@server/dto";
import { User } from "@server/entities";
import { FindCollaboratorsDto } from "@server/modules/user/user/dto/find-collaborators.dto";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";

import { useSWR, usePatch, usePost, useStickyStaleSWR } from "../helpers";

export const patchUser = (id: string, data: UpdateUserDto) =>
  usePatch(`/user/${id}`, data);

export const useUsers = (data: QueryDto) =>
  useStickyStaleSWR<User[]>(`/user/search`, data);

export const useUser = (id: string) => useSWR<User>(`/user/${id}`);

export const useFindCollaborators = (data: FindCollaboratorsDto) =>
  usePost<User[]>(`/user/collaborators`, data);

export const useFindCollaboratorsCount = (data: FindCollaboratorsDto) =>
  usePost<number>(`/user/collaborators/count`, data);

export const getUserByUsername = (username: string) =>
  useSWR<User>(`/user/username/${username}`);
