import { Avatar, Card, RichText } from "@common/components";
import { isEmpty, SIMPLE_CONTROLS } from "@common/utils/editor";
import { notification } from "@common/utils/notification";
import useAsyncForm from "@common/utils/use-async-form";
import { useClickOutside } from "@mantine/hooks";
import { Description } from "@mui/icons-material";
import { updateMixdown } from "@requests/project/mixdown";
import { Mixdown } from "@server/entities";
import { UpdateMixdownDto } from "@server/modules/project/mixdown/dto/update-mixdown.dto";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useUser } from "src/contexts/UserContext";

export interface MixdownDescriptionProps {
  mixdown: Mixdown;
}

const MixdownDescription: React.FC<MixdownDescriptionProps> = ({
  mixdown,
  ...props
}) => {
  const { user } = useUser();
  const canEdit = user && user?.id === mixdown.creatorId;

  const form = useAsyncForm<UpdateMixdownDto>({
    initialValues: {
      description: mixdown.description,
    },
  });

  const [editing, setEditing] = useState(false);
  const [body, setBody] = useState(form.values.description as any);
  const ref = useClickOutside(() => {
    if (canEdit) {
      handleSave();
    }
  });

  useEffect(() => {
    form.setFieldValue("description", body);
  }, [body]);

  const handleEdit = () => {
    if (canEdit) {
      setBody(form.values.description);
      setEditing(true);
    }
  };

  const handleSave = async () => {
    if (!mixdown?.id) return;
    if (!form.isDirty) return;

    const { error } = await form.sendForm(
      (values) => {
        return updateMixdown(mixdown.id, {
          description: values.description,
        });
      },
      {
        resetInitial: true,
      }
    );

    if (error) return notification.error(error.message);
    setEditing(false);
  };

  if (isEmpty(form.values?.description) && !canEdit) return null;

  if (editing) {
    return (
      <div
        ref={ref}
        className="flex gap-6 mt-4"
        data-testid="mixdown-description"
        data-quick-assist-id="mixdown-description"
        {...props}
      >
        <Avatar className="mt-3" user={mixdown.creator} />

        <RichText
          className="w-full"
          controls={SIMPLE_CONTROLS}
          data-testid="mixdown-description-input"
          value={body}
          onChange={setBody}
        />
      </div>
    );
  }

  if (!isEmpty(form.values?.description)) {
    return (
      <div className="flex gap-6">
        <Avatar className="mt-3" user={mixdown.creator} />

        <Card
          data-testid="mixdown-description"
          data-quick-assist-id="mixdown-description"
          className="flex-1 h-full mb-4 bg-dark-800 border border-solid border-dark-600"
          {...props}
        >
          <div
            className={
              !canEdit
                ? "flex-1"
                : "flex-1 p-2 -m-2 rounded transition hover:bg-dark-700"
            }
            onClick={handleEdit}
            data-testid="mixdown-description-text"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(form.values?.description),
            }}
          />
        </Card>
      </div>
    );
  }

  if (isEmpty(form.values?.description) && canEdit) {
    return (
      <div className="flex gap-6">
        <Avatar className="mt-3" user={mixdown.creator} />

        <Card
          data-testid="mixdown-description"
          data-quick-assist-id="mixdown-description"
          className="flex-1 h-full mb-4 bg-dark-800 border border-solid border-dark-600"
          {...props}
        >
          <div
            className="text-center my-2 cursor-pointer transition ease-in-out hover:scale-105 hover:text-primary-400"
            onClick={() => setEditing(true)}
          >
            <Description fontSize="medium" />
            <p>Describe this mixdown</p>
          </div>
        </Card>
      </div>
    );
  }

  return null;
};

export { MixdownDescription };
