import { Card } from "@common/components/Card";
import { Avatar } from "@mantine/core";
import React from "react";

import { useProfile } from "../pages/Profile";

export const ProfileSoundsLike: React.FC = () => {
  const { user } = useProfile();

  if (!user?.soundsLike) return null;

  return (
    <Card>
      <h3>Sounds like</h3>

      <div className="flex flex-col gap-4 mt-4">
        {user?.soundsLike?.map((artist, index) => (
          <div key={index} className="flex items-center gap-4">
            <Avatar size="sm" radius="xl" src={artist.dp} />
            <p>{artist.name}</p>
          </div>
        ))}
      </div>
    </Card>
  );
};
