import {
  useDebouncedValue,
  useElementSize,
  useForceUpdate,
  useInterval,
} from "@mantine/hooks";
import { PlayArrow, Pause } from "@mui/icons-material";
import { ActionIcon } from "@synqup/ui/src/components/ActionIcon";
import { useAsyncWorker } from "@synqup/ui/src/utils/use-worker";
import { Howl } from "howler";
import React, { HTMLAttributes, useEffect, useMemo, useState } from "react";

import {
  GetAudioArrayBufferArgs,
  GetAudioArrayBufferResult,
} from "../../modules/stemviewer/helpers/workers/get-audio-array-buffer";
import { getBuffer } from "../../modules/stemviewer/recoil/helpers/stem";

interface VoiceMemoProps extends HTMLAttributes<HTMLDivElement> {
  audio: Blob;
}

const createWorker = () =>
  new Worker(
    new URL(
      "../../modules/stemviewer/helpers/workers/get-audio-array-buffer",
      import.meta.url
    ),
    {
      type: "module",
    }
  );

export const VoiceMemo: React.FC<VoiceMemoProps> = ({ audio, ...props }) => {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>();
  const [playing, setPlaying] = useState(false);
  const getAudioArrayBuffer = useAsyncWorker<
    GetAudioArrayBufferArgs,
    GetAudioArrayBufferResult
  >(createWorker);
  const { ref, width } = useElementSize();
  const [debouncedWidth] = useDebouncedValue(width, 200);
  const forceUpdate = useForceUpdate();
  const { start, stop } = useInterval(() => {
    forceUpdate();
  }, 500);

  const howl = useMemo(
    () =>
      new Howl({
        src: [URL.createObjectURL(audio)],
        format: ["webm"],
        onend: () => setPlaying(false),
      }),
    [audio]
  );

  const scale = useMemo(
    () => (debouncedWidth !== 32 ? 4 / (debouncedWidth - 32) : undefined),
    [debouncedWidth]
  );

  useEffect(() => {
    if (!scale || !audio) return;

    (async () => {
      try {
        const buffer = await audio.arrayBuffer();
        const audioBuffer = await getBuffer(buffer);
        const { array } = await getAudioArrayBuffer({
          scale,
          duration: audioBuffer.duration,
          buffer: audioBuffer.getChannelData(0),
        });

        draw(array);
      } catch (e) {
        console.error(e);
      }
    })().catch(console.error);
  }, [scale, audio]);

  const handlePlay = () => {
    if (playing) {
      howl.pause();
      stop();
    } else {
      howl.play();
      start();
    }
    setPlaying(!playing);
  };

  const handleSeek = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const seek = Math.max(0, (x / (canvas.width + 48)) * howl.duration());
    howl.seek(seek);
    forceUpdate();
  };

  const draw = (arrayBuffer: number[]) => {
    const cvs = canvas;
    if (!cvs) return;

    const ctx = cvs.getContext("2d");
    if (!ctx || !arrayBuffer) return;
    cvs.width = debouncedWidth - 29;
    cvs.height = 64;
    ctx.fillStyle = "white";
    ctx.strokeStyle = "white";

    for (let i = 1; i < cvs.width + 1; i += 1) {
      let a = 2;
      if (i < arrayBuffer.length) a = arrayBuffer[i];

      const x1 = i * 4;
      const y1 = 64 / 2 - a;
      const x2 = i * 4;
      const y2 = 64 / 2 + a;

      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
    }
  };

  return (
    <div {...props}>
      <div
        ref={ref}
        className="select-none relative flex items-center gap-2 p-2 bg-primary-800 rounded-xl overflow-hidden w-full h-fit"
      >
        <ActionIcon
          className="z-10"
          size="sm"
          radius="xl"
          // @ts-ignore
          variant={!playing ? "white" : "light"}
          color="primary"
          onClick={handlePlay}
        >
          {playing ? (
            <Pause className="w-4 h-4" />
          ) : (
            <PlayArrow className="w-4 h-4" />
          )}
        </ActionIcon>
        <canvas
          onMouseDown={handleSeek}
          className="relative z-10 w-full h-8"
          ref={setCanvas}
        />

        <div
          className="absolute left-0 z-0 bg-primary-500 h-full"
          style={{
            width: `calc(${(howl.seek() / howl.duration()) * 100}% + 36px)`,
            transition: "width 0.5s linear",
          }}
        />
      </div>
    </div>
  );
};
