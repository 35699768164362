import { ActionIcon, Avatar, Tooltip } from "@common/components";
import { ColorPicker, Input, Slider } from "@common/components";
import { LoadingOverlay } from "@mantine/core";
import { CircularProgress } from "@mui/material";
import { isEqual } from "lodash";
import React, { memo, useCallback } from "react";
import { ColorResult } from "react-color";
import { Note } from "tabler-icons-react";

import { useStemViewer } from "../../contexts/StemViewerContext";
import { colors, themePicker } from "../../helpers/colors";
import { stemHeight } from "../../helpers/constants";
import { StemHowl } from "../../recoil/stem";

import { VersionPicker } from "./components/VersionPicker";

interface StemPanelProps {
  stem: StemHowl;
  uploading?: number;
}

const StemPanelComponent: React.FC<StemPanelProps> = ({ stem, uploading }) => {
  const { stem: stemCtl } = useStemViewer().controllers;
  const colorOptions = Object.keys(colors);

  /**
   * Handles track name changing
   */
  const handleTrackName = useCallback(({ target }) => {
    return stemCtl.update(stem.stemId, { name: target.value });
  }, []);

  const handleColor = useCallback((color: ColorResult) => {
    let newColor = "purple";

    colorOptions.forEach((colorName) => {
      if (themePicker[colorName].panel === color.hex) newColor = colorName;
    });

    return stemCtl.update(stem.stemId, { color: newColor });
  }, []);

  const handleMute = useCallback(() => {
    return stemCtl.update(stem.stemId, { mute: !stem.mute });
  }, [stem.mute]);

  const handleSolo = useCallback(() => {
    return stemCtl.update(
      stem.stemId,
      { mute: false, solo: !stem.solo },
      {
        otherStems: {
          mute: !stem.solo,
        },
      }
    );
  }, [stem.solo]);

  const handleVolume = useCallback((ev, value) => {
    return stemCtl.update(stem.stemId, { volume: value / 100 });
  }, []);

  const handleVersion = useCallback(
    async (versionId: string) => {
      if (stem.loading) return;
      await stemCtl.switchVersion(stem.stemId, versionId);
    },
    [stem]
  );

  return (
    <div
      data-quick-assist-id="sv-stem-panel"
      className="relative flex justify-center items-center bg-dark-900 p-3 select-none
      border-0 border-solid border-b border-l-2 border-r border-b-dark-800 transition"
      style={{
        height: stemHeight,
        borderLeftColor: themePicker[stem.color].panel,
        borderRightColor: stem.active ? "lime" : "black",
      }}
    >
      <LoadingOverlay
        visible={!!stem.uploading}
        loader={
          <CircularProgress
            variant={uploading === 0 ? "indeterminate" : "determinate"}
            size={36}
            value={uploading}
            thickness={3}
            sx={{ color: themePicker[stem.color].panel }}
          />
        }
      />

      <div className="relative">
        <div className="flex items-center gap-2 mb-2">
          <Input
            data-quick-assist-id="sv-stem-name"
            size="xs"
            color={themePicker[stem.color].panel}
            classNames={{ input: "p-0 pl-1 font-bold min-h-0 h-6" }}
            value={stem.name}
            onChange={handleTrackName}
          />

          <ColorPicker
            style={{ marginTop: 10, marginRight: -5 }}
            colors={colorOptions.map((color) => themePicker[color].panel)}
            value={themePicker[stem.color].panel}
            onChange={handleColor}
            target={
              <Tooltip
                classNames={{ tooltip: "text-xs" }}
                label={stem.creator?.displayName}
              >
                <Avatar
                  data-quick-assist-id="sv-stem-color"
                  bordered
                  color={stem.color}
                  size="xs"
                  user={stem.creator}
                />
              </Tooltip>
            }
          />
        </div>

        <div className="flex items-center justify-between mb-2">
          <VersionPicker
            size="xs"
            previewVersionId={stem.previewVersionId}
            stem={stem}
            onChange={handleVersion}
          />

          <div className="flex items-center gap-1">
            {stem.notes && stem.notes.length > 0 && (
              <div
                className="flex items-center gap-0.5 text-dark-300"
                data-testid="sv-stem-notes-indicator"
              >
                <p className="text-inherit text-[0.75rem] font-semibold m-0">
                  {stem.notes?.length}
                </p>
                <Note className="w-3 h-3" />
              </div>
            )}

            <ActionIcon
              data-testid="sv-mute"
              data-quick-assist-id="sv-mute"
              size="xs"
              radius="xs"
              color="red"
              variant="light"
              className="text-xs"
              active={stem.mute}
              onClick={handleMute}
            >
              M
            </ActionIcon>

            <ActionIcon
              data-quick-assist-id="sv-solo"
              size="xs"
              radius="xs"
              color="yellow"
              variant="light"
              className="text-xs"
              onClick={handleSolo}
            >
              S
            </ActionIcon>
          </div>
        </div>

        <div
          data-quick-assist-id="sv-volume"
          className="flex items-center gap-4 h-2"
        >
          <i className="fas fa-volume-high scale-75 w-4" />

          <Slider
            data-testid="sv-volume"
            color={themePicker[stem.color].panel}
            max={100}
            min={0}
            step={1}
            size="small"
            valueLabelDisplay={"auto"}
            value={Math.round(stem.volume * 100)}
            onChange={handleVolume}
          />
        </div>

        {/*<div className="flex items-center gap-4 mt-2">*/}
        {/*  <i className="fas fa-headphones scale-75 w-4" />*/}

        {/*  <Slider*/}
        {/*    color={themePicker[stem.color].panel}*/}
        {/*    value={stem.stereo}*/}
        {/*    min={-1}*/}
        {/*    max={+1}*/}
        {/*    size="small"*/}
        {/*    step={0.1}*/}
        {/*    valueLabelDisplay={"auto"}*/}
        {/*    onChange={handleStereo}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

const StemPanel = memo(
  StemPanelComponent,
  (prev, next) =>
    isEqual(prev.stem, next.stem) && prev.uploading === next.uploading
);

export { StemPanel };
