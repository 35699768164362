import {
  ActionIconProps,
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
  MantineTheme,
  Styles,
} from "@mantine/core";
import { merge } from "lodash";
import React, { forwardRef } from "react";

export type ButtonProps = MantineButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    fontWeight?: string;
    cancel?: boolean;
  };

export const rgba = (
  theme: MantineTheme,
  shade: number,
  alpha,
  props: Pick<ButtonProps | ActionIconProps, "color">
) => {
  const color = theme.colors[props.color || theme.primaryColor];
  if (color) return theme.fn.rgba(color[shade], alpha);
};

export const getOutlineTheme: Styles<"root", ButtonProps | ActionIconProps> = (
  theme: MantineTheme,
  props: Pick<ButtonProps | ActionIconProps, "color" | "variant">
) => {
  if (props.variant === "light")
    return {
      root: {
        color: rgba(theme, 1, 1, props),
        background: rgba(theme, 7, 0.5, props),
        "&:hover": {
          background: rgba(theme, 6, 0.5, props),
        },
      },
    };

  if (props.variant === "outline")
    return {
      root: {
        color: "rgba(255,255,255,0.8)",
        background: rgba(theme, 8, 0.6, props),
        border: `1px solid ${rgba(theme, 8, 0.8, props)}`,
        "&:hover": {
          background: rgba(theme, 8, 0.8, props),
        },
      },
    };

  return {};
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, fontWeight, ..._props } = props;

  const getSize = (props: MantineButtonProps) => {
    switch (props.size) {
      case "xs":
        return { padding: "0 10px", height: 28, fontSize: 12 };
      case "sm":
        return { padding: "0 12px", height: 32, fontSize: 13 };
      case "lg":
        return { padding: "0 20px", height: 40 };
      case "xl":
        return { padding: "0 24px", height: 44 };
      default:
        return { padding: "0 16px", height: 36 };
    }
  };

  const defaults: MantineButtonProps = {
    style: {
      opacity: props.disabled ? 0.5 : 1,
      ...getSize(props),
      ...props.style,
    },
    styles: (theme) =>
      merge(
        {
          rightIcon: { marginLeft: 6, marginRight: -6 },
          leftIcon: { marginRight: 6, marginLeft: -6 },
          ...getOutlineTheme(theme, props),
        },
        props.styles
      ),
    radius: 4,
    gradient: { from: "#4b53e3", to: "#d33bde", deg: 100 },
  };

  return (
    <MantineButton ref={ref} {...defaults} {..._props}>
      {children}
    </MantineButton>
  );
});

export { Button };
