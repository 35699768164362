import { notification } from "@common/utils/notification";
import JSZip from "jszip";

import { getRecoil, setRecoil } from "../../../../contexts/RecoilNexus";
import { downloadBlob } from "../../helpers/download-blob";
import { Mp3EncoderFn } from "../../helpers/workers/mp3-encoder";
import {
  ProcessStemsFn,
  StemBuffer,
  StemExport,
} from "../../helpers/workers/process-stems";
import { WavEncoderFn } from "../../helpers/workers/wav-encoder";
import { Controller, PartialSetterOrUpdater } from "../helpers/controller";
import { stemToFile } from "../helpers/stem";
import { StemHowl, StemState, stemState } from "../stem";

interface ExportParams {
  stems: StemHowl[];
  individual?: boolean;
  original?: boolean;
  type: "wav" | "mp3";
}

export class ExportsController extends Controller {
  private readonly _stem: () => StemState;
  private readonly setStem: PartialSetterOrUpdater<StemState>;

  constructor(
    private readonly processStems: ProcessStemsFn,
    private readonly wavEncoder: WavEncoderFn,
    private readonly mp3Encoder: Mp3EncoderFn
  ) {
    super();
    this._stem = () => getRecoil(stemState);
    this.setStem = (state) => setRecoil(stemState, state);
  }

  async howlsToBuffers(
    stems: StemHowl[],
    individual?: boolean
  ): Promise<[StemBuffer[], number]> {
    const exportStems: StemExport[] = [];

    let sampleRate = 44100;

    for (const { howl: _, buffer, ...stem } of stems) {
      const leftBuffer = buffer.getChannelData(0);
      let rightBuffer = leftBuffer;
      if (buffer.numberOfChannels > 1) rightBuffer = buffer.getChannelData(1);

      sampleRate = buffer.sampleRate;

      exportStems.push({
        ...stem,
        leftBuffer,
        rightBuffer,
      });
    }

    const buffers = await this.processStems({
      stems: exportStems,
      individual,
      duration: this.state.track.duration,
      sampleRate,
    });

    return [buffers, sampleRate];
  }

  async downloadBlobs(stems: StemHowl[], blobs: Blob[], individual = false) {
    // If project not loaded, return
    if (!this.state.track.project) return;
    const project = this.state.track.project;

    if (blobs.length > 0) {
      if (blobs.length === 1 && !individual)
        return downloadBlob(blobs[0], project.name);

      if (blobs.length === 1 && individual)
        return downloadBlob(blobs[0], stems[0].name);

      const zip = new JSZip();

      blobs.forEach((blob, idx) =>
        zip.file(
          `${stems[idx].name}-${stems[idx].stemId.slice(0, 4)}.wav`,
          blob
        )
      );

      return new Promise<void>((resolve) => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          downloadBlob(content, `${project.name}-tracks.zip`);
          resolve();
        });
      });
    }
  }

  async getIndividualBlobs(stems: StemHowl[], original?: boolean) {
    const blobs: Blob[] = [];

    for (const [idx, stem] of stems.entries()) {
      if (!stem.file?.url) {
        this.setStem((state) => ({
          exporting: { ...state.exporting, done: idx + 1, percent: 0 },
        }));
        continue;
      }

      const file = await stemToFile({ stem, original });

      blobs.push(file);

      this.setStem((state) => ({
        exporting: { ...state.exporting, done: idx + 1, percent: 0 },
      }));
    }

    return blobs;
  }

  async export({ stems, individual, original, type }: ExportParams) {
    let blobs: Blob[];

    this.setStem({ exporting: { done: 0, total: stems.length, percent: 0 } });

    if (individual) {
      blobs = await this.getIndividualBlobs(stems, original);
    } else {
      const [buffers, sampleRate] = await this.howlsToBuffers(
        stems,
        individual
      );

      try {
        if (type === "wav")
          blobs = await this.wavEncoder({ buffers, sampleRate });
        else blobs = await this.mp3Encoder({ buffers, sampleRate });
      } catch (e) {
        notification.error(e.toLocaleString());
        return;
      }
    }

    return this.downloadBlobs(stems, blobs, individual);
  }
}
