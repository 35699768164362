import { Avatar as MantineAvatar, AvatarProps } from "@mantine/core";
import { User } from "@server/entities";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import React from "react";

import { Avatar } from "./Avatar";

interface AvatarsGroupProps extends AvatarProps {
  users: User[];
  bordered?: boolean;
  limit?: number;
  total?: number;
  noAnimation?: boolean;
}

const MotionAvatar = motion(Avatar);

const AvatarsGroup: React.FC<AvatarsGroupProps> = (props) => {
  const { users, limit = 3, bordered, noAnimation, total, ..._props } = props;

  const animation: Omit<MotionProps, "style"> = {
    transition: {
      type: "spring",
      bounce: 0.5,
      duration: 0.3,
    },
    initial: {
      scale: 0.3,
      opacity: 0,
    },
    animate: {
      scale: 1,
      opacity: 1,
    },
    exit: {
      scale: 0.3,
      opacity: 0,
    },
  };

  return (
    <MantineAvatar.Group {..._props}>
      <AnimatePresence>
        {users.slice(0, limit).map((user) => (
          <MotionAvatar
            key={user.id}
            user={user}
            bordered={bordered}
            {...animation}
            size={props.size}
            layout={!noAnimation}
          />
        ))}
      </AnimatePresence>

      {users.length - limit > 0 && (
        <MantineAvatar radius="xl" size={props.size}>
          +{users.length - limit}
        </MantineAvatar>
      )}
    </MantineAvatar.Group>
  );
};

export { AvatarsGroup };
