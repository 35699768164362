import { ActionIcon } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { ChevronRight } from "tabler-icons-react";

import { FriendsList } from "./FriendsList";
import { TaskBucket } from "./TaskBucket";

const Sidebar: React.FC = () => {
  const [_opened, _setOpened] = useState(
    localStorage.getItem("sidebar")
      ? localStorage.getItem("sidebar") === "true"
      : true
  );
  const [hovered, setHovered] = useState(false);
  const location = useLocation();
  const { width } = useViewportSize();

  const setOpened = (opened: boolean) => {
    localStorage.setItem("sidebar", opened ? "true" : "false");
    _setOpened(opened);
  };

  const isStemViewer = location.pathname.includes("/viewer");
  const opened = _opened && isStemViewer;

  return (
    <div className="relative h-full hidden md:flex flex-col gap-4 p-4">
      {isStemViewer && (
        <div
          className="absolute z-20 left-3.5 h-[calc(100%-1rem)] w-4"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div
            className="absolute z-20 h-full w-1 rounded-sm bg-indigo-500 transition"
            style={{ opacity: hovered ? 1 : 0 }}
          />
          <ActionIcon
            size="sm"
            radius="xl"
            color="gray"
            variant="filled"
            className="absolute z-20 top-6 -left-2.5 hover:bg-indigo-500 transition"
            data-quick-assist-id="hide-side-panel"
            style={{
              opacity: hovered ? 1 : 0,
              transform: opened ? "rotate(0deg)" : "rotate(180deg)",
            }}
            onClick={() => setOpened(!opened)}
          >
            <ChevronRight className="w-4 h-4" />
          </ActionIcon>
        </div>
      )}

      <AnimatePresence>
        {opened && (
          <motion.div
            className="flex flex-col gap-4 h-full"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, width: width < 1280 ? "16rem" : "20rem" },
              collapsed: { opacity: 0, width: 0 },
            }}
            transition={{ type: "tween", duration: 0.15 }}
          >
            <TaskBucket />
            <FriendsList />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export { Sidebar };
