import { DatePicker, Input, Modal } from "@common/components";
import { FormActionButtons } from "@common/components/FormActionButtons";
import { UploadImageModal } from "@common/components/UploadImageModal";
import useAsyncForm from "@common/utils/use-async-form";
import { Image, ModalProps } from "@mantine/core";
import { patchTrack } from "@requests/user/track";
import { Track } from "@server/entities/user";
import { UpdateTrackDto } from "@server/modules/user/track/dto/update-track.dto";
import dayjs from "dayjs";
import React from "react";

import { useProfile } from "./Profile";

interface EditTrackModalProps extends Omit<ModalProps, "opened"> {
  track?: Track;
}

export const EditTrackModal: React.FC<EditTrackModalProps> = ({
  track,
  ...props
}) => {
  const { user, mutate } = useProfile();

  const form = useAsyncForm<UpdateTrackDto>({
    initialValues: (track || {}) as UpdateTrackDto,
  });

  const handleSave = async () => {
    if (!user || !track) return;

    const { error } = await form.sendForm((values) =>
      patchTrack(track.id, values)
    );

    if (error) return;

    await mutate();
    props.onClose();
  };

  return (
    <Modal opened={!!track} title={<h2>Edit Track</h2>} {...props}>
      <div className="flex items-center gap-8">
        <div>
          <div className=" w-fit p-6 rounded-xl bg-dark-1000">
            <UploadImageModal
              targetWidth={300}
              targetHeight={300}
              onUpload={(url) => form.setFieldValue("artwork", url)}
            >
              <Image
                className="mb-4"
                width={128}
                height={128}
                radius="md"
                src={form.values.artwork}
              />
            </UploadImageModal>

            {form.values.title && <h4>{form.values.title}</h4>}
            {form.values.date ? (
              <p>{dayjs(form.values.date).format("MMMM YYYY")}</p>
            ) : (
              <p>Unreleased</p>
            )}
          </div>
        </div>

        <div className="flex flex-1 flex-col gap-4">
          <Input
            required
            label="Track title"
            placeholder="Track title"
            {...form.getInputProps("title")}
          />

          <DatePicker
            required
            label="Released on"
            placeholder="Released on"
            {...form.getInputProps("date")}
          />

          <Input required readOnly label="Artist" value={user?.displayName} />
        </div>
      </div>

      <br />

      <FormActionButtons form={form} onSave={handleSave} />
    </Modal>
  );
};
