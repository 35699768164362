import { Avatar, Menu } from "@common/components";
import { __prod__ } from "@common/utils/constants";
import { CogIcon, PowerIcon } from "@heroicons/react/24/outline";
import { QuestionMarkCircledIcon, RocketIcon } from "@modulz/radix-icons";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "src/contexts/UserContext";

import { useQuickAssist } from "../../../contexts/QuickAssist/QuickAssistContext";

export function HeaderProfile() {
  const { user } = useUser();
  const { openMenu } = useQuickAssist();
  const navigate = useNavigate();

  const handleViewProfile = () => {
    navigate("/u/" + user?.username);
  };

  const handleLogout = async () => {
    window.location.replace(
      `${__prod__ ? "" : "http://localhost:5001"}/api/auth/logout`
    );
  };

  return (
    <Menu width={256}>
      <Menu.Target data-testid="header-menu">
        <div>
          <Avatar alt="" user={user} />
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item>
          <div className="flex items-center p-2">
            <Avatar user={user} />

            <div className="ml-4" onClick={handleViewProfile}>
              <h3>{user?.displayName}</h3>
              <span>View your profile</span>
            </div>
          </div>
        </Menu.Item>

        {/*<Divider className="border-dark-600" />*/}

        <Menu.Item icon={<RocketIcon className="w-4 h-4" />} onClick={openMenu}>
          QuickAssist
        </Menu.Item>

        <Link to="https://help.synqup.com.au" target="_blank">
          <Menu.Item icon={<QuestionMarkCircledIcon className="w-4 h-4" />}>
            Help & Support
          </Menu.Item>
        </Link>

        {/*<Divider className="border-dark-600" />*/}

        <Link to="/settings">
          <Menu.Item icon={<CogIcon className="w-4 h-4" />}>
            Settings and Privacy
          </Menu.Item>
        </Link>

        <Menu.Item
          data-testid="logout"
          color="red"
          icon={<PowerIcon className="w-4 h-4" />}
          onClick={handleLogout}
        >
          Log Out
        </Menu.Item>

        <Menu.Label className="text-center">
          Synqup {import.meta.env.VITE_VERSION_NO || "DEV"}
        </Menu.Label>
      </Menu.Dropdown>
    </Menu>
  );
}
