import React from "react";

import { ProfileClassifications } from "./ProfileClassifications";
import { ProfileSoundsLike } from "./ProfileSoundsLike";

interface ProfileSidebarProps {}

const ProfileSidebar: React.FC<ProfileSidebarProps> = () => {
  return (
    <div className="flex flex-col gap-4 ml-4 mt-4 w-72">
      <ProfileClassifications />

      <ProfileSoundsLike />
    </div>
  );
};

export { ProfileSidebar };
