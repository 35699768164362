import { Search, SearchProps } from "@common/components";
import UserItem from "@common/components/UserItem";
import { Project, User } from "@server/entities";
import React from "react";

import { useTaskState } from "../../../contexts/Tasks/task.selector";

interface SearchCollabProps
  extends Omit<SearchProps<User>, "data" | "onChange" | "value"> {
  value: User | null;
  onChange: (user: User | null) => void;
  project?: Project;
}

export function SearchCollab({ value, onChange, ...props }: SearchCollabProps) {
  const { project } = useTaskState(["project"]);

  let _project = props.project || project;

  if (!_project) return null;

  return (
    <div>
      <Search<User>
        name="search-users"
        rightSection={<></>}
        nothingFound="No users found"
        placeholder="Search collaborators"
        data={
          _project?.collaborators?.map((collab) => ({
            label: collab.user.displayName,
            value: collab.user.id,
            data: collab.user,
          })) || []
        }
        renderOption={(option) => (
          <UserItem user={option.data} size="sm" className="m-0 py-0" />
        )}
        value={value?.id}
        onChange={(value) => {
          onChange &&
            onChange(
              _project?.collaborators?.find(
                (collab) => collab?.userId === value
              )?.user || null
            );
        }}
        {...props}
      />
    </div>
  );
}
