import { SwiperForm } from "@common/components/SwiperForm";
import { __prod__ } from "@common/utils/constants";
import useAsyncForm, {
  UseAsyncFormReturnType,
} from "@common/utils/use-async-form";
import { LoadingOverlay } from "@mantine/core";
import {
  checkEmail,
  checkUsername,
  postRegister,
  useAuth0Me,
} from "@requests/auth/auth";
import { RegisterDto } from "@server/modules/auth/dto";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import * as Yup from "yup";

import { useUser } from "../../../contexts/UserContext";
import { RegisterStage2 } from "../components/RegisterStage2";
import { RegisterStage3 } from "../components/RegisterStage3";

export interface RegisterProps {
  form: UseAsyncFormReturnType<RegisterDto>;
}

const Register: React.FC = () => {
  const [query] = useQueryParams({
    redirect_uri: StringParam,
  });
  const { data, isValidating: loading } = useAuth0Me();
  const { user } = useUser();
  const navigate = useNavigate();

  const form = useAsyncForm<RegisterDto>({
    initialValues: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      phoneNumber: undefined,
      address: undefined,
      pseudonym: undefined,
      ...data?.profile,
    },

    schema: Yup.object({
      username: Yup.string()
        .trim()
        .required("Username is required")
        .test({
          name: "username-check",
          message: "Username already in use",
          test: async (username) => {
            if (!username) return true;
            const { data } = await checkUsername(username);
            return !data;
          },
        }),
      email: Yup.string()
        .required("Email is required")
        .email("Must be a valid email")
        .test({
          name: "email-check",
          message: "Email already in use",
          test: async (email) => {
            if (!email) return true;
            const { data } = await checkEmail(email);
            return !data;
          },
        }),
      firstName: Yup.string()
        .trim()
        .min(1, "First name must be longer than 1 character"),
      lastName: Yup.string()
        .trim()
        .min(1, "Last name must be longer than 1 character"),
    }),
  });

  useEffect(() => {
    if (loading) return;

    if (user) navigate("/");

    if (!data?.profile)
      window.open(
        `${__prod__ ? "" : "http://localhost:5001"}/api/auth/auth0`,
        "_self"
      );
  }, [data, user, loading]);

  const handleRegister = async () => {
    const { error } = await form.sendForm(postRegister);
    if (!error)
      return window.open(
        query.redirect_uri ? query.redirect_uri : "/",
        "_self"
      );
  };

  if (loading)
    return (
      <LoadingOverlay
        visible
        loaderProps={{ size: "lg", variant: "bars" }}
        overlayColor="#000"
        className="backdrop-blur"
      />
    );

  return (
    <div className="max-w-5xl mx-auto">
      <SwiperForm
        form={form}
        validateFields={[
          // ["email", "password", "confirmPassword"],
          ["username"],
          ["firstName", "lastName"],
        ]}
        onFinish={handleRegister}
      >
        {/*<RegisterStage1 form={form} />*/}
        <RegisterStage2 form={form} />
        <RegisterStage3 form={form} />
      </SwiperForm>
    </div>
  );
};

export { Register };
