import { Divider, SegmentedControl, Textarea } from "@common/components";
import Confirm from "@common/components/Confirm";
import { FormActionButtons } from "@common/components/FormActionButtons";
import useAsyncForm from "@common/utils/use-async-form";
import { useDeepEffect } from "@common/utils/use-deep-effect";
import { Badge, Drawer, DrawerProps } from "@mantine/core";
import { deleteNote, postNote } from "@requests/project/note";
import { Note } from "@server/entities/project";
import { CreateNoteDto } from "@server/modules/project/note/dto";
import React, { useState } from "react";
import { Trash } from "tabler-icons-react";

import { useUser } from "../../../../contexts/UserContext";
import { useProject } from "../../../projects/view/ProjectContext";
import { useStemViewer } from "../../contexts/StemViewerContext";

import { NoteCard } from "./components/NoteCard";

interface NotesDrawerProps extends DrawerProps {}

export const getNoteTitleBody = (input: string) => {
  const title = input.split("\n")[0].slice(0, 50);
  const body = input.split("\n").join("<br />");

  return { title, body };
};

export const NotesDrawer: React.FC<NotesDrawerProps> = ({ ...props }) => {
  const { project, mutate } = useProject();
  const { user } = useUser();
  const { stems } = useStemViewer().stem(["stems"]);
  const { stem } = useStemViewer().controllers;
  const [_type, setType] = useState<string>("Project");
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const activeStem = stems.find((s) => s.active);

  const form = useAsyncForm<CreateNoteDto>({
    initialValues: {
      title: "",
      body: "",
      stemId: activeStem?.id,
      projectId: project?.id,
    },
  });

  const type = activeStem ? _type : "Project";

  const handleSave = async () => {
    if (!form.values.body) return;
    if (form.values.body.trim().length === 0) return;
    const { title, body } = getNoteTitleBody(form.values.body);

    const { data, error } = await form.sendForm((values) =>
      postNote({ ...values, title, body })
    );

    if (error) return;
    if (isTrack) {
      await stem.update(
        activeStem.stemId,
        {
          notes: [...(activeStem.notes || []), { ...data, user } as Note],
        },
        { local: true }
      );
    } else await mutate();
  };

  const handleDelete = async () => {
    if (!deleteId) return;
    setDeleteId(null);
    await deleteNote(deleteId);
    await mutate();
  };

  const isTrack = type === "Track" && activeStem;

  useDeepEffect(() => {
    if (activeStem) setType("Track");
  }, [activeStem]);

  return (
    <Drawer
      size="lg"
      position="right"
      {...props}
      padding="md"
      withOverlay={false}
    >
      <Confirm
        color="red"
        icon={<Trash />}
        title="Delete thread"
        content="Are you sure you want to delete this thread?"
        opened={!!deleteId}
        onClose={() => setDeleteId(null)}
        onConfirm={handleDelete}
      />

      <SegmentedControl
        data-quick-assist-id="sv-threads-type"
        fullWidth
        size="sm"
        value={type}
        onChange={setType}
        data={["Project", "Track"]}
      />

      <br />

      <Textarea
        data-quick-assist-id="sv-threads-body"
        data-testid="sv-threads-body"
        autosize
        minRows={3}
        value={form.values.body}
        onChange={(e) => form.setFieldValue("body", e.currentTarget.value)}
        error={form.errors.body}
      />

      <FormActionButtons
        form={form}
        onSave={handleSave}
        size="sm"
        data-testid="sv-threads-save"
      />

      <Divider />

      <Badge
        data-testid="sv-threads-badge"
        color={isTrack ? activeStem?.color : "teal"}
        radius="sm"
        className="mb-4"
      >
        {isTrack ? activeStem.name : project?.name}
      </Badge>

      <div>
        <div>
          {isTrack &&
            stems.map((stem) => (
              <div
                className="flex-col gap-2"
                style={{ display: stem.active ? "flex" : "none" }}
                key={stem.id}
              >
                {stem.notes?.map((note) => (
                  <NoteCard
                    key={note.id}
                    note={note}
                    isTrack={!!isTrack}
                    activeStem={activeStem}
                    onDelete={setDeleteId}
                  />
                ))}
              </div>
            ))}
        </div>

        <div className="flex flex-col gap-2">
          {!isTrack &&
            project?.notes
              ?.filter((note) => !note.stemId)
              .map((note) => (
                <NoteCard
                  key={note.id}
                  note={note}
                  isTrack={!!isTrack}
                  activeStem={activeStem}
                  onDelete={setDeleteId}
                />
              ))}
        </div>
      </div>
    </Drawer>
  );
};
