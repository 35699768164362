import { Input } from "@common/components";
import { UseAsyncFormReturnType } from "@common/utils/use-async-form";
import InstagramSvg from "@res/images/instagram.svg";
import SoundcloudSvg from "@res/images/soundcloud.svg";
import SpotifySvg from "@res/images/spotify.svg";
import TikTokSvg from "@res/images/tiktok.svg";
import YoutubeSvg from "@res/images/youtube.svg";
import { LinkedAccount } from "@server/entities/user";
import { UpdateUserDto } from "@server/modules/user/user/dto/update-user.dto";
import React, { ChangeEvent } from "react";

interface EditProfileLinkedAccountsProps {
  form: UseAsyncFormReturnType<UpdateUserDto>;
}

export const EditProfileLinkedAccounts: React.FC<
  EditProfileLinkedAccountsProps
> = ({ form }) => {
  const handleChange =
    (type: LinkedAccount["type"]) =>
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(
        "linkedAccounts",
        form.values.linkedAccounts?.map((account) => {
          if (account.type === type)
            return { ...account, url: target.value } as LinkedAccount;

          return account;
        })
      );
    };

  const getValue = (type: LinkedAccount["type"]) =>
    form.values.linkedAccounts?.find((account) => account.type === type)?.url;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-4">
        <img className="w-5 h-5" src={InstagramSvg} alt="instagram" />
        <p className="w-24 m-0">Instagram</p>
        <Input
          className="flex-1"
          placeholder="https://www.instagram.com/..."
          value={getValue("instagram")}
          onChange={handleChange("instagram")}
          error={form.errors["linkedAccounts.0.url"]}
        />
      </div>

      <div className="flex items-center gap-4">
        <img className="w-5 h-5" src={SpotifySvg} alt="spotify" />
        <p className="w-24 m-0">Spotify</p>
        <Input
          className="flex-1"
          placeholder="https://open.spotify.com/artist/..."
          value={getValue("spotify")}
          onChange={handleChange("spotify")}
          error={form.errors["linkedAccounts.1.url"]}
        />
      </div>

      <div className="flex items-center gap-4">
        <img className="w-5 h-5" src={TikTokSvg} alt="tiktok" />
        <p className="w-24 m-0">TikTok</p>
        <Input
          className="flex-1"
          placeholder="https://www.tiktok.com/..."
          value={getValue("tiktok")}
          onChange={handleChange("tiktok")}
          error={form.errors["linkedAccounts.2.url"]}
        />
      </div>

      <div className="flex items-center gap-4">
        <img className="w-5 h-5" src={YoutubeSvg} alt="youtube" />
        <p className="w-24 m-0">Youtube</p>
        <Input
          className="flex-1"
          placeholder="https://www.youtube.com/..."
          value={getValue("youtube")}
          onChange={handleChange("youtube")}
          error={form.errors["linkedAccounts.3.url"]}
        />
      </div>

      <div className="flex items-center gap-4">
        <img className="w-5 h-5" src={SoundcloudSvg} alt="soundcloud" />
        <p className="w-24 m-0">Soundcloud</p>
        <Input
          className="flex-1"
          placeholder="https://www.soundcloud.com/..."
          value={getValue("soundcloud")}
          onChange={handleChange("soundcloud")}
          error={form.errors["linkedAccounts.4.url"]}
        />
      </div>
    </div>
  );
};
