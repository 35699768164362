import { Input } from "@common/components";
import { Divider } from "@mantine/core";
import React from "react";

import { RegisterProps } from "../pages/Register";

const RegisterStage2: React.FC<RegisterProps> = ({ form }) => {
  return (
    <div className="flex h-[calc(75vh)] items-center p-6">
      <div className="w-full">
        <h3 className="text-dark-400">
          STEP <span className="text-white">1 </span>
          OF <span className="text-white">2 </span>
        </h3>
        <h1>Select a unique username for your profile</h1>

        <Divider className="my-8" />

        <Input
          {...form.getInputProps("username")}
          size="xl"
          placeholder="Username"
          data-testid="username"
        />
      </div>
    </div>
  );
};

export { RegisterStage2 };
